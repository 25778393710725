$error: #ff7a7a;

#combi-risk-manager {
  .page-main {
    overflow-y: hidden;
    .form-inner {
      margin-top: 50px;
    }
    .ReactVirtualized__Grid {
      overflow-x: hidden !important;
    }
    .top-panel {
      .top-panel-container {
        height: 50px;
        background-color: #a0c4e4;
        width: 100%;
        position: fixed;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        .form-content {
          float: left;
          border: 1px solid #80b4e2;
          border-radius: 5px;
          margin: 2px;
          background-color: #fff;
          margin-left: 13px;
          .form-group {
            float: left;
            border-right: 1px solid #80b4e2;
            padding: 3px 10px;
            text-align: center;
            label {
              text-transform: uppercase;
              font-size: 10px;
              color: #418aca;
              margin-right: 0px;
              font-weight: 500;
              &.span {
                color: #000;
              }
              &.no-siblings {
                line-height: 2.5;
              }
            }
            span {
              display: block;
              font-size: 11px;
              &.label {
                color: #418aca;
                font-weight: 500;
              }
            }
          }
          .btn-expand {
            float: left;
            width: 15px;
            line-height: 30px;
            color: #418aca;
            cursor: pointer;
            text-align: center;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            &:before {
              margin-left: -2px;
            }
          }
        }
      }

      .top-panel-section {
        display: flex;
      }
      .form-wrapper {
        padding: 0px;
        flex: 1;
      }
      .singular-wrapper {
        min-height: 25px;
        padding: 5px 0px;
        > div {
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .paginate {
      margin-bottom: 10px;
      label {
        display: inline-block;
        padding: 10px;
      }
      .button-group {
        display: inline-block;
        margin: 5px 0 10px 0px;
      }
    }
    .combi-bet-type {
      width: 100% !important;
    }
  }
  .ReactVirtualized__Table {
    .ReactVirtualized__Grid__innerScrollContainer {
      font-weight: 200;
    }
  }

  //icons/status
  .phxico {
    &[data-blockstatus="NONE"] {
      color: $green;
    }
    &[data-blockstatus="MANUAL"] {
      color: $red;
    }
    &[data-blockstatus=""] {
      color: $gray;
    }
  }

  .ReactVirtualized__Table__Grid {
    .no-combi-found {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  .blockingrules-section {
    width: 100%;
    min-height: 47px;
    .channels {
      width: calc(100% - 195px);
      float: left;
      height: 100%;
      .channel {
        min-width: 80px;
        height: 100%;
        border-right: 1px solid $black10;
        padding: 3px 5px;
        float: left;
        &:last-child() {
          border-right: 0px;
        }
        i {
          margin-right: 5px;
          font-size: 12px;
          color: $primary;
          float: left;
        }
        label {
          color: $primary;
          float: left;
          margin: 0px;
        }
        .channel-rule {
          text-transform: uppercase;
          font-size: 10px;
          padding: 3px 7px;
          width: 60px;
          color: $white;
          background-color: $green;
          display: block;
          margin: 17px auto 0px auto;
          border-radius: 3px;
          text-align: center;
          &[data-rule="SPECIFIC"] {
            background-color: $yellow;
          }
          &[data-rule="ANY"] {
            background-color: $red;
          }
        }
      }
    }
    button {
      float: right;
      margin-right: 20px;
    }
  }
  .ReactVirtualized__Table {
    .critical-icon {
      color: red;
    }
    .warning-icon {
      color: orange;
    }
    .hide-warnign-icon {
      display: none;
    }
  }
}

//modals
.large {
  &.tall {
    .modal-container {
      height: 745px;
      .form-wrapper {
        border: none !important;
      }
    }
  }
}
.ReactModal__Content {
  &[aria-label="Set New Blocking Rule"] {
    .modal-container {
      height: 370px;
      .modal-body .form-inner > .form-wrapper {
        padding: 0px 10px;
        height: 290px;
        p {
          margin: 10px 0px 5px 0px;
        }
        .form-wrapper h4 {
          background: $primary-light;
        }
      }
    }
    .combinationBet-tableOnly {
      max-height: 100px;
      overflow-y: auto;
      margin-bottom: 10px;
      .ReactVirtualized__Grid__innerScrollContainer {
        font-weight: 200;
      }
    }
    .blocktype-inner {
      .blocktype-item {
        display: block;
        margin-bottom: 2px;
        input[type="radio"] {
          margin-right: 10px;
        }
      }
    }
    .blocktype-channels {
      padding: 2px 10px;
      .channel {
        width: 25%;
        float: left;
        line-height: 20px;
        input[type="checkbox"] {
          margin-right: 5px;
        }
      }
    }
    .button-group {
      display: block;
      padding: 10px 20px;
      position: absolute;
      width: 100%;
      bottom: 0px;
    }
    .button-group-merged {
      button {
        border-radius: 0px;
      }
      button:first-child {
        border-radius: 3px 0px 0px 3px;
      }
      button:last-child {
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
}

$border-color: #e0e0e0;
$header-bg-color: #e9e9e9;

@mixin VirtualColumn {
  .ReactVirtualized__Table__rowColumn,
  .ReactVirtualized__Table__headerColumn {
    height: 20px;
    line-height: 20px;
    border-right: 1px solid $border-color;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      border-left: 1px solid $border-color;
      margin-left: 0;
      padding-left: 10px;
    }
  }
}

// .ReactVirtualized__Table {
//     margin: 10px;
// }
.ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid $border-color;
  background: $header-bg-color;
  text-transform: none !important;

  &:first-child {
    border-top: 1px solid $border-color;
  }

  @include VirtualColumn;

  .ReactVirtualized__Table__rowColumn,
  .ReactVirtualized__Table__headerColumn {
    outline: none;
  }

  .ReactVirtualized__Table__headerColumn {
    margin-right: 0;
    padding: 0 5px;
  }
}

.ReactVirtualized__Table__row {
  @include VirtualColumn;
  cursor: pointer;
  outline: none;

  &:not(:first-child) {
    border-top: 1px solid $border-color;
  }

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  &.row-selected {
    background: $primary-light !important;
    color: $white;
    outline: none;

    .ReactVirtualized__Table__rowColumn {
      input,
      select {
        background: transparent;
        color: $white;
      }

      select {
        &:active,
        &:focus {
          color: #000;
        }
      }
    }
  }
}

.ReactVirtualized__Table__sortableHeaderIcon {
  height: 1.5em !important;
}

.margin-5 {
  margin: 5px;
}

.rv-custom-row {
  border-bottom: 1px solid $border-color;

  .ReactVirtualized__Table__rowColumn {
    margin: 0;
    padding: 0 5px;

    select,
    input {
      width: 100%;
      height: 15px;
      border: none;

      &:focus,
      &:active {
        outline: none;
      }
    }

    input {
      text-align: right;

      &:focus,
      &:active {
        // text-align: left;
        border: 1px;
        background: #ffffff;
        color: #000;
      }
    }
  }
}

div[aria-label="grid"] {
  &:focus {
    outline: none;
  }
}

.filter-wrapper {
  margin: 5px;
  display: flex;

  .filter-container {
    margin: 0 5px;

    .filter-wrap {
      background: #f1f1f1;
      border-radius: 5px;
      padding: 15px;
      min-height: 290px;

      .filter-item {
        display: flex;
        margin-bottom: 5px;
        .dropdown {
          position: relative;
          display: inline-block;
          width: 100%;
          border-radius: 5px;
          background-color: #fff;
          color: #323232;
          padding: 0px 15px;
          height: 26px;
          line-height: 24px;
          border: 1px solid #e2e2e2;
          max-width: 80%;
          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: 5px;
            top: 60%;
            margin-top: -6px;
            border-width: 6.4px 3.5px 0px 3.5px;
            border-style: solid;
            border-color: black transparent;
          }
          &.active {
            box-shadow: 0px 0px 1px 1px #418aca;
          }
          &.disabled {
            pointer-events: none;
            background: #f1f3f3;
            color: #333;
            border: none;
          }
          .dropbtn {
            background-color: transparent;
            color: black;
            border: none;
            display: inline-block;
            width: 100%;
            &.disabled {
              pointer-events: none;
              background: #f1f3f3;
              color: #333;
            }
            &:hover,
            &:focus {
              background-color: transparent;
            }
          }
          #sports-search {
            height: 100%;
            width: 100%;
            border: none;
            &:focus {
              outline: none;
            }
          }
          .dropdown-content {
            display: none;
            flex-direction: column;
            position: absolute;
            background-color: #fff;
            overflow: auto;
            z-index: 1;
            width: 100%;
            left: 0;
            height: 240px;
            color: black;
            top: 25px;
            &.active {
              border: 1px solid #418aca;
            }
            // :first-child{
            //     label{
            //       padding: 7px;
            //     }
            // }
            .dropdown-list {
              padding: 5px;
              > label {
                display: flex;
                > span {
                  margin-right: 5px;
                  margin-top: 1px;
                  > img {
                    height: 12px;
                  }
                }
              }
              .path-icon {
                display: inline-block;
                padding: none;
                margin-right: 0;
              }
              &:hover {
                background-color: #418aca !important;
                color: #fff;
              }
              &.searched-item {
                background-color: #418aca !important;
                color: #fff;
              }
            }
          }
        }

        > label {
          text-align: right;
          display: block;
          flex: 1;
        }

        .date-range-error-message {
          flex: 2;
          color: #fe7a7a;
          font-size: 11px;
        }

        .filter-input {
          position: relative;
          display: flex;
          flex: 2;

          &.hasError {
            z-index: 99;
          }

          &.hasErrorBlock {
            > * {
              margin-bottom: 10px;
            }
          }
          .spacer {
            width: 10px;
            text-align: center;
          }

          .range-error {
            position: absolute;
            bottom: -15px;
            font-size: 11px;
            color: $error;
          }
          .range-error-bubble {
            position: absolute;
            top: -30px;
            padding: 5px;
            background: rgba(254, 122, 122, 0.9);
            box-shadow: -1px 0px 1px 1px $error;
            border-radius: 5px;
            color: #ffffff;
            font-size: 11px;
            text-shadow: -1px 1px #333;

            &:after {
              content: "";
              position: absolute;
              bottom: 12px;
              left: 30px;
              width: 0;
              height: 0;
              border: 8px solid transparent;
              border-top-color: #fe7a7a;
              border-bottom: 0;
              border-left: 0;
              margin-left: -10px;
              margin-bottom: -20px;
            }
          }

          .datepicker-container {
            position: relative;

            .error {
              position: absolute;
              right: 5px;
              top: 4px;
              color: $error;
              font-size: 11px;
            }

            .datepicker-inner {
              position: relative;
              z-index: 99;
            }

            p {
              flex: 1;
              margin: 0;
            }

            input[type="text"] {
              width: 100%;
              z-index: -1;
            }
          }

          input[type="text"] {
            flex: 1;

            &.hasError {
              border-color: $error;
            }
          }

          .option {
            display: flex;
            flex-flow: row wrap;
            flex: 1;
            label {
              display: flex;
              text-align: left;
              margin: 5px 10px 0 0;
              cursor: pointer;
              width: 30%;
            }
          }
        }
      }

      ul {
        width: 100%;

        &.option {
          li {
            label {
              cursor: pointer;
            }
          }
        }
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@for $i from 1 through 4 {
  .flex-#{$i} {
    flex: #{$i};
  }
}

@for $i from 1 through 9 {
  .flex1-#{$i} {
    flex: #{1 + ($i*0.1)} !important;
  }
}

.specific-sports {
  label {
    user-select: none;
    cursor: pointer;
    display: flex;
    &.disabled {
      color: #999;
      .path-icon {
        color: #999;
      }
      > span {
        margin-top: 1px;
        > span {
          margin: 0px 3px 0px 1px;
          > img {
            height: 10px;
            color: #999;
          }
        }
      }
    }
    > span {
      margin-top: 1px;
      > span {
        margin: 0px 3px 0px 1px;
        > img {
          height: 10px;
        }
      }
    }
    &.no-child {
      margin-left: 17px;
    }

    overflow: hidden;
  }
}

.event-paths-filter {
  label {
    user-select: none;
    cursor: pointer;
    &.disabled {
      color: #999;
      .path-icon {
        color: #999;
      }
    }
    &.no-child {
      margin-left: 17px;
    }

    overflow: hidden;
  }
}

.specific-sports,
.event-paths-filter {
  width: 100%;
  min-height: 200px;
  max-height: 250px;
  border: 1px solid #aaa;
  padding: 5px;
  margin-top: 5px;
  overflow: scroll;
  input {
    margin-right: 0 !important;
  }
  .path-icon {
    color: #333;
    display: inline-block;
    margin-right: 0;
    width: auto;
  }
  .phx-event-path,
  .phx-rank-event,
  .phx-game-event {
    color: #fff;
    font-size: 13px;
  }
}

.epf-container {
  width: 100%;
  display: flex;
  align-items: center;
  .event-paths-filter {
    height: 250px;
    .ep-toggle {
      i {
        cursor: pointer;
        outline: none;
      }
    }
    .path-icon-game-event {
      background-color: $primary;
    }

    .path-icon-ranked-event {
      background-color: #845c39;
    }

    .path-icon--path {
      background-color: $greenery;
    }
    .path-icon--market {
      width: 16px !important;
      padding: 0;
      background-color: $red;
      i {
        color: $white;
        font-size: 13px;
      }
    }
    .path-icon--outcome {
      width: 16px !important;
      background-color: $indigo;
      i {
        color: $white;
        font-size: 13px;
      }
    }
  }
}

.epf-action-buttons {
  // width:80px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin: 0 10px;
  flex: 0 0 auto;
  flex-direction: column;
  button {
    margin-bottom: 5px;
    padding: 5px 10px;
  }
}
