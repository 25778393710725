header {
  overflow: hidden;
  border-bottom: 1px solid $white;
  .brand {
    width: 195px;
    .logo {
      @include pseudo-caret-right($header-crumbs-w);
      width: 175px;
      background: url(../../assets/img/logo.png) no-repeat center;
      background-size: 100%;
      cursor: pointer;
      @include transit();
      &:hover {
        @include brightness(130%);
      }
    }
  }
  .navigation-container {
    ul.nav {
      margin-left: 0px;
      li {
        display: inline-block;
        margin-top: 0px;
        @include pseudo-caret-right($header-crumbs-w);
        width: auto;
        min-width: 160px;
        padding-right: 10px;
        a {
          text-transform: uppercase;
          text-align: center;
          font-weight: $fw-light;
          font-size: 13px;
          color: $white;
          background: transparent;
          line-height: $header-height;
          padding-left: 0px;
          text-decoration: none;
          margin-left: 30px;
        }
        &:first-child {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
  .header-actions {
    float: left;
    margin-left: 20px;
    ul.list-reset {
      li {
        float: left;
        margin-right: 5px;
        margin-top: 6px;
        height: 30px;
        // @include outline-action($primary-lightest, $primary);
        &.active {
          background-color: $primary-darker;
        }
        &.disabled {
          border-color: $primary-dark;
          a,
          i {
            color: $primary-dark;
          }
        }
        input {
          line-height: 30px;
          height: 30px;
        }
        &.btn-box {
          // background: $primary;
          width: 40px;
          // border-radius: 15px;
          background: none;
          height: 32px;
          &.withActionSelect {
            width: 180px;
            i {
              width: 40px;
              float: left;
              line-height: 30px;
            }
            .action-select {
              float: left;
              border-left: 1px solid rgba(0, 0, 0, 0.2);
              width: calc(100% - 45px);
              select {
                margin-top: 3px;
                border: 0px;
                float: right;
              }
            }
          }
          &.disabled {
            border: 1px solid $white50;
            cursor: not-allowed;
            a {
              cursor: inherit;
            }
            i {
              color: $white50;
            }
          }
          &:not(.disabled) {
            cursor: pointer;
            @include transit();
            border: 1px solid $white25;
            background: $white;
            // box-shadow:0 4px $black50;
            i {
              color: $primary-dark;
            }
            &:hover {
              cursor: pointer;
              background-color: $white75;
            }
            &.endcall {
              i {
                color: $red;
              }
            }
            &.lock {
              i {
                color: $yellow;
                &.phx-lock-open {
                  color: $green;
                }
              }
            }
            &.save,
            &.search,
            &.refresh,
            &.create,
            &.edit,
            &.delete {
              i {
                color: $primary-dark;
              }
            }
            //Risk Manager Icons
            &.settings,
            &.chart,
            &.globe {
              i {
                color: $primary-dark;
              }
            }
          }
        }
      }
    }
    .search-ontop {
      background: $black50;
      border: none;
      -webkit-appearance: none;
      // border-bottom: 1px solid $white50;
      // padding-left: 15px;
      color: $white;
      width: 250px;
      @include transit();
      &:focus {
        // border: 1px solid $primary-dark;
        // background: $white;
      }
      &::placeholder {
        color: $white50;
      }
    }
    &.sidebar-toggle {
      // display: none;
      margin: 0;
      background: $black25;
      //padding: 0 20px;
      i {
        font-size: 20px;
        line-height: 45px;
      }
    }
  }
  .user-section {
    .dropdown-container {
      margin-right: 10px;
    }
    .user-details {
      width: 40%;
      float: left;
      margin-right: 10px;
      text-align: right;
      font-size: $font-small;
      > div {
        line-height: 14px;
        display: block;
        width: 100%;
        color: $primary-lightest;
        float: right;
      }
    }
    .btn-logout {
      // margin-right: 20px;
      padding: 11px;
      //width: 70px !important;
      line-height: 12px;
      //font-size: $font-small;
      //text-transform: uppercase;
    }
  }
}
