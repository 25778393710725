*[role="button"]:not(button) {
  outline: none;
}

a {
  color: $secondary-darker;
}

ul {
  list-style: none;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}

label {
  margin-right: 20px;
  > input[type="checkbox"],
  > input[type="radio"] {
    margin-right: 5px;
  }
}

.main-bg {
  position: absolute;
  @include wh(100%, 100vh);
  //background: url(../../assets/img/bg.png) center no-repeat;
  // @include gradient-alert();
  background: linear-gradient(270deg, $xylo1, $xylo2, $xylo3);
  background-position: 50% 0;
  background-size: 200% 200%;
}

.loading.tcenter {
  font-size: 30px;
  color: $primary;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

.fleft {
  float: left;
}

.fright {
  display: flex;
  float: right;
}
.button-height {
  line-height: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid hsla(0, 0%, 100%, 0.25);
  background: #fff;
  border-radius: 3px;
  width: 36px;
  height: 36px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
    width: 18px;
  }
}

//.margin5 {
//  margin: 5px;
//}

.clickable {
  cursor: pointer;
}

.fclear {
  clear: both;
  float: none;
}

.block {
  display: block;
}

.ftop {
  position: absolute;
  top: 10px;
  &.fright {
    right: 10px;
  }
  &.fleft {
    left: 10px;
  }
}

.full-height {
  height: 100%;
  box-sizing: border-box;
}

.tcenter {
  text-align: center;
}

.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.push-left {
  margin-left: 5px;
}

.push-right {
  margin-right: 5px;
}

.half {
  width: calc(50% - 5px);
  box-sizing: border-box;
}

.list-reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nopads {
  @include nopads();
}
.no-pointer-events {
  pointer-events: none;
}

.padding-small {
  padding: 5px !important;
}
.padding-medium {
  padding: 10px !important;
}
.padding-large {
  padding: 15px !important;
}
.padding-bm-modal {
  padding: 7px !important;
}

.txt-ellipsis {
  @include ellipsis();
}

.txt-wrap {
  @include txtwrap();
}

.txt-capitalize {
  text-transform: capitalize;
}

.status-lbl-icon {
  text-transform: uppercase;
  font-size: $font-small;
  padding-left: 20px;
  position: relative;
  &:before {
    @include pseudo-status-icon();
    @include sq(10px);
    border-radius: 5px;
  }
}

.info-msg {
  .phx-warning {
    color: $red;
    margin-right: 10px;
  }
  .phx-warning {
    color: $yellow;
  }
}

.loading-section {
  font-color: $primary;
  font-size: $font-xlarge;
  width: 100%;
  min-height: 100px;
  text-align: center;
  margin-top: calc(50% - 8px);
}

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  background: $black75;
  animation: fade-in 2s ease;
  animation-iteration-count: 1;
  i {
    color: $white;
    font-size: 30px;
    margin-top: 30px;
  }
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.flex {
  display: flex;
}
.flex--grow {
  flex: 1 1 auto;
}
.flex--shrink {
  flex: 0 0 auto;
}
.flex--row {
  flex-direction: row;
}
.flex--column {
  flex-direction: column;
}
.flex--align-center {
  align-items: center;
}
.flex--align-top {
  align-items: flex-start;
}
.flex--align-bottom {
  align-items: flex-end;
}
.flex--justify-center {
  justify-content: center;
}
.flex--align-self-top {
  align-self: flex-start;
}
.flex--align-self-bottom {
  align-self: flex-end;
}
.flex--align-self-center {
  align-self: center;
}

.dropdown-wrap {
  justify-content: flex-end;
  display: flex;

  .dropdown {
    width: 4% !important;
    .menu {
      max-height: 300px !important;
      width: 180px !important;
      position: absolute !important;
      top: 36px !important;
      left: -126px !important;
    }
  }
}
.dropdown-wrap1 {
  justify-content: flex-end;
  display: flex;

  color: #000;

  .dropdown {
    width: 4% !important;
    .menu {
      max-height: 300px !important;
      width: 180px !important;
      position: absolute !important;
      top: 36px !important;
      left: -120px !important;
    }
  }
}
.drop-button_width {
  .selection {
    width: 60px !important;
  }
}
.header-wrap {
  display: flex;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.user-section {
  display: flex;
  align-items: center;

  .dropdown {
    margin-left: 5px;
  }
}
.header-content {
  display: flex !important;
  height: 43px !important;
}
.header-container {
  .user-section {
    line-height: 0px !important;
    .dropdown-wrap1 {
      padding-top: 5px !important;
      padding-left: 5px;
      line-height: 30px;
      border-radius: 3px;
      .flag-select__btn {
        background: #316796;
        border: none;
        box-shadow: none !important;
        height: unset !important;
        &:hover {
          background: transparent !important;
          color: #333;
        }
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .flag-select__options {
        min-width: 14em !important;
        min-height: 2.71428571em;
        background: #f3f3f3;
        display: inline-block;
        padding: 0 !important;
        color: rgba(0, 0, 0, 0.87);
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease, width 0.1s ease;
        top: 40px;
        left: -100px !important;
        max-height: 350px !important;
        li {
          margin: 0 !important;
          span {
            width: 100% !important;
            height: auto !important;
            display: flex;
            align-items: center;
            img {
              top: 4px;
            }
          }
        }
      }

    }
  }
  .user-section-width {
    width: 100% !important;
  }
}
.flex-spacer {
  flex: 1 1 auto;
}
header {
  .user-section {
    .user-details > div {
      float: unset !important;
    }
    .user-details {
      width: unset !important;
      margin: 0px 10px !important;
    }
  }
}

.button-height {
  //width: 131px !important;
  font-size: 11px;
  margin-right: 5px;
}

.logout-btn {
  margin-right: 10px;
}

.ReactModal__Overlay {
  z-index: 999;
}
.flag-select__options {
  li {
    span {
      align-items: baseline !important;
    }
  }
}
