.event-creator-sidebar {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sidebar-header {
  flex: 0 0 auto;
  padding: 10px;
  background-color: $gray-lightest;
  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    color: #323232;
    padding: 0px 15px;
    height: 26px;
    line-height: 24px;
    border: 1px solid #e2e2e2;
    max-width: 100%;
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: 5px;
      top: 60%;
      margin-top: -6px;
      border-width: 6.4px 3.5px 0px 3.5px;
      border-style: solid;
      border-color: black transparent;
    }
    &.active {
      box-shadow: 0px 0px 1px 1px #418aca;
    }
    &.disabled {
      pointer-events: none;
      background: #f1f3f3;
      color: #333;
      border: none;
    }
    .dropbtn {
      background-color: #fff;
      color: black;
      border: none;
      display: inline-block;
      width: 100%;
      &.disabled {
        pointer-events: none;
        background: #f1f3f3;
        color: #333;
      }
      &:hover,
      &:focus {
        background-color: #fff;
      }
    }
    #sports-search {
      height: 100%;
      width: 100%;
      border: none;
      &:focus {
        outline: none;
      }
    }
    .dropdown-content {
      display: none;
      flex-direction: column;
      position: absolute;
      background-color: #fff;
      overflow: auto;
      z-index: 1;
      width: 100%;
      left: 0;
      height: 300px;
      color: black;
      top: 25px;
      &.active {
        border: 1px solid #418aca;
      }
      .dropdown-list {
        padding: 5px;
        > label {
          display: flex;
          > span {
            margin-right: 5px;
            margin-top: 1px;
            > img {
              height: 12px;
            }
          }
        }
        .path-icon {
          display: inline-block;
          padding: none;
          margin-right: 0;
        }
        &:hover {
          background-color: #418aca !important;
          color: #fff;
        }
        &.searched-item {
          background-color: #418aca !important;
          color: #fff;
        }
      }
    }
  }
}
.path-search-container {
  position: relative;
  input {
    width: 100%;
    height: 25px;
    line-height: 25px;
    padding: 2px 20px 2px 5px;
  }
  i {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 20px;
    opacity: 0.5;
    line-height: 18px;
    border-radius: 10px;
    background-color: $primary;
    text-align: center;
    @include transit();
    &:hover {
      opacity: 1;
    }
  }
}
.sidebar-body {
  flex: 1 1 auto;
  padding: 10px;
  overflow-y: auto;
  background-color: $white;
}
.sports-tree-controls {
  display: flex;
  margin-top: 10px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    width: 26px;
    height: 26px;
    line-height: 26px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      box-shadow: none;
    }
    &[disabled] {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.25);
      border: 1px solid rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }
  .controls-group {
    display: flex;
  }
}

.path-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f1f1f1;
}
.level-0 {
  border-top: 0;
}
.level-1 {
  &:last-child {
    border-bottom: 1px solid #f1f1f1;
  }
}
.path-anchor {
  padding: 1px 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  text-decoration: none;
  color: $pathtree-txt-clr;
  &.active {
    font-weight: bold;
    color: $primary;
    cursor: default;
  }
}
.path-anchor--matched {
  color: $secondary-dark;
}
.path-anchor--nopointer {
  pointer-events: none;
}
.path-name {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  min-height: 18px;
}
.path-name-desc {
  flex: 1 1 auto;
  &:hover {
    color: $primary;
  }
}
.path-action {
  min-width: 20px;
  max-width: 20px;
  text-align: center;
}
.path-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 22px;
  padding: 2px;
  margin-right: 4px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.path-checkbox + .path-name {
  .path-icon {
    margin-left: 4px;
  }
}
.path-icon-game-event {
  background-color: $primary;
}

.path-icon-ranked-event {
  background-color: #845c39;
}
.path-icon--path {
  background-color: $greenery;
}
.path-icon--market {
  width: 16px;
  padding: 0;
}
.path-count-container {
  flex: 0 0 auto;
  padding: 0 5px;
  min-width: 40px;
}
.path-count {
  display: inline-block;
  min-width: 30px;
  text-align: center;
  padding: 2px 5px;
  border-radius: 10px;
  background-color: $gray-light;
  color: $white;
}
