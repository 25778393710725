#app-utilities {
  .page-main {
    left: 275px;
  }
}
.utilities-sidebar {
  @extend .event-creator-sidebar;
  .path-icon {
    color: #ffca28;
  }
  .path-icon--settings {
    color: $primary;
  }
  .path-anchor {
    &.disabled {
      pointer-events: none;
      color: #ccc;
    }
  }
  .path-container {
    &.level-1:first-child {
      border-top: none;
    }
  }
}
.utilities-app-page {
  height: 100%;
  .panel-content {
    padding: 5px;
  }
}

.utilities-header {
  .panel-header {
    background: none;
    height: 45px;
    display: flex;
    .btn-box {
      display: flex;
      align-items: center;
      width: 40px;
      height: 32px;
      background-color: #fff;
      transition: all 0.3s ease;
      border: 1px solid rgba(255, 255, 255, 0.25);
      color: $primary-dark;
      &:hover {
        background-color: rgba(255, 255, 255, 0.75);
        .phxico {
          color: $primary-dark;
        }
      }
      &:disabled {
        pointer-events: none;
        background: none;
        .phxico {
          color: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }
  .panel-header-actions {
    display: flex;
    align-items: center;
  }
}
.list-container-sports {
  .level-0 {
    overflow: auto;
    width: 100%;
    padding: 0 2px;
    > .path-anchor {
      display: block;
    }
  }
  .loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.new-bet-restriction-container {
  .sports-tree-controls {
    display: block;
    height: 30px;
    margin: 2px 0;
    .controls-group {
      align-items: center;
      .dropdown {
        position: relative;
        display: inline-block;
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        color: #323232;
        padding: 0px 15px;
        height: 25px;
        line-height: 24px;
        border: 1px solid #e2e2e2;
        max-width: 100%;
        text-align: left;
        &:after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          right: 5px;
          top: 60%;
          margin-top: -6px;
          border-width: 6.4px 3.5px 0px 3.5px;
          border-style: solid;
          border-color: black transparent;
        }
        &.active {
          box-shadow: 0px 0px 1px 1px #418aca;
        }
        &.disabled {
          pointer-events: none;
          background: #f1f3f3;
          color: #333;
          border: none;
        }
        .dropbtn {
          background-color: transparent;
          color: black;
          border: none;
          display: inline-block;
          width: 100%;
          &.disabled {
            pointer-events: none;
            background: #f1f3f3;
            color: #333;
          }
          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
        #sports-search {
          height: 100%;
          width: 100%;
          border: none;
          &:focus {
            outline: none;
          }
        }
        .dropdown-content {
          display: none;
          flex-direction: column;
          position: absolute;
          background-color: #fff;
          overflow: auto;
          z-index: 1;
          width: 100%;
          left: 0;
          height: 144px;
          color: black;
          top: 25px;
          &.active {
            border: 1px solid #418aca;
          }
          .dropdown-list {
            padding: 5px;
            > label {
              display: flex;
              > span {
                margin-right: 5px;
                margin-top: 1px;
                > img {
                  height: 12px;
                }
              }
            }
            .path-icon {
              display: inline-block;
              padding: none;
              margin-right: 0;
            }
            &:hover {
              background-color: #418aca !important;
              color: #fff;
            }
            &.searched-item {
              background-color: #418aca !important;
              color: #fff;
            }
          }
        }
      }
      > div {
        display: flex;
        &:first-child {
          width: 50%;
          > div {
            width: 100%;
          }
        }
        &:not(:last-child) {
          padding-right: 5px;
        }
      }
      select {
        width: 100%;
      }
    }
    .path-search-container input {
      width: 100%;
    }
  }
}

#app-utilities {
  .page-container {
    .page-main {
      .wager-limit-group-management {
        .utilities-wager {
          align-items: flex-start;
        }
        header {
          background-color: #274c6e;
          padding: 4px;
          .market,
          .actions {
            position: relative;
            display: inline-block;
            float: left;
          }
          .market {
            span {
              display: block;
              margin: 2px;
            }
            .market-type {
              font-size: 16px;
              font-weight: 900;
            }
            .market-name {
              font-size: 10px;
              font-weight: 400;
            }
          }
          .actions {
            margin: 2px;
            color: white;
            li {
              float: left;
              padding: 4px 0;
              border-color: #fff;
              color: #fff;
              &.disabled {
                color: rgba(0, 0, 0, 0.25);
                border-color: rgba(0, 0, 0, 0.25);
              }
              &.plus,
              &.close {
                padding: 8px 0;
              }
            }
          }
        }
      }
      .hurdle-management-body {
        .hurdle-sequence-body {
          .hurdle-table {
            .rt-noData {
              margin-top: 11px;
            }
          }
          align-items: end;
          .sequence-table {
            padding-left: 0px;
            margin-right: -2px;
          }
          .description-panel {
            border: 1px solid #e4e2e2;
            padding: 10px;
            height: auto;
            .no-sequence-selected {
              text-align: center;
              color: #847f7f;
              font-weight: 800;
            }
            .description {
              display: table;
              width: 100%;
              .label {
                display: table-cell;
                width: 12%;
                margin-top: 3px;
                vertical-align: middle;
              }
              input {
                display: table-cell;
                float: right;
                width: 100%;
                outline: none;
                height: 26px;
              }
            }
            .section-container {
              margin-top: 10px;
              .padding-medium {
                padding: 0px !important;
              }
              .panel-header {
                display: flow-root;
              }
              .add-option {
                float: right;
                cursor: pointer;
                border: 1px solid #6fba65;
                color: #6fba65;
                width: 20px;
                height: 20px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;
                margin-right: 5px;
              }
              .add-option:hover {
                background-color: #6fba65;
                color: #ffffff;
              }
              .close-option {
                float: right;
                width: 20px;
                height: 20px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;
                margin-right: 5px;
              }
              .close-option.enabled {
                cursor: pointer;
                border: 1px solid #e60b0c;
                color: #e60b0c;
              }
              .close-option.disabled {
                cursor: not-allowed;
                border: 1px solid #bcbaba;
                color: #bcbaba;
              }
              .close-option.enabled:hover {
                background-color: #e60b0c;
                color: #ffffff;
              }
            }
          }
        }
        .hurdle-sequence-associations-body {
          .child-react-table {
            .rt-thead.-header {
              display: none;
            }
          }
        }
      }
      .shop-till-management-body {
        .shop-and-till-management-body {
          .information-wrapper {
            .form-wrapper.description {
              .parent.active {
                margin-left: -5px;
                margin-right: -5px;
                padding: 0 5px;
                background: #e9f4ff;
              }
            }
          }
        }
      }
      .exchange-rate-body {
        margin: 10px;
        .exchange-rate-table {
          margin-top: 10px;
          .rt-td {
            .previously-saved-ex-rate {
              color: #85929e;
            }
            .need-to-update-ex-rate {
              color: #000000;
            }
            .updating-ex-rate {
              color: #002aff;
            }
          }
        }
        .fetching-exchange-rate {
          height: 122px;
          margin-top: 10px;
          background: #ffffff;
          border-radius: 10px;
          i {
            margin: 0px auto;
            font-size: 37px;
          }
        }
      }
      .utilities-channel-body {
        margin: 10px;
      }
      .emergency-lockdown-body {
        margin: 10px;
        .outcome-totalling {
          margin-bottom: 5px;
          .username {
            margin-top: 5px;
            label {
              margin-left: 4px;
              margin-right: 17px;
            }
          }
          .password {
            margin-top: 5px;
            label {
              margin-left: 4px;
            }
          }
        }
        .transition-section {
          margin-top: 20px;
          .main-heading {
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 800;
            line-height: 20px;
            color: #418aca;
          }
          .main-container {
            background: #ffffff;
            border: 1px solid #c6c2c2;
            padding: 10px;
            .rdl-available {
              .available-heading {
                font-size: 11px;
                font-weight: 800;
                line-height: 20px;
                color: #418aca;
              }
              ul {
                border: 1px solid #e2e2e2;
                width: 100%;
                min-height: 162px;
                position: relative;
                padding: 5px;
                .parent-selection {
                  padding: 2px;
                  label {
                    display: flex;
                  }
                  .active-item {
                    color: #418aca;
                  }
                  .rp-unassigned-parent-selection {
                    margin-right: 0px;
                  }
                }
                .parent-selection:hover {
                  background: #e9f4ff;
                }
                .list-box-loading {
                  position: absolute;
                  left: 46%;
                  top: 37%;
                }
              }
            }
            .rdl-actions {
              button {
                background-color: #ebf5ff;
              }
              button:hover {
                background-color: #418aca;
              }
            }
            .rdl-selected {
              .selected-heading {
                font-size: 11px;
                font-weight: 800;
                line-height: 20px;
                color: #418aca;
              }
              ul {
                border: 1px solid #e2e2e2;
                width: 100%;
                min-height: 162px;
                position: relative;
                padding: 5px;
                .parent-selection {
                  padding: 2px;
                  label {
                    display: flex;
                  }
                  .active-item {
                    color: #418aca;
                  }
                  .rp-unassigned-parent-selection {
                    margin-right: 0px;
                  }
                }
                .parent-selection:hover {
                  background: #e9f4ff;
                }
                .list-box-loading {
                  position: absolute;
                  left: 46%;
                  top: 37%;
                }
              }
            }
          }
        }
      }
      .line-management-body {
        margin: 10px;
        .line-management-table {
          margin-top: 10px;
          .ReactTable {
            // .rt-tr.-odd :hover{
            //   background: #d5dae0
            // }
            // .rt-tr.-even :hover{
            //   background: #d5dae0
            // }
          }
        }
        .fetching-lines {
          height: 122px;
          margin-top: 10px;
          background: #ffffff;
          border-radius: 10px;
          i {
            margin: 0px auto;
            font-size: 37px;
          }
        }
      }
      .default-risk-management-body {
        margin: 10px;
        .outcome-totalling {
          margin-bottom: 5px;
          .heading {
            font-weight: 600;
          }
          .total-above {
            margin-top: 5px;
            label {
              margin-left: 4px;
            }
          }
          .total-below {
            margin-top: 5px;
            label {
              margin-left: 4px;
            }
          }
        }
        .ReactTable {
          .risk-input {
            input {
              width: 100%;
            }
          }
        }
      }
      .market-control {
        .hurdles,
        .alerts {
          align-items: flex-start;
          .-header {
            display: none;
          }
          .list-hurdles {
            padding: 3px 5px;
          }
          .description-panel {
            .description {
              margin-bottom: 5px;
              label {
                font-weight: 600;
              }
              input {
                width: calc(100% - 90px);
              }
            }
            > label {
              font-size: 11px;
            }
            .configuration {
              display: flex;
              margin-top: 3px;
              width: 100%;
              min-height: 600px;
              background: rgba(230, 230, 230, 1);
              .empty-configuration {
                border: 1px solid #c9c6c6;
                padding: 10px;
                border-radius: 5px;
                margin: 20px;
                padding-bottom: 40px;
                color: #418aca;
                font-size: 16px;
                text-align: center;
                width: 100%;
                height: 70px;
              }
              ul {
                margin: 7px;
                height: 100%;
                width: 100%;
                background: white;
                position: relative;
                li {
                  padding: 2px;
                  &.no-padding {
                    padding: 0;
                  }
                  &.padding {
                    padding: 10px 18px;
                  }
                  label {
                    display: inline-block;
                    width: 50%;
                  }
                  span {
                    .live-periods {
                      position: absolute;
                      left: 5px;
                    }
                  }
                }
              }
            }
          }
        }
        .alerts {
          .event-path {
            .event-container {
              background: #fff;
              border: 1px solid rgba(200, 200, 200, 0.2);
              h4 {
                background: rgba(200, 200, 200, 0.2);
                margin: 0;
                padding: 5px;
              }
              .event-content {
                span {
                  display: block;
                  padding: 5px 10px;
                }
              }
            }
          }
        }
      }
      .channel-priority-utility {
        .utilities-channel {
          margin-top: 50px;
        }
      }
      .market-enablement-body {
        margin: 10px;
        .wager-limit-select {
          padding: 0px 7px;
          margin: -5px 0;
          height: 21px;
          background: transparent;
          color: inherit;
          &:focus {
            background: white;
          }
        }
        .label-wd {
          width: 2%;
        }
        .market-table {
          align-items: end;
          .major-sort-input {
            margin-top: 20px;
            label {
              font-weight: 700;
              font-size: 12px;
              margin: 0 2px;
            }
            input {
              margin: 0 2px;
              height: 11px;
              width: 11px;
            }
            span {
              font-size: 10px;
            }
          }
        }

        // .select-optn{
        //     margin: 0px;
        // }
        .sport-label {
          padding-right: 0px !important;
          padding-left: 0.1rem !important;
        }
        .drop-label {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        .dropdown {
          position: relative;
          display: inline-block;
          width: 100%;
          border-radius: 5px;
          background-color: #fff;
          color: #323232;
          padding: 0px 15px;
          height: 26px;
          line-height: 24px;
          border: 1px solid #e2e2e2;
          max-width: 100%;
          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: 5px;
            top: 60%;
            margin-top: -6px;
            border-width: 6.4px 3.5px 0px 3.5px;
            border-style: solid;
            border-color: black transparent;
          }
          &.active {
            box-shadow: 0px 0px 1px 1px #418aca;
          }
          &.disabled {
            pointer-events: none;
            background: #f1f3f3;
            color: #333;
            border: none;
          }
          .dropbtn {
            background-color: #fff;
            color: black;
            border: none;
            display: inline-block;
            width: 100%;
            &.disabled {
              pointer-events: none;
              background: #f1f3f3;
              color: #333;
            }
            &:hover,
            &:focus {
              background-color: #fff;
            }
          }
          #sports-search {
            height: 100%;
            width: 100%;
            border: none;
            &:focus {
              outline: none;
            }
          }
          .dropdown-content {
            display: none;
            flex-direction: column;
            position: absolute;
            background-color: #fff;
            overflow: auto;
            z-index: 1;
            width: 100%;
            left: 0;
            height: 300px;
            color: black;
            top: 25px;
            &.active {
              border: 1px solid #418aca;
            }
            .dropdown-list {
              padding: 5px;
              > label {
                display: flex;
                > span {
                  margin-right: 5px;
                  margin-top: 1px;
                  > img {
                    height: 12px;
                  }
                }
              }
              .path-icon {
                display: inline-block;
                padding: none;
                margin-right: 0;
              }
              &:hover {
                background-color: #418aca !important;
                color: #fff;
              }
              &.searched-item {
                background-color: #418aca !important;
                color: #fff;
              }
            }
          }
        }
        .rt-resizable-header-content {
          margin: -5px -5px -7px;
          padding: 5px 5px 7px;
        }
        .header-cell-3 {
          display: block;
          margin: 0 -5px -7px -5px;
          padding: 0;
          .cell-3 {
            display: block;
            margin: -5px;
            margin-top: 4px;
            padding: 0 5px;
            height: 26px;
            border-top: 1px solid rgba(128, 128, 128, 0.4);
            span {
              display: inline-flex;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 33%;
              margin: 0;
              padding: 5px;
              height: 26px;
              &:nth-child(1),
              &:nth-child(2) {
                border-right: 1px solid rgba(128, 128, 128, 0.1);
              }
            }
          }
        }
        .cell-3 {
          display: block;
          margin: -5px;
          padding: 0 5px;
          height: 26px;
          span {
            display: inline-flex;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 33%;
            margin: 0;
            padding: 5px;
            height: 26px;
            &:nth-child(1),
            &:nth-child(2) {
              border-right: 1px solid rgba(128, 128, 128, 0.1);
            }
            input[type="text"] {
              margin-top: -4px;
            }
          }
        }
        input.input-read-write {
          height: 12px;
          color: inherit;
          &.read-mode {
            border: 0px;
            background: transparent;
          }
        }
        .header-cell-2 {
          display: block;
          margin: 0 -5px -7px -5px;
          padding: 0;
          & > span {
            padding: 5px;
          }
          .cell-2 {
            border-top: 1px solid rgba(128, 128, 128, 0.4);
            margin-top: 4px;
            span {
              display: inline-block;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        .description-with-image {
          display: flex;
          justify-content: space-between;
          .description {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img {
            margin: 2px;
          }
        }
        .cell-2 {
          display: block;
          margin: -5px;
          padding: 0 5px;
          height: 26px;
          span {
            display: inline-block;
            width: 50%;
            margin: 0;
            padding: 5px;
            height: 26px;
            .outcome-order-policy-american,
            .outcome-order-policy-normal {
              min-width: 72px !important;
              padding: 0px 7px;
              height: 21px;
              background: transparent;
              text-transform: capitalize;
              color: inherit;
              &:focus {
                background: white;
              }
              option {
                text-transform: capitalize;
              }
            }
            .outcome-order-policy-american {
              margin: -5px 0;
            }
            .outcome-order-policy-normal {
              margin: -5px 0 0 -5px;
            }
            &:nth-child(1) {
              border-right: 1px solid rgba(128, 128, 128, 0.1);
            }
          }
        }
        .non-american {
          span {
            width: 100%;
            border-right: none !important;
          }
        }
        .default-sort {
          padding: 0;
          div {
            width: 100%;
            height: 35px;
            padding: 10px 5px;
            &.selected {
              background: rgb(248, 242, 218);
              input {
                font-weight: 900;
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
}

.modal-wager-limit-market-type {
  width: 20%;
  height: auto;
  // margin-top: 20px;
  min-width: 500px;
  max-height: 600px;
  h4 {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 5px;
    span {
      padding: 6px;
      display: inline-block;
      text-align: center;
    }
    .close {
      float: right;
      right: 10px;
    }
  }
  .market-type-container {
    position: relative;
    margin-top: 35px;
  }
  .ReactTable {
    .rt-noData {
      margin-top: 11px;
      padding: 0px 0px;
    }
    .-pagination {
      max-height: 40px;
      .-center {
        .-pageInfo {
          margin: 0 3px;
          display: inline-block;
          .-pageJump {
            max-width: 30px;
          }
        }
        .-pageSizeOptions {
          select {
            min-width: 60px;
          }
        }
      }
    }
  }
  .modal-body {
    .form {
      padding: 3em 2em 1.5em;
      label {
        display: inline-block;
        margin: 3px 10px;
      }
      input,
      select {
        width: 60%;
        height: 25px;
        float: right;
        margin: 3px 0;
        outline: none;
      }
      .button-group {
        margin-top: 27px;
        button {
          margin: 0px;
        }
      }
    }
  }
}

.ReactTable {
  .rt-noData {
    padding: 0px 0px !important;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
}

.modal-add-line {
  width: 35%;
  margin-top: 10px;
  height: 150px;
}

.add-line-input {
  width: 95%;
  height: 30px !important;
  font-size: 15px !important;
  outline: none;
}

.add-line-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  //padding:3px 10px 10px 10px;
  background-color: white;
  text-align: center;
  button {
    width: 85px;
  }
}
.add-new-hurdle-modle {
  top: 46% !important;
  width: 50%;
  height: 60%;
  .modal-body {
    overflow-y: hidden;
  }
  .body {
    overflow-y: scroll;
    position: absolute;
    height: 82%;
    display: block;
    width: 100%;
    .entries-container {
      margin: 10px !important;
      .item-container {
        display: flex;
        label {
          margin-left: 5px;
          margin-top: 1px;
        }
      }
    }
  }
}

.shop-and-till-management-body {
  display: inline-block;
  width: 100%;
  .sidebar-body {
    float: left;
    width: 280px;
    min-height: 660px;
    height: auto;
    border: 1px solid #cecece;
    .path-container {
      .path-children {
        .path-icons.tills-children {
          color: #6fba65;
        }
      }
      .path-anchor.selected {
        background-color: #3e7ecb;
        .path-name-desc {
          color: #ffffff;
        }
      }
    }
  }
  .group-information {
    margin-left: 280px;
    padding-left: 5px;
    .no-group-selected {
      border: 1px solid #e2e2e2;
      border-radius: 3px;
      text-align: center;
      padding: 10px;
      font-size: 16px;
      color: #418aca;
    }
    .information-wrapper {
      // display: flex;
      // flex-direction: column;
      width: 80%;
      .field-wrapper {
        display: flex;
        //flex-basis: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        input[type="text"]:disabled {
          color: #000000;
        }
        .input-field {
          min-width: 51%;
          width: auto;
        }
      }
      .section-container {
        margin-top: 10px;
        .padding-medium {
          padding: 0px !important;
        }
        .panel-header {
          height: auto;
          display: flow-root;
        }
        .add-option {
          float: right;
          cursor: pointer;
          border: 1px solid #6fba65;
          color: #6fba65;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 3px;
          margin-right: 5px;
        }
        .add-option:hover {
          background-color: #6fba65;
          color: #ffffff;
        }
        .close-option {
          float: right;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 3px;
          margin-right: 5px;
        }
        .close-option.enabled {
          cursor: pointer;
          border: 1px solid #e60b0c;
          color: #e60b0c;
        }
        .close-option.disabled {
          cursor: not-allowed;
          border: 1px solid #bcbaba;
          color: #bcbaba;
        }
        .close-option.enabled:hover {
          background-color: #e60b0c;
          color: #ffffff;
        }

        ul {
          border: 1px solid #e2e2e2;
          width: 100%;
          min-height: 162px;
          position: relative;
          padding: 5px;
          .parent-selection {
            padding: 2px;
            label {
              display: flex;
            }
            .active-item {
              color: #418aca;
            }
            .rp-unassigned-parent-selection {
              margin-right: 0px;
            }
          }
          .parent-selection:hover {
            background: #e9f4ff;
          }
          .list-box-loading {
            position: absolute;
            left: 46%;
            top: 37%;
          }
        }
      }
    }
  }
}

.modal-shop-till.add-new-operator {
  height: 105px;
  width: 400px;
  .body {
    padding: 10px;
    input {
      width: 100%;
    }
    .search-result-container {
      border: 1px solid #000000;
      width: 95%;
      max-height: 300px;
      position: absolute;
      background: #ffffff;
      overflow: auto;
      z-index: 9;
      ul {
        li {
          padding: 5px;
        }
      }
    }
  }
  .add-line-footer {
    padding: 10px;
  }
}
