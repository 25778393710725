#app-shop-manager {
  background: #f2f2f2;
  header {
    overflow: initial;
  }

  .page-main {
    left: 250px;
    background-color: #edf2f7;

    .operator-message {
      // @include gradient-alert();
      background: $operator-message-color;
      line-height: $font-large;
      color: $white;
      font-size: $font-large;
      padding: 10px 0;
      border: 1px solid $primary-darker;
      border-width: 1px 0;
      // border-top: 10px solid $primary-darkest;
    }
    .tabular {
      overflow: hidden;
    }
    .tabular .react-tabs__tab-panel {
      height: calc(100% - 31px);
      overflow-y: auto;
    }
    .main-wrapper,
    .tabular,
    .react-tabs,
    .tab-content,
    .tab-content > div {
      height: 100%;
    }
    .main-wrapper {
      padding: 5px;
      background: $gray-lightest;
    }
    .operator-message {
      // @include gradient-alert();
      background: $operator-message-color;
      line-height: $font-large;
      color: $white;
      font-size: $font-large;
      padding: 10px 0;
      border: 1px solid $primary-darker;
      border-width: 1px 0;
      // border-top: 10px solid $primary-darkest;
    }
  }

  .shop-manager-sidebar {
    .width-24 {
      width: 24px;
    }
    @extend .event-creator-sidebar;
    .sidebar-body {
      padding: 0;
      ul.sidebar__items {
        .nav-item-sub {
          ul {
            margin: 0px;
          }
        }
      }
    }
    width: 250px;
    .path-icon {
      color: #ffca28;
    }
    .path-icon--settings {
      color: $primary;
    }
    .path-anchor {
      &.disabled {
        pointer-events: none;
        color: #ccc;
      }
    }
    .path-container {
      &.level-1:first-child {
        border-top: none;
      }
    }
    .side-bar-menu-left-icons {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
    }
  }

  .utilities-sidebar {
    @extend .event-creator-sidebar;
    .path-icon {
      color: #ffca28;
    }
    .path-icon--settings {
      color: $primary;
    }
    .path-anchor {
      &.disabled {
        pointer-events: none;
        color: #ccc;
      }
    }
    .path-container {
      &.level-1:first-child {
        border-top: none;
      }
    }
  }

  .subtop-container {
    width: 100%;
    height: 40px;
    background-color: #fff;

    .subtop-content {
      .acct-details-wrap {
        float: left;
        padding: 5px 10px;
        height: 35px;
        display: flex;
        align-items: center;
        .phx-refresh {
          float: left;
          color: $primary;
          font-size: 16px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
        }
        .acct-name {
          float: left;
          min-width: 200px;
          margin: 0px 20px 0px 10px;
          color: $black;
          font-size: 12px;
          max-width: 300px;
        }
      }
      .form-content {
        float: left;
        border: 1px solid $primary-lighter;
        border-radius: 5px;
        margin: 2px;
        .form-group {
          float: left;
          border-right: 1px solid $primary-lighter;
          padding: 2px 10px;
          &:last-child {
            border-right: 0px;
          }
          label {
            text-transform: uppercase;
            font-size: 10px;
            color: $primary;
            margin-right: 0px;
          }
          span {
            display: block;
            font-size: 11px;
          }
        }
        .btn-expand {
          float: left;
          width: 20px;
          line-height: 30px;
          color: $primary;
          cursor: pointer;
          text-align: center;
          @include transit();
          &:hover {
            color: $white;
            background-color: $primary;
          }
        }
        .acct-status {
          color: $white;
          height: 30px;
          border-radius: 3px 0px 0px 3px;
          &[data-status="open"] {
            background-color: $green;
          }
          &[data-status="open not pool"],
          &[data-status="closed"] {
            background-color: $red;
          }
          &[data-status="suspended"] {
            background-color: $orange;
          }
          &[data-status="suspended except login"],
          &[data-status="suspended password failure"] {
            background-color: $orange;
          }
          label {
            color: $white75;
          }
          span {
            text-transform: uppercase;
            font-size: 10px;
          }
        }
      }
      .transaction-actions {
        float: right;
        .btn-box {
          @include wh(35px, 28px);
          margin-right: 5px;
          margin-top: 4px;
          border-radius: 5px;
          border: 1px solid $primary-light;
          background-color: transparent;
          color: $primary;
          @include transit();
          float: left;
          font-size: 20px;
          line-height: 32px;
          &:not(.disabled) {
            &:hover {
              background-color: $primary;
              color: $white;
            }
          }
          &.disabled {
            border-color: $line-gray;
            background-color: $gray-lighter;
            color: $gray;
          }
        }
      }
    }
  }

  .form-group-control {
    select,
    input {
      width: 100%;
      height: 25px;
      line-height: 25px;
    }
  }
  .checkbox-label,
  .form-group-label {
    line-height: 25px;
    // padding: 2px 0;
    font-weight: 500;
  }

  .checkbox-label {
    font-size: 12px;
  }

  .form-wrapper {
    // overflow: visible;
    // > h4 {
    // 	background: $primary-dark;
    //     font-weight: bolder;
    //   		font-size: 1.25em;
    //   		// color: $black-secondary;
    //   		color: $white;
    // }
    .form-group {
      position: relative;
      color: $black-secondary;
      padding: 2px;
      &:hover {
        background-color: $primary-lightest;
      }
      select {
        margin: 0px;
      }
    }
    > div {
      &:not(.table-wrapper) {
        padding: 5px;
      }
    }
  }
  .info-message {
    display: block;
    margin: 0 0 10px;
    span,
    button {
      display: block;
      width: 100%;
      text-align: center;
      float: none;
    }
    span {
      padding: 0 0 3px 0;
    }
    button {
      box-shadow: none;
      background-color: $error-fg;
      color: $white;
    }
  }

  .wager-limit {
    .form-group {
      .btn {
        margin-right: 5px;
      }
      &.wagerlimit-actions {
        float: left;
      }
      &.wagerlimit-setdefault {
        float: left;
        border-left: 1px solid $line-gray;
        padding-left: 10px;
        label {
          margin-right: 5px;
          width: 120px;
        }
        .form-group-control {
          width: 350px;
          select {
            width: 300px;
          }
          .btn {
            margin-left: 10px;
          }
        }
      }
      &.wagerlimit-table {
        float: left;
        width: 100%;
      }
    }
  }

  input[type="text"][disabled],
  input[type="alphabet"][disabled],
  select[disabled],
  textarea[disabled],
  input[type="number"][disabled] {
    background: #f1f3f3;
    color: #34383c !important;
  }
}
