.affiliates-viewer-main-bg {
  position: absolute;
  @include wh(100%, 100vh);
  //background: url(../../assets/img/bg.png) center no-repeat;
  // @include gradient-alert();
  //background: linear-gradient(270deg, $xylo1, $xylo2, $xylo3);
  background: #b1ceef;
  background-position: 50% 0;
  background-size: 200% 200%;
  .login-wrapper {
    width: 30%;
    height: auto;
    margin: 0 auto;
    margin-top: 70px;
    background: #ffffff;
    border-radius: 17px;
    padding: 20px;
    border: 1px solid #1077ed;
    form {
      position: relative;
    }
    .login-head {
      width: 100%;
      display: block;
      margin: 0px auto 10px auto;
      border-bottom: 1px solid #3153f0;
      h3 {
        color: $gray-dark;
        font-weight: 300;
      }
      .logo-container {
        text-align: center;
        span {
          display: inline-block;
          background: #3e7ecb;
          height: 36px;
          max-width: 153px;
          img {
            height: 36px;
            width: 100%;
          }
        }
      }
      .heading {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        span {
          font-size: 25px;
          color: #2a6cb7;
        }
      }
    }
    .sign-up-container {
      width: 100%;
      display: block;
      margin-top: 42px;
      margin-bottom: 40px;
      .heading {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid black;
        margin-left: 30%;
        margin-right: 30%;
        padding-bottom: 10px;
        span {
          font-size: 17px;
          font-weight: 700;
          color: #2a6cb7;
          padding-bottom: 7px;
          cursor: pointer;
        }
      }
    }
    .login-container {
      width: 100%;
      margin: 0 auto;
      input {
        border-radius: 3px;
        width: 100%;
        height: 30px;
        -webkit-box-shadow: 0 0 0 30px white inset;
        border: 1px solid gray;
        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
    .btn-action {
      margin-top: 20px;
    }
  }

  .signUp-wrapper {
    width: 50%;
    height: auto;
    margin: 0 auto;
    margin-top: 70px;
    background: #ffffff;
    .signUp-modal-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 700px;
      max-width: 100%;
      z-index: 3;
      box-shadow: 0 0 2px 2px #566377;
      .close-button {
        background: rgba(0, 0, 0, 0.5);
        color: rgba(255, 255, 255, 0.5);
        height: 32px;
        line-height: 32px;
        width: 32px;
        position: absolute;
        top: -16px;
        right: -16px;
        z-index: 4;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.3s ease;
      }
      .section-container {
        .form-wrapper.description {
          margin-bottom: 1px !important;
        }
      }
      input {
        width: 100%;
        padding-left: 13px;
      }
      select {
        width: 100%;
      }
      hr {
        margin-bottom: 2%;
        margin-top: 2%;
        width: 90%;
      }
    }
  }
}

.app-affiliate-viewer {
  .page-container {
    .affiliate-viewer-sidebar {
      width: 250px;
      height: 100%;
      display: flex;
      border-right: solid 1px white;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      border-right: 1px solid #a9a9a9;

      .sidebar-header {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding: 10px;
        background-color: #ffffff;
        height: 160px;

        .balance-box {
          text-align: center;
          border: 1px solid #0ed0f7;
          border-radius: 15px;
          width: 100%;
          height: 100%;
          margin: 0 auto;
          background-color: #f3f3f3;

          .heading {
            margin-top: 19px;
            font-weight: 600;
            i {
              padding: 2px;
              border-radius: 3px;
              margin-left: 5px;
              font-size: 11px;
            }
            i.enabled {
              background: #3c70a0;
              color: #fff !important;
              cursor: pointer;
            }
            i.disabled {
              background: #ffffff;
              color: #000000 !important;
            }
          }

          .loading-balance {
            font-size: 31px;
            margin-top: 30px;
            color: #146eb7;
          }

          .content {
            padding: 10px;
            .title {
              float: left;
              font-weight: 600;
            }
            .value {
              float: right;
            }
          }

          .content.pending {
            padding-top: 0px !important;
          }

          hr {
            margin: 15px;
          }
        }
      }
      .sidebar__items {
        .nav-item {
          a.margin-Left {
            margin-left: 6%;
          }
        }
        .active-bold {
          font-weight: 700;
        }
      }
    }
    .page-main.no-footer {
      left: 250px;
      .revenue-withdrawals {
        .revenue-withdrawals-details-container {
          margin-top: 5px;
          padding-right: 0px;
          display: flex;
          .header.panel-header {
            display: flex;
            height: auto !important;
          }
          .padding-medium {
            height: auto;
            .revenues-item-box {
              height: 100px;
              width: 25%;
              float: left;
              margin-bottom: 5px;
              margin-top: 5px;
              .revenues-box {
                //cursor: pointer;
                text-align: center;
                border: 1px solid #0ed0f7;
                border-radius: 15px;
                width: 100%;
                height: 100% !important;
                margin: 0 auto;
                padding-top: 27px;
                .name {
                  font-weight: 900;
                }
                .value {
                  font-weight: bold;
                  font-size: x-large;
                  margin-top: 8px;
                }
                .players {
                  color: #3949ab;
                }
                .total-stake {
                  color: #1565c0;
                }
                .total-payout {
                  color: #ec407a;
                }
                .costs {
                  color: #3949ab;
                }
                .gross-revenue {
                  color: #8bc34a;
                }
                .net-revenue {
                  color: #388e3c;
                }
              }
            }
            .rate-item-box {
              height: auto;
              width: 25%;
              float: left;
              margin-bottom: 5px;
              margin-top: 5px;
              .rate-box {
                text-align: center;
                border: 1px solid #0ed0f7;
                border-radius: 15px;
                width: 100%;
                height: 100% !important;
                margin: 0 auto;
                padding: 10px;
                .heading {
                  margin: 0px 0px 14px 0px;
                  font-size: 1.25em;
                  font-weight: 800;
                }
                .no-commission-tier {
                  text-align: center;
                  font-size: 25px;
                }
                .commission-tier-box {
                  margin: 5px 0px 5px 0px;
                  text-align: left;
                  .product-name {
                    font-weight: 600;
                    color: blue;
                  }
                  .range-name {
                    color: #616161;
                    margin: 5px 0px 5px 0px;
                  }
                  .commission-tier .ReactTable .rt-table .rt-thead .rt-tr {
                    background: #3e7ecb;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
        .my-account {
          align-items: unset !important;
          .panel-header {
            display: flex;
            height: auto !important;
          }
          .account-setting {
            margin: 5px;
            .padding-medium.row {
              align-items: baseline;
              .row .title {
                font-weight: 900;
                margin: 16px 0px 15px 0px;
              }
            }
          }
          .change-password {
            margin: 5px;
            .padding-medium.password {
              text-align: center;
            }
            .padding-medium.enter-password {
              margin: 0px 35px 0px 35px;
              input {
                width: 100%;
                outline: none;
                border: 1px solid black;
              }
            }
            button {
              width: 100%;
              -webkit-box-shadow: none;
              box-shadow: none;
              border-radius: 9px;
            }
          }
          .account-info {
            margin: 5px;
            .account-form {
              padding: 5px !important;
              align-items: baseline;
              .name {
                margin-top: 12px;
              }
              .field input {
                width: 100%;
                outline: none;
                border: 1px solid black;
              }
              .field select {
                width: 100%;
                outline: none;
                border: 1px solid black;
              }
            }
          }
          .tills-sms-code {
            align-items: baseline;
            display: block;
            .tills {
              margin: 5px;
              .tills-section {
                padding: 25px !important;
                .rt-noData {
                  display: none;
                }
                .ReactTable .rt-table .rt-thead .rt-tr {
                  background: #3e7ecb;
                  color: #ffffff;
                }
              }
            }
            .sms-code {
              margin: 5px;
              .sms-code-section {
                padding: 25px !important;
                .rt-noData {
                  display: none;
                }
                .ReactTable .rt-table .rt-thead .rt-tr {
                  background: #3e7ecb;
                  color: #ffffff;
                }
              }
            }
          }
        }
        .description {
          overflow: visible;
          &.left {
            margin-right: 10px;
            width: 100%;
          }
          &.right {
            flex: 1;
          }
        }
        .withdrawals-section {
          .error-messages {
            margin: 0 auto;
            border: none;
            margin-bottom: 10px;
          }
          .first {
            border: 1px solid #0ed0f7;
            border-radius: 15px;
            margin: 0 auto;
            padding: 20px;
            .fund {
              text-align: left;
              font-weight: 900;
            }
            .amount {
              text-align: left;
              margin-top: 15px;
              input {
                outline: none;
                border: 1px solid black;
                width: 100%;
              }
            }
            .button {
              margin-top: 15px;
              button {
                width: 100%;
                box-shadow: none;
                border-radius: 9px;
              }
            }
          }
          .second {
            margin: 0 auto;
            margin-top: 20px;
            label {
              color: #616161;
            }
            .ReactTable .rt-table .rt-thead .rt-tr {
              background: #3e7ecb;
              color: #ffffff;
            }
          }
        }
        .affiliate-viewer-report {
          .module-header {
            height: auto;
            background-color: #274c6e;
            display: block;
            position: relative;
            padding: 5px 10px;
            color: #fff;
            .row {
              .col-xs-12 {
                margin-top: 3px;
                i {
                  margin-top: -2px;
                }
              }
            }
            .header-title {
              display: inline-block;
              padding: 5px;
              i {
                font-size: 18px;
                color: #ffffff;
              }
            }
            .header-title.no-data {
              color: #ff4848;
            }
            .status-filter {
              display: block;
              padding-right: 10px;
              float: right;
              select {
                margin: 0;
                float: left;
                width: 100px;
                margin-right: 10px;
              }
              .search-input-wrapper {
                display: flex;
                float: right;
                .search-field-container {
                  background: white;
                  border-radius: 4px;
                  input {
                    height: 25px;
                    outline: none;
                    border: none;
                    width: 87%;
                  }
                  .close-button {
                    background: rgba(0, 0, 0, 0.5);
                    color: rgba(255, 255, 255, 0.5);
                    height: 19px;
                    line-height: 15px;
                    width: 20px;
                    margin-top: 3px;
                    margin-right: 4px;
                    position: absolute;
                    right: 23px;
                    z-index: 4;
                    text-decoration: none;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 50%;
                    transition: all 0.3s ease;
                    padding: 4px 2px 2px 2px;
                    i {
                      color: #ffffff;
                      width: 10%;
                    }
                  }
                }
                .search {
                  margin-top: 7px;
                }
              }
            }
          }
          .report-footer {
            position: fixed;
            bottom: 0px;
            right: 0px;
            height: 50px;
            width: 100%;
            background: #fff;
            border-top: 1px solid #e2e2e2;
            padding: 10px;
          }
          .reports-format-title {
            margin-right: 20px;
            text-transform: uppercase;
            font-size: 12px;
          }
          .reports-format-btn {
            float: right;
          }
          .reports-footer-inner {
            padding-top: 5px;
            float: left;
          }
        }
      }
    }
  }
}
