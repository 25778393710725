#app-operator-manager {
  .control-buttons.header-actions {
    .control-buttons {
      margin-left: 5px;
      position: relative;
      &:before {
        content: "";
        height: 30px;
        width: 1px;
        background-color: $white25;
        position: absolute;
        top: 9px;
        left: 0px;
      }
    }
  }
  .operator-manager-main {
    left: 250px;
  }
  .operator-manager-sidebar {
    width: 250px;
    height: 100%;
    display: flex;
    border-right: solid 1px white;
    flex-direction: column;
    .sidebar-header {
      .operator-list-search {
        width: 100%;
        position: relative;
        input[type="text"] {
          width: 100%;
          height: 24px;
          padding: 3px;
        }
        i {
          position: absolute;
          right: 4px;
          top: 3px;
          width: 20px;
          opacity: 0.5;
          line-height: 18px;
          border-radius: 10px;
          background-color: #418aca;
          text-align: center;
          transition: all 0.3s ease;
        }
      }
      .operator-list-filter {
        select {
          width: 190px;
        }
        button {
          height: 25px;
          margin-left: 4px;
          box-shadow: none;
        }
      }
    }

    .operator-list {
      .operator-group {
        &.-none {
          display: none;
        }
        .operator-header {
          .phx-account-multiple {
            color: $primary;
          }
          i {
            float: left;
          }
          .operator-header-description {
            cursor: pointer;
            font-size: 11px;
            margin: 2px 0px 3px 0;
            float: left;
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.-active {
              color: $primary;
            }
            &:hover {
              color: $primary;
            }
            &.-match {
              color: $secondary-dark;
            }
          }
          .operator-header-count {
            float: right;
            margin-top: 2px;
            font-size: 11px;
            padding: 2px 6px;
            border-radius: 10px;
            background-color: #cccccc;
            color: #fff;
          }
        }

        .operator-group-list {
          margin-left: 18px;
          height: 0px;
          .phx-account {
            margin-right: 4px;
          }
          &.-closed,
          &.-none {
            overflow: hidden;
          }
          &.-open {
            height: 100%;
            overflow: hidden;
          }
        }
        .operator {
          cursor: pointer;
          font-size: 12px;
          &.-active {
            font-weight: bold;
            color: $primary;
          }
          &:hover {
            color: #418aca;
          }
          &.-match {
            color: $secondary-dark;
          }
          .operator-username {
            width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
    }
  }

  h5 {
    margin: 5px 0;
  }

  .permission-panel {
    .form-wrapper {
      display: flex;
      flex-flow: column;
      height: 100%;
    }
    .desktop-full {
      > .form-group-label {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 800;
        line-height: 20px;
        color: $primary;
      }
      > .application-permission,
      > .action-permission {
        margin-bottom: 10px;
      }
    }
    .rdl-move {
      background-color: $primary-lightest;
      color: $primary;
      border: 0px solid;
      &:hover {
        background-color: $primary;
        color: $white;
      }
      span {
        font-family: "phxicons" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .rdl-move-right {
      span:first-child {
        &:before {
          // content : 'I'
          content: "\F145";
        }
      }
    }
    .rdl-move-left {
      span:first-child {
        &:before {
          // content : 'I'
          content: "\F144";
        }
      }
    }
    .rdl-move-all {
      &.rdl-move-right {
        span:first-child {
          &:before {
            // content : 'I'
            content: "\F141";
          }
        }
      }
      &.rdl-move-left {
        span:first-child {
          &:before {
            // content : 'I'
            content: "\F140";
          }
        }
      }
    }
    select {
      option {
        font-weight: 400;
      }
      // 	optgroup {
      // 		&:before {
      // 			font-family: 'phxicons' !important;
      // 		    speak: none;
      // 		    font-style: normal;
      // 		    font-weight: normal;
      // 		    font-variant: normal;
      // 		    text-transform: none;
      // 		    line-height: 1;
      // 		    -webkit-font-smoothing: antialiased;
      // 			content: "\f251";
      // 		}
      // 	}
    }
    .list-box {
      border: 1px solid $line-gray;
      border-radius: 2px;
      // height:116px;
      overflow: hidden;
      overflow-y: auto;
      padding: 5px 2px;
      width: 100%;
      height: 25vh;
      li {
        padding: 1px 2px;
        font-size: 14px;
        user-select: none;
        &.parent {
          // font-weight:600;
          .parent-selection {
            // width:100%;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            flex-direction: row;
            align-items: center;
            &:not(disabled) {
              &:hover {
                background: $primary-lightest;
                label {
                  &.active {
                    // color: $white;
                  }
                }
              }
            }
          }
        }
        a {
          color: #333;
          float: left;
        }
        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0 5px;
          &.active {
            font-weight: 400;
            color: $primary;
          }
        }
      }
      .list-box-child {
        margin-left: 23px;
        label {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .selected {
      background: $primary-lightest;
      // color:#FFF;
      a {
        // color:#FFF !important;
      }
    }

    &.operator-open {
      .form-wrapper {
        // height:70vh;
        height: 100%;
      }
      .list-box {
        height: 19vh;
      }
    }
  }
  .operator-group-details {
    margin-top: 5px;
    > div[type="row"] {
      padding: 0px 5px;
    }
    .form-group-label {
      // width: auto;
      // margin-right: 10px;
    }
    .form-group-control {
      // width: calc(100% - 100px);
      input {
        width: 100%;
      }
    }
  }
  .operator-details {
    > div[type="row"] {
      padding: 0px 5px;
    }
  }
  .report-desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      background: $primary-lightest;
    }
    &.active {
      color: $primary;
    }
  }
  .report-toggle {
    display: flex;
    flex: 0 0 20px;
  }
  .report-checkbox {
    display: flex;
    flex: 0 0 15px;
  }
}

.change-password-modal {
  .modal-container {
    height: 200px;
    width: 350px;
    overflow: hidden;
    form {
      padding: 0;
      .form-wrapper {
        border: 0;
        .field-err {
          position: relative;
          float: right;
          top: 2px;
        }
      }
    }
    .modal-controls {
      padding: 0;
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
  .form-inner {
    margin: auto;
  }
  .desktop-full {
    padding: 20px;
  }
}

.ReactModal__Content {
  &[aria-label="New Operator"],
  &[aria-label="New Operator Group"],
  &[aria-label="Duplicate Operator"],
  &[aria-label="Duplicate Group"] {
    .modal-container {
      width: 400px;
      .form-wrapper {
        margin-bottom: 0px;
        padding: 5px 10px;
        border: 0px;
        .form-group {
          padding: 2px 5px;
          @include transit();
          &:hover {
            background-color: $primary-lightest;
          }
          input,
          select {
            width: 100%;
            margin: 0px;
          }
          select {
            height: 20px;
          }
          .field-err {
            position: relative;
            float: right;
            top: 2px;
          }
        }
      }
      .button-group.modal-controls {
        position: absolute;
        width: 100%;
        bottom: 0px;
        .loader {
          width: 100%;
          height: 50px;
          i {
            color: $primary;
            text-align: 50px;
            width: 100%;
          }
        }
      }
    }
  }
  &[aria-label="New Operator"] {
    .modal-container {
      height: 260px;
    }
  }
  &[aria-label="New Operator Group"] {
    .modal-container {
      height: 165px;
    }
  }
  &[aria-label="Duplicate Group"] {
    .modal-container {
      height: 150px;
    }
  }
  &[aria-label="Duplicate Operator"] {
    .modal-container {
      height: 160px;
    }
  }
  .form-new-operator-password {
    padding-left: 14px;
  }
  .form-new-operator-username {
    padding-left: 14px;
  }
}

.react-dual-listbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-dual-listbox * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-dual-listbox input:disabled,
.react-dual-listbox select:disabled {
  background: #eee;
  cursor: not-allowed;
}

.react-dual-listbox button,
.react-dual-listbox select {
  line-height: 1.42857;
  font-family: inherit;
}

.rdl-listbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.rdl-filter,
.rdl-control {
  display: block;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  color: #333;
  font-size: 14px;
}

.rdl-filter {
  margin-bottom: 10px;
}

.rdl-control-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.rdl-control-label {
  position: absolute;
  clip: rect(0 0 0 0);
}

.rdl-control {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.rdl-control optgroup {
  font: inherit;
  font-weight: 700;
}

.rdl-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 10px;
}

.rdl-has-filter .rdl-actions {
  padding-top: 44px;
}

.rdl-actions-right,
.rdl-actions-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rdl-actions-right {
  margin-bottom: 10px;
}

.rdl-move {
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  color: #333;
  font-size: 12px;
}

.rdl-move:active:not(:disabled),
.rdl-move:focus:not(:disabled) {
  border-color: #8c8c8c;
  background: #e6e6e6;
}

.rdl-move:focus:not(:disabled) {
  outline: thin dotted;
  outline-offset: -2px;
}

.rdl-move:hover:not(:disabled) {
  border-color: #adadad;
  background: #e6e6e6;
}

.rdl-move:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.rdl-move:last-child {
  margin-bottom: 0;
}

.rdl-move i {
  margin: 0 -1px;
}

.rdl-align-top .rdl-available {
  margin-right: 10px;
}

.rdl-align-top .rdl-selected {
  margin-left: 10px;
}

.rdl-align-top .rdl-control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rdl-align-top .rdl-actions-left,
.rdl-align-top .rdl-actions-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0;
}

.rdl-align-top .rdl-move {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.rdl-align-top .rdl-move:first-child {
  margin-bottom: 0;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rdl-align-top .rdl-move:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
