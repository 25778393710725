.btn,
.button,
button,
a {
  cursor: pointer;
  &.marg-left {
    margin-left: 10px;
  }
  &.center {
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  &[disabled] {
    cursor: not-allowed;
  }
}

/*.btn, .button, button { this is from the componentization*/
.btn-componentized {
  /*remove this and uncomment above line when all buttons are ready to be changed*/
  min-width: 60px;
  height: 25px;
  line-height: 25px;
  background: none;
  border: 1px solid $primary;
  border-radius: 3px;
  text-align: center;
  color: $primary;
  padding: 0px 10px;
  @include transit();
  &:hover {
    cursor: pointer;
    background-color: $primary-lightest;
  }
  &[disabled],
  &.disabled {
    border-color: $gray-light;
    color: $gray-light;
    background: $gray-lightest;
    &:hover {
      cursor: default;
      background: $gray-lightest;
    }
  }
  &:not(.btn-icon) i {
    margin-right: 5px;
  }
}

/*.btn-primary, .btn.active { this is from the componentization*/
.btn-primary,
.btn-componentized.active {
  /*remove this and uncomment above line when all buttons are ready to be changed*/
  background: $primary;
  &:not([disabled]),
  &:not(.disabled) {
    color: $white;
    background-color: $primary;
    &:hover {
      background-color: $primary-light;
      color: $white;
    }
  }
  &[disabled],
  &.disabled {
    border-color: $gray-light;
    color: $gray-light;
    background: $gray-lightest;
    &:hover {
      background-color: $gray-lightest;
      color: $gray-light;
    }
  }
}

.btn-outline {
  padding: 10px;
  border: 1px solid $white50;
  background: none;
  border-radius: $radius;
  @include transit();
  &:hover {
    background: $white;
    color: $primary !important;
  }
}

.button-group {
  text-align: center;
  margin: 10px 0px;
  padding: 0 10px;
  .risk-analysis-header & {
    margin-bottom: 0;
  }
  &.list-reset {
    margin: 0px;
  }
  &.ftop {
    margin: 0px;
  }
  &.alignright {
    text-align: right;
  }
  &.alignleft {
    text-align: left;
  }
  > button,
  > .btn-grp-child {
    border: 0px;
    background: $primary-lightest;
    padding: 6px 10px;
    box-shadow: 0px 3px $primary-lighter;
    cursor: pointer;
    border-right: 1px solid $primary50;
    //float: left;
    margin-bottom: 10px;
    min-width: 100px;
    border-radius: 0px;
    @include transit();
    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    &:last-child {
      border-radius: 0px 5px 5px 0px;
      border-right: 0px solid;
    }
    &:not([disabled]):hover {
      background: $primary-lighter;
      box-shadow: 0px 3px $primary-darker;
      color: $white;
      border-color: $primary;
    }
    &[disabled] {
      background: $gray-lighter;
      box-shadow: 0px 3px $gray-light;
      cursor: not-allowed;
      color: $gray;
    }
    &.btn-primary:not([disabled]) {
      background: $primary;
      color: $white;
      box-shadow: 0px 3px $primary-darker;
      border-color: $primary;
      &:hover {
        background: $primary-lighter;
      }
    }
    a {
      color: $black;
      text-decoration: none;
    }
  }
}

.btn-box {
  min-width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 3px;
  text-align: center;
  margin-right: 5px;
  padding: 0;
  border: 1px solid $primary-light;
  color: $primary-light;
  background: none;
  a {
    line-height: 30px;
  }
  &:focus {
    outline: none;
  }
  &[disabled],
  &.disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
  &:hover:not(.disabled),
  &.active:not(.disabled) {
    background: $primary-light;
    color: $white;
    a,
    i,
    span {
      color: $white;
    }
  }
  &.active:not(.disabled) {
    box-shadow: inset 0 0 2px 2px $primary;
  }
}

.btn-icononly,
.btn-icon {
  cursor: pointer;
  border: 0px;
  color: $primary;
  border-radius: 3px;
  @include wh(35px, 25px);
  min-width: 25px;
  @include transit();
  padding: 0px;
  &:not(.disabled) {
    &:hover {
      background-color: $primary-lightest;
      color: $primary;
    }
  }
}

.btn-action,
button {
  @include btn();
}

.btn-primary {
  &:not([disabled]) {
    color: $white;
    background-color: $primary-lighter;
    box-shadow: 0px 3px $primary-dark;
  }
}

.btn-long {
  width: 100%;
  height: 30px;
  text-transform: uppercase;
  font-weight: $fw-light;
}

.btn-launch {
  @extend .btn-primary;
  width: 200px;
  line-height: 40px;
  height: 40px;
  padding-left: 0px;
  box-shadow: none;
  &:hover {
    .app-icon {
      @include transit();
      @include brightness(120%);
    }
  }
  .app-icon {
    @include wh(50px, 100%);
    float: left;
    background-color: $primary-lightest;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
  }
  span {
    margin-left: 10px;
  }
  &.active {
    background-color: $secondary;
    .app-icon {
      background-color: $secondary-lightest;
    }
  }
}
