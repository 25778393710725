#app-lobby {
  header {
    .logo:before {
      display: none;
    }
  }
  .page-container {
    background-color: $gray-lightest;
  }
  .btn-launch {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    &[data-app="Risk Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/risk-manager.png);
        background-color: $clr-risk;
      }
    }
    &[data-app="Customer Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/customer-manager.png);
        background-color: $clr-cust;
      }
    }
    &[data-app="Event Creator"] {
      .app-icon {
        background-image: url(../../assets/logos/event-creator.png);
        background-color: $clr-evnt;
      }
    }
    &[data-app="Results Viewer"] {
      .app-icon {
        background-image: url(../../assets/logos/results-viewer.png);
        background-color: $clr-rslt;
      }
    }

    &[data-app="Customer Chat"] {
      .app-icon {
        background-image: url(../../assets/logos/customer-chat.png);
        background-color: $clr-chat;
      }
    }

    &[data-app="Instant Action"] {
      .app-icon {
        background-image: url(../../assets/logos/instant-action.png);
        background-color: $clr-inst;
      }
    }

    &[data-app="Reports Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/reports-manager.png);
        background-color: $clr-rprt;
      }
    }
    &[data-app="Operator Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/operator-manager.png);
        background-color: $clr-oper;
      }
    }
    &[data-app="Payment Handler"] {
      .app-icon {
        background-image: url(../../assets/logos/payment-handler.png);
        background-color: $clr-paym;
      }
    }
    &[data-app="Utilities"] {
      .app-icon {
        background-image: url(../../assets/logos/utilities.png);
        background-color: $clr-util;
      }
    }
    &[data-app="Bet Attendant"] {
      .app-icon {
        background-image: url(../../assets/logos/bet-attendant.png);
        background-color: $clr-betatn;
      }
    }
    &[data-app="Combination Risk Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/combi-risk-manager.png);
        background-color: $clr-crm;
      }
      //because it has longer description
      span {
        line-height: 12px;
        margin-top: 8px;
        display: block;
        margin-left: 60px;
      }
    }
    &[data-app="Bonus Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/bonus-manager.png);
        background-color: $clr-bons;
      }
    }
    &[data-app="Jackpot Bet Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/jackpot-bet-manager.png);
        background-color: $clr-jbm;
      }
    }
    &[data-app="Translation Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/translation-manager.png);
        background-color: $clr-tm;
      }
    }
    &[data-app="Wager Limits"] {
      .app-icon {
        background-image: url(../../assets/logos/wager-limits.png);
        background-color: $clr-wl;
      }
    }
    &[data-app="Affiliate Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/affiliate-manager.png);
        background-color: $clr-aff;
      }
    }
    &[data-app="Settings Browser"] {
      .app-icon {
        background-image: url(../../assets/logos/affiliate-manager.png);
        background-color: $clr-aff;
      }
    }
    &[data-app="Shop Manager"] {
      .app-icon {
        background-image: url(../../assets/logos/shop-manager.png);
        background-color: $clr-aff;
      }
    }
  }
}
.flexCenter {
  display: flex;
  align-items: center;
}
