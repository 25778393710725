.rank-import-opponents {
  height: calc(100% - 32px);
  overflow: hidden;
  .multi-select-list {
    height: calc(100% - 48px);
    overflow: hidden;
    > ul {
      height: calc(100% - 32px);
      overflow-y: auto;
      overflow-x: hidden;
      .checkbox {
        height: 18px;
      }
    }
  }
}
.import-button-container {
  margin-bottom: 8px;
}

.opponents-table-container {
  position: relative;
  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 70px 0 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.event-container {
  height: 100%;
}

.market-page {
  height: 100%;
}

.market-container {
  display: flex;
  flex-direction: column;

  .columns-container {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 10px;
    .form-details {
      flex: 1 1 auto;
    }
    .form-attributes {
      width: 180px;
      min-width: 180px;
      flex: 0 0 auto;
    }
    .form-books {
      width: 250px;
      min-width: 250px;
      flex: 0 0 auto;
    }
    > div {
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .outcomes-container {
    flex: 1 1 auto;
    padding: 0 10px 10px;
    min-height: 180px;
  }

  .form-books,
  .form-attributes,
  .form-details,
  .outcomes-container {
    display: flex;
    flex-direction: column;
    .form-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      .panel-content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .form-details,
  .form-books {
    .form-group {
      display: flex;
      align-items: center;
    }
  }
  .form-details {
    .form-wrapper {
      overflow: visible;
    }
    .form-group {
      &.disabled {
        opacity: 0.5;
      }
      .checkbox-label {
        padding-left: 5px;
      }
    }
  }
  .form-books {
    .book-types-container,
    .book-rules-container {
      display: flex;
      align-items: center;
      label {
        margin-right: 12px;
      }
      input {
        margin-right: 5px;
      }
    }
    .book-types-container {
      flex-direction: row;
    }
    .book-rules-container {
      flex-direction: column;
      align-items: flex-start;
      > div {
        line-height: 25px;
      }
    }
    .book-rules-label {
      align-self: flex-start;
    }
    .instance-sp-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 34px;
    }
    .sp-checkbox-container {
      label {
        margin: 0 0 0 4px;
      }
    }
  }

  // override
  .form-details .form-group-label {
    width: 100px;
  }
  .form-details .form-group-control {
    width: calc(100% - 100px);
  }
  .form-books .form-group-label {
    width: 80px;
  }
  .form-books .form-group-control {
    width: calc(100% - 80px);
  }
  .form-wrapper .panel-header {
    padding: 5px !important; // forced to this
    display: flex;
    align-items: center;
    font-size: 13px;
    .panel-header-title {
      flex: 1 1 auto;
    }
    .panel-header-actions {
      float: none;
    }
  }
  // end override

  .time-field {
    .form-group-label {
      display: none;
    }
    .form-group-control {
      width: 100%;
    }
  }

  .custom-table {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    .rt-body {
      flex: 1 1 auto;
      overflow-y: auto;
    }
  }
  .status-radio-container {
    display: flex;
    float: left;
    align-items: center;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    input {
      margin-right: 6px;
    }
    label {
      display: flex;
      margin: 0;
      i {
        margin-right: 2px;
      }
    }
  }
}

.book-manager-container,
.new-book-container {
  .form-group {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .book-types-container,
  .book-rules-container {
    display: flex;
    align-items: center;
    label {
      margin-right: 12px;
    }
    input {
      margin-right: 5px;
    }
  }
  .sp-checkbox-container {
    input {
      margin-right: 6px;
    }
  }
}
.book-manager-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  position: relative;
  .form-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .panel-content {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .book-fields {
    padding: 10px 50px;
  }
  .book-row {
    display: flex;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid #f1f1f1;
    input {
      width: 20px;
    }
    &.active {
      background-color: $primary;
      border-bottom-color: lighten($primary, 5%);
    }
    &:not(.active):hover {
      background-color: #f1f1f1;
    }
  }
  .book-details {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    span {
      margin: 2px;
    }
  }
  .panel-header {
    display: flex;
    min-height: 34px;
  }
  .panel-header-title {
    flex: 1 1 auto;
  }
  .panel-header-actions {
    flex: 0 0 auto;
  }
  .panel-header-error {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    padding: 0 10px;
  }
  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.create-new-book {
  display: flex;
  flex-direction: column;
}
.new-book-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .book-fields {
    padding: 10px 30px;
    flex: 1 1 auto;
  }
  .form-group .form-group-label {
    width: 100px;
  }
}

.new-market {
  .modal-body {
    display: flex;
    flex-direction: column;
  }
  .modal-content-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
  }
}
.market-types-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .market-filters {
    flex: 0 0 auto;
    min-height: 40px;
    display: flex;
    align-items: center;
  }
  .default-filters {
    display: flex;
    flex: 1 1 auto;
  }
  .advance-filters {
    display: flex;
    align-items: center;
  }
  .btn-box {
    box-shadow: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    &:hover {
      box-shadow: none;
    }
  }
  .search-filter {
    input {
      height: 24px;
    }
  }
  .market-types {
    display: flex;
    flex: 1 1 auto;
    padding: 10px;
    .inner {
      border: 1px solid #e1e1e1;
      overflow-y: auto;
      width: 100%;
    }
    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 auto;
    }
  }
  .button-group {
    flex: 0 0 auto;
  }
  .market-type {
    padding: 5px;
    border-bottom: 1px solid #f1f1f1;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f1f1f1;
    }
  }
  .market-row {
    display: flex;
    align-items: center;
  }
  .market-row-players {
    display: flex;
    align-items: flex-start;
  }
  .market-details {
    flex: 1 1 auto;
  }
  .market-main-inputs {
    flex: 0 0 auto;
    display: flex;
    input {
      width: 100px;
      margin: 0 2px;
    }
    select {
      height: 20px;
      margin: 0;
    }
  }
  input {
    border-radius: 2px;
  }
  .player-table {
    flex: 1 1 auto;
    max-width: 50%;
    padding: 5px 3px 0;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    .col-input {
      width: 100px;
    }
    .rt-tr {
      display: flex;
    }
    .rt-th,
    .rt-td {
      display: flex;
      align-items: center;
    }
    .col-desc {
      flex: 1 1 auto;
    }
  }
}

.more-periods-modal {
  .modal-container {
    height: 420px;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}

.modal-controls,
.modal-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 54px;
  background-color: #f9f9f9;
  padding: 0 10px;
}
.modal-controls {
  &.button-group {
    margin: 0;
    button {
      margin-bottom: 0;
    }
  }
}

.more-periods-container {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 10px;
  ul {
    padding: 2px 5px 2px 20px;
  }
  > ul {
    padding-left: 0;
  }
}
.period-path-name {
  display: flex;
  align-items: center;
}
.period-path-toggle,
.period-path-spacer {
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
}

.market-types-container,
.more-periods-container {
  input + label {
    margin-left: 5px;
  }
}

#app-event-creator {
  .rank-event-form,
  .game-event-form {
    .form-wrapper {
      .checkboxes {
        .form-group {
          padding: 0 5px;
          &.disabled {
            opacity: 0.5;
          }
          .checkbox-label {
            padding-left: 5px;
            .sublabel {
              color: $gray;
              padding-left: 15px;
              font-style: italic;
              display: block;
              line-height: 12px;
            }
          }
        }
        column-count: 3;
      }
    }
    .other-options {
      padding: 0 5px;
      select {
        width: auto;
        margin: 0;
        height: 24px;
      }
    }
    .other-options-label {
      margin-right: 10px;
    }
    .other-options-option {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      input {
        margin-right: 5px;
      }
    }
  }
  .col-visibility {
    width: 50px;
    text-align: center;
    @include transit();
    &:not(.disabled) {
      cursor: pointer;
      cursor: pointer;
      &:hover {
        color: $primary;
        .phx-eye-off-outline {
          color: $primary;
        }
      }
    }
    .phx-eye-off-outline {
      color: $red;
    }
  }
  .col-numbers {
    width: 150px;
    padding: 2px;
  }
  .rank-opponent-input,
  .rank-opponent-inactive {
    width: 100%;
    border-radius: 2px;
    height: 22px;
    padding: 3px 5px;
  }
  .rank-opponent-inactive {
    border: none;
  }
  .game-event {
    .desktop-three-quarter {
      width: 99% !important;
    }
    .desktop-quarter {
      width: 35% !important;
    }
  }
}
.custom-table {
  .rt-thead .rt-tr {
    background-color: #e2e2e2;
  }
  .rt-thead .rt-th,
  .rt-thead .rt-td {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }
  .rt-tbody .rt-td {
    padding: 2px;
    line-height: normal;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    &:first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
  .rt-tr {
    display: table;
    width: 100%;
  }
  .rt-th,
  .rt-td {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
  }
  .rt-tbody {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rt-tr-group:hover {
    background-color: #f1f1f1;
  }
}

.event_path--bottom_bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
  margin: 0px 0.3rem;

  .bottom_bar--left_padding {
    display: inline-block;
    flex-grow: 0;
    width: 155px;
    min-width: 155px;
    max-width: 155px;
  }

  .bottom_bar--button + .bottom_bar--button {
    margin-left: 10px;
  }
}

.cutoff-market-modal-container {
  .auto-open {
    width: 160px;
    input[disabled] {
      width: 155px;
    }
  }
}
.tabular {
  .tab-content {
    position: relative;
  }
  .main-inner {
    .inner-tab {
      .react-tabs__tab-list {
        background-color: transparent;
        text-align: center;
        li {
          margin-right: 0px;
          &:first-child {
            .tab-header {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
          }
          &:last-child {
            .tab-header {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              border-right: 1px solid rgba(0, 0, 0, 0.4);
            }
          }
          span {
            &.tab-header {
              display: block;
              padding: 5px 10px;
              background: #fff;
              border: 1px solid rgba(0, 0, 0, 0.4);
              border-right: none;
              min-width: 70px;
              text-align: center;
              border-radius: 0px;
              font-size: 13px;
            }
          }
          &.react-tabs__tab--selected {
            .tab-header {
              background: #418aca;
              color: #fff !important;
            }
          }
        }
      }
      .react-tabs__tab-panel {
        margin-top: -10px !important;
        padding-top: 0px !important;
        border: 1px solid #e2e2e2 !important;
        border-radius: 5px !important;
        .tab-content {
          padding: 20px 10px !important;
          text-align: left;
          height: 100%;
          .match-result {
            width: 350px;
            margin: 0 auto;
            display: inline;

            input[type="text"] {
              width: 50px !important;
              margin: 0 10px;
            }
            label {
              margin-right: 0;
              &:nth-child(2) {
                width: 50px;
              }
            }
          }
          .footer-form {
            .clearfix {
              background-color: #80b4e2;
              margin: 3px;
              max-width: 170px;
              width: auto;
              input[type="checkbox"] {
                width: 15px !important;
                display: inline-block;
                position: absolute;
              }
              .checkbox-label {
                margin-left: 20px;
              }
              .period-status {
                padding: 6px;
              }
            }
          }
          .container-fluid {
            padding: 0 2rem !important;
          }
          .form-wrapper {
            margin-top: 15px;
            h6 {
              position: absolute;
              margin-top: -12px;
              margin-left: 10px;
            }
          }
          .results-stats {
            .form-wrapper {
              min-height: 200px;
              &:first-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.event-score {
  width: 450px;
  margin: 20px auto;
  tr,
  td {
    border: 0;
  }
  thead {
    tr {
      td:nth-child(1) {
        width: 180px;
      }
      td:nth-child(3) {
        width: 100px;
      }
    }
  }
  tbody {
    tr {
      &.first {
        td:first-child {
          padding-left: 25px;
        }
      }
      &.second {
        td:first-child {
          padding-left: 50px;
        }
      }
      &.third {
        td:first-child {
          padding-left: 75px;
        }
      }
      // &:not(:first-child){
      //     td:first-child{
      //         padding-left:25px;
      //     }
      // }
      td {
        &:nth-child(2),
        &:nth-child(4) {
          text-align: center;
        }
        input[type="text"] {
          width: 50px;
        }
      }
    }
  }
}
.more-periods-container {
  height: 334px;
  .inner {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    .event-score {
      width: 300px !important;
      margin: 0 0 0 50px;
    }
  }
  .modal-controls {
    margin: 0;
    flex: 0 0 auto;
    bottom: 0;
    position: absolute;
    width: 100%;
  }
}

.feed-history {
  padding-left: 0 !important;
  .row {
    margin-bottom: 10px;
  }
  .lineup,
  .comments {
    margin-top: 20px;
  }
  .lineup {
    .tab-content {
      padding: 20px 5px !important;
    }
    .table-lineup {
      border: 1px solid #e2e2e2;
      height: 100%;
      td {
        border-top: 0;
        border-bottom: 0;
        &:first-child {
          width: 5%;
        }
      }
    }
  }
  .comments {
    height: 679px;
    &.form-wrapper {
      padding: 5px 10px;
    }
  }
  .has-lineup {
    .lineup {
      height: 579px;
    }
    .comments {
      height: 100px;
    }
  }
}

.import-feed-modal {
  .custom-table .rt-tbody {
    max-height: 300px;
    height: auto !important;
    border-bottom: 1px solid $line-gray;
    .rt-tr-group .rt-td {
      // overflow: unset !important;
      text-overflow: unset !important;
      white-space: unset !important;
      hyphens: auto;
      .path-icon-game-event {
        color: $white;
        font-size: 12;
        padding: 2;
        float: left;
      }
      span[class^="sports-ico-"] {
        float: left;
      }
      .event-description,
      .eventpath-description,
      .sports-description {
        margin-left: 20px;
      }
    }
    .rt-tr-group .rt-td {
      overflow: unset !important;
      text-overflow: unset !important;
      white-space: unset !important;
    }
  }
}

.feed-history-modal {
  .modal-title {
    text-align: center !important;
  }
  .market-types-container .button-group {
    flex: 0 0 auto;
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .main-content {
    height: 905px;
  }
  .content {
    height: 560px;
  }
  .inner {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    .block {
      vertical-align: top;
      display: block;
    }
  }
  .modal-controls {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .form-wrapper {
    input[type="text"],
    select,
    input[type="textarea"],
    input[type="number"],
    input[type="email"] {
      width: 100%;
    }
    &.no-border {
      border: 0 !important;
    }
  }
  h4 {
    text-align: left !important;
  }
  .search-filter {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .feed-title {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    height: 54px;
    background-color: #f9f9f9;
    padding: 0 10px;
    h3 {
      margin-top: 0px;
    }
    h5 {
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 0px;
    }

    .button-group {
      button {
        i {
          line-height: 0 !important;
        }
      }
    }
  }
  .feed-list {
    height: 255px;
    &.modal-content {
      display: flex;
      flex: 1 1 auto;
      height: 500px;
      .custom-table {
        width: 100%;
        .rt-td {
          a {
            color: #000 !important;
          }
        }
      }
      fieldset {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-top: 1px solid #333;
        legend {
          padding: 2px;
          font-weight: 500;
          background: #fff;
        }
        table {
          tr {
            &.open {
              background: #e0ffe0;
            }
            &.suspended {
              background: #f0e68c;
            }
            &.closed {
              background: #ffe6e6;
            }
            &.resulted {
              background: #cbbef3;
            }
            &.voided {
              background: #eee;
            }
            td {
              font-size: 7pt;
              border: 0;
              &:first-child {
                // width:250px;
              }
              .strong {
                font-weight: bold;
              }
              .text-open,
              .greater {
                color: #00ad00;
              }
              .text-suspended {
                color: #e4d232;
              }
              .text-closed,
              .text-voided,
              .lower {
                color: #e60000;
              }
              .text-resulted {
                color: #3a1c96;
              }
            }
          }
        }
      }
    }
    .rt-td {
      a {
        color: #000 !important;
      }
    }
    .feed-table {
      border: 1px solid #e2e2e2;
      height: 100%;
      // table {
      //     height: 256px;
      //     overflow: auto;
      //     display: inline-block;
      //     td {
      //         border-top:0;
      //         border-bottom:0;
      //         &:first-child {
      //             width:1%;
      //         }
      //     }
      //     thead {
      //         tr {
      //         }
      //     }
      //     tbody {
      //         // display:block;
      //         // height: 256px;
      //         // display: inline-block;
      //         // width: 100%;
      //         // position: absolute;
      //         // overflow: auto;
      //         tr:not(.no-hover) {
      //             vertical-align:top !important;
      //             &:hover {
      //                 cursor:pointer;
      //                 background:#F2f2f2;
      //             }
      //             &.active {
      //                 background:#F2f2f2;
      //             }
      //         }
      //         td {
      //             &.no-data {
      //                 height:250px;
      //                 vertical-align:middle;
      //                 font-size:24px;
      //                 font-weight:400;
      //             }
      //         }
      //     }
      // }
      tfoot {
        td {
          background-color: #599edb;
          border: 0;
          font-weight: 500;
          color: #666;
        }
        ul {
          li {
            display: inline-block;
            float: left;
            padding: 0 10px;
            &.total {
              color: #333;
            }
          }
          ul {
            li {
              display: inline-block;
              float: left;
              padding: 0 10px;
              width: 80px;
              &:nth-child(2) {
                width: 50px;
                color: #fff;
                &.count {
                  color: #000;
                }
              }
              &.queue {
                color: #333;
                text-align: center;
                &-incoming {
                  background-color: $yellow;
                }
                &-processed {
                  background-color: $color-success;
                }
                &-warning {
                  background-color: $color-warning;
                }
                &-error {
                  background-color: $color-error;
                }
              }
            }
          }
        }
      }
    }
  }
  .feed-error-condition,
  .feed-content {
    height: 350px;
  }
  .feed-content {
    .dl-button {
      margin-top: -4px;
      font-size: 12px;
      height: 20px;
      padding: 0 10px;
      &:hover {
        background: $primary;
      }
      &[disabled]:hover {
        background: #e2e2e2 !important;
      }
    }
    pre {
      margin: 0;
      height: 315px;
      overflow-y: scroll;
      padding: 5px;
    }
  }

  .custom-table {
    height: 235px;
    .rt-table {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      width: 100%;
      border-collapse: collapse;
      overflow: auto;
    }
    .rt-thead {
      .rt-th {
        label {
          margin-right: 0;
        }
      }
    }
    .rt-tbody {
      height: 210px;
      .rt-tr-group {
        &.selectable:hover {
          cursor: pointer;
        }
        &.active {
          background: #f2f2f2;
        }
        .rt-td {
          border-bottom: 0;
          padding: 5px;
          max-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .has-popover {
            position: absolute;
            vertical-align: top;
            margin-top: -10px;
            margin-left: 9px;
          }
        }
      }
      .rt-noData {
        width: 150px;
        text-align: center;
        margin: 100px auto;
        position: relative;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.06);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        z-index: 1;
        pointer-events: none;
        padding: 20px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.import-feeds-modal {
  .modal-title {
    text-align: center !important;
  }
  .form-wrapper {
    input[type="text"],
    select,
    input[type="textarea"],
    input[type="number"],
    input[type="email"] {
      width: 100%;
    }
  }
  h4 {
    text-align: left !important;
  }
  .feed-title {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    height: 54px;
    background-color: #f9f9f9;
    padding: 0 10px;
    h3 {
      margin-top: 0px;
    }
    h5 {
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 0px;
    }
  }
}

.exponential-div {
  position: relative;
  font-size: 10px;
  display: inline-block;
  top: -5px;
}

.bulk-update-container {
  .panels-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
  .panel-desc {
    padding: 5px;
    font-weight: bold;
  }
  .selection-list-panel {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    padding: 5px;
  }
  .list-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    background-color: #f1f1f1;
    // padding: 2px 0;
    li {
      padding: 2px 5px;
    }
    > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 1 auto;
    }
  }
  .delete-paths-panel {
    .selection-list-panel {
      flex: 0 0 100%;
    }
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
  }
  .delete-paths-wrapper,
  .delete-events-wrapper {
    flex: 0 0 40%;
  }
}
