/*Styles applicable to specific tool only that doesnt belong to any modules*/

/*START: CUSTOMER MANAGER*/

//note: added event-creator since both will use border-box, will be removed after ui redesign
#app-cust,
#app-event-creator,
#app-operator-manager {
  * {
    box-sizing: border-box;
    // font-weight: 400;
    /*&:focus {
			outline: none;
		}*/
  }

  .search-ontop {
    padding: 0 10px;
  }

  .header-actions {
    .btn-box {
      height: 32px;
    }
    .bulk {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        font-size: 15px;
        font-weight: 600;
        color: #599edb;
      }
      &:disabled {
        background: none !important;
      }
    }
  }

  .sidenav-child {
    font-weight: 100;
  }

  .form-group-control {
    select,
    input {
      width: 100%;
      height: 25px;
      line-height: 25px;
    }
  }
  .checkbox-label,
  .form-group-label {
    line-height: 25px;
    // padding: 2px 0;
    font-weight: 500;
  }

  .checkbox-label {
    font-size: 12px;
  }

  .form-wrapper {
    // overflow: visible;
    // > h4 {
    // 	background: $primary-dark;
    //     font-weight: bolder;
    //   		font-size: 1.25em;
    //   		// color: $black-secondary;
    //   		color: $white;
    // }
    .form-group {
      position: relative;
      color: $black-secondary;
      padding: 2px;
      &:hover {
        background-color: $primary-lightest;
      }
      select {
        margin: 0px;
      }
    }
    > div {
      &:not(.table-wrapper) {
        padding: 5px;
      }
    }
  }
  .info-message {
    display: block;
    margin: 0 0 10px;
    span,
    button {
      display: block;
      width: 100%;
      text-align: center;
      float: none;
    }
    span {
      padding: 0 0 3px 0;
    }
    button {
      box-shadow: none;
      background-color: $error-fg;
      color: $white;
    }
  }
  .page-main {
    bottom: 0;
    height: 100%;
    // width: 100%;
    border: none;
    // left: 300px;
    &.has-message {
      .tabular {
        height: calc(100% - 40px);
      }
    }
    .tabular {
      overflow: hidden;
    }
    .tabular .react-tabs__tab-panel {
      height: calc(100% - 31px);
      overflow-y: auto;
    }
    .main-wrapper,
    .tabular,
    .react-tabs,
    .tab-content,
    .tab-content > div {
      height: 100%;
    }
    .main-wrapper {
      padding: 5px;
      background: $gray-lightest;
    }
    .operator-message {
      // @include gradient-alert();
      background: $operator-message-color;
      line-height: $font-large;
      color: $white;
      font-size: $font-large;
      padding: 10px 0;
      border: 1px solid $primary-darker;
      border-width: 1px 0;
      // border-top: 10px solid $primary-darkest;
    }
  }
  // .form-wrapper {
  // 	background: none;
  // }
  .message-container {
    .input-with-icon {
      width: 250px;
      background: none;
      @include center();
      .search-onbody {
        width: 250px;
        border: 1px solid $line-gray;
        line-height: $input-h;
        padding: 0px 10px;
        background: $white;
        &:focus {
          outline: none;
        }
      }
      .fa,
      .icon {
        top: 3px;
        right: 10px;
      }
    }
  }
  .react-tabs .react-tabs__tab-panel--selected .tab-content {
    padding: 0;
  }
  .tabular .react-tabs__tab-list li.react-tabs__tab--selected span.tab-header {
    color: $primary;
  }
  //Start header
  .header-container {
    // .btn-box:not(.disabled) {
    // 	&.endcall {
    // 		border: 0;
    // 		background: $white;
    // 		i {
    // 			color: $red-orange;
    // 		}
    // 	}
    // 	&.lock {
    // 		border: 0;
    // 		background: $white;
    // 		i {
    // 			color: $yellow;
    // 		}
    // 	}
    // }
  }
  //End Header

  input[type="text"][disabled],
  input[type="alphabet"][disabled],
  select[disabled],
  textarea[disabled],
  input[type="number"][disabled] {
    background: #f1f3f3;
    color: #34383c !important;
  }

  .chat-container {
    height: 100%;
  }

  .chat-side {
    width: 320px;
    height: 100%;
    background: #fff;
    border-right: 1px solid #80b4e2;
    float: left;
  }

  .chat-side-filter {
    padding: 5px 5px 15px;
    border-bottom: 1px solid #aaa;
  }

  .chat-side-filter-input {
    padding: 0 10px;
  }

  .chat-side-content {
    max-height: calc(100% - 105px);
    overflow: auto;
    line-height: 1.4em;
    color: #5a5a5a;
  }

  .chat-side-content-list {
    padding: 5px 10px;
    border-bottom: 1px solid #aaa;
    cursor: pointer;

    &.active {
      background: $primary-light;
      color: #fff;
    }
  }

  .chat-main {
    width: calc(100% - 320px);
    height: 100%;
    background: #fff;
    float: left;
  }

  .chat-main-wrapper {
    height: 100%;
    overflow: auto;
    text-align: center;
  }

  .chat-main-list {
    padding: 15px;
    background: $white;
    border-radius: 5px;
    // margin-bottom: 5px;
    position: relative;
    // margin-bottom: 30px;
    margin: 0 20px 20px;
    width: 50%;
    &.CUSTOMER {
      background: $primary-lighter;
      text-align: right;
      float: right;
      &:after {
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -23px;
        top: 7px;
        bottom: -20px;
        border: 12px solid;
        border-color: $primary-lighter transparent transparent $primary-lighter;
      }
    }
    &.OPERATOR,
    &.SYSTEM {
      background: $primary-light;
      text-align: left;
      float: left;
      &:after {
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        right: auto;
        left: -23px;
        top: 7px;
        bottom: -20px;
        border: 12px solid;
        border-color: $primary-light $primary-light transparent transparent;
      }
    }
    // &.SYSTEM {
    // 	background: #dcdcdc;
    // }
    .info {
      padding: 0 0 5px;
    }
    .timestamp {
      color: $white50;
    }
    .origin {
      color: $white;
      font-weight: $fw-semibold;
    }
    .empty {
      font-size: $font-xlarge;
      font-weight: $fw-semibold;
    }
  }

  .header-utilities-container {
    height: auto;

    .btn-box {
      border: none;
      box-shadow: none;
      width: 20px;
      min-width: 20px;
      font-size: $font-xlarge;
      color: $primary;
      &:hover:not(.disabled) {
        background: none;
        i {
          color: $primary-darker;
        }
      }
    }

    .btn-submit {
      @include btn();
    }
  }
}

//recent searches
.recent-search-wrapper {
  z-index: 9999;
  background-color: $white;
  position: absolute;
  text-align: left;
  border: 1px solid $line-gray;
  max-height: 500px;
  overflow-y: auto;
  line-height: 30px;
  max-width: 250px;
  h4 {
    @include nopads();
    color: $primary;
    padding-left: 10px;
  }
  .recent-search-item {
    color: $black;
    padding: 3px 10px;
    cursor: pointer;
    padding-bottom: 5px;
    border-bottom: 1px solid $line-grid;
    @include transit();
    .sublbl {
      color: $gray;
      font-size: $font-small;
      line-height: 10px;
    }
    &:hover {
      color: $primary;
      background-color: $gray-lightest;
      border-bottom: 1px solid $primary;
    }
    &:last-child .sublbl {
      border-bottom: 0px solid;
    }
  }
}

//table
table,
.table-wrapper {
  .rt-tbody {
    .rt-tr-group {
      .rt-tr {
        cursor: pointer;
      }
    }
  }
}

//subtop
#app-cust .subtop-container {
  width: 100%;
  height: 30px;
  .app-name {
    display: none;
  }
  .subtop-content {
    .acct-details-wrap {
      float: left;
      padding: 5px 10px;
      height: 35px;
      display: flex;
      align-items: center;
      .phx-refresh {
        float: left;
        color: $primary;
        font-size: 16px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
      }
      .acct-name {
        float: left;
        min-width: 200px;
        margin: 0px 20px 0px 10px;
        color: $black;
        font-size: 12px;
        max-width: 300px;
      }
    }
    .form-content {
      float: left;
      border: 1px solid $primary-lighter;
      border-radius: 5px;
      margin: 2px;
      .form-group {
        float: left;
        border-right: 1px solid $primary-lighter;
        padding: 2px 10px;
        &:last-child {
          border-right: 0px;
        }
        label {
          text-transform: uppercase;
          font-size: 10px;
          color: $primary;
          margin-right: 0px;
        }
        span {
          display: block;
          font-size: 11px;
        }
      }
      .btn-expand {
        float: left;
        width: 20px;
        line-height: 30px;
        color: $primary;
        cursor: pointer;
        text-align: center;
        @include transit();
        &:hover {
          color: $white;
          background-color: $primary;
        }
      }
      .acct-status {
        color: $white;
        height: 30px;
        border-radius: 3px 0px 0px 3px;
        &[data-status="open"] {
          background-color: $green;
        }
        &[data-status="open not pool"],
        &[data-status="closed"] {
          background-color: $red;
        }
        &[data-status="suspended"] {
          background-color: $orange;
        }
        &[data-status="suspended except login"],
        &[data-status="suspended password failure"] {
          background-color: $orange;
        }
        label {
          color: $white75;
        }
        span {
          text-transform: uppercase;
          font-size: 10px;
        }
      }
    }
    .transaction-actions {
      float: right;
      .btn-box {
        @include wh(35px, 28px);
        margin-right: 5px;
        margin-top: 4px;
        border-radius: 5px;
        border: 1px solid $primary-light;
        background-color: transparent;
        color: $primary;
        @include transit();
        float: left;
        font-size: 20px;
        line-height: 32px;
        &:not(.disabled) {
          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
        &.disabled {
          border-color: $line-gray;
          background-color: $gray-lighter;
          color: $gray;
        }
      }
    }
  }
}

//main
#app-cust .page-main {
  position: relative;
  left: 0px;
  height: calc(100% - 36px);
  width: 100%;
}

//side
#app-cust .sidebar-container {
  background-color: $sidebar-bg;
  border: none;
  // overflow: hidden;
  // width: $side;
  .sidebar-head {
    background: none;
    .sidenav {
      border-top: 1px solid $gray-lighter;
      background: none;
      margin: 0px 20px 0px 20px;
      border: none;
      border-bottom: 1px solid $white10;
      margin-bottom: 15px;
      margin-top: 20px;
      p {
        color: $white50;
      }
    }
    .sidenav-child {
      color: $primary-darker;
      padding-left: 5px;
      &.user {
        i {
          display: block;
          text-align: center;
          font-size: 44px;
          color: $black;
        }
        .label {
          display: none;
        }
        .details {
          text-align: center;
          padding: 0;
        }
      }
      &.transactions {
        i {
          width: 40px;
        }
        .label {
          width: calc(100% - 40px);
          @include ellipsis();
        }
      }
      &.has-menu {
        padding: 10px 20px;
        + div {
          background: rgba(0, 0, 0, 0.1);
          margin: 0 -25px;
          padding: 5px 0;
          color: $sidebar-color;
        }
      }
      &:not(.has-menu):hover {
        cursor: auto;
        background-color: $black25;
        .label,
        .phxico {
          color: $white;
        }
      }
      i,
      .label {
        display: inline-block;
        font-weight: 400;
      }
      .label,
      .phxico {
        color: $sidebar-color;
        padding: 0 10px 0;
      }
      .label {
        padding: 0;
        cursor: default;
      }
      div {
        padding: 0 0 0 5px;
        &:first-child {
          color: $black50;
          padding: 5px 0;
        }
        &:last-child {
          font-size: $font-medium;
        }
      }
      .details {
        display: block;
        padding: 10px 0 0 35px;
        color: $white;
        &.closed {
          color: red;
        }
      }
    }
    .sidenav-lvl-0 {
      // padding: 2px 2px 0;
      // background: $white;
      padding: 5px;
      h4 {
        color: $white50;
        line-height: 10px;
        // color: rgba(255,255,255,0.5);
        text-transform: uppercase;
        font-size: 12px;
        font-size: 10px;
        padding: 0;
        margin: 0 0 10px 3px;
        letter-spacing: 0.4px;
        background: $sidebar-bg;
        .phxico {
          color: $white;
          @include transit();
          width: 15px;
          padding: 0;
          &.phx-refresh {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: $font-xlarge;
          }
        }
      }
    }
    .sidenav-lvl-1 {
      font-size: 15px;
      padding: 0;
      li {
        padding: 5px 20px;
        /* padding: 9px 20px 9px 54px; */
        margin-left: -25px;
        margin-right: -24px;
      }
    }
    .accordion-heading {
      text-align: left;
      padding: 5px 0 5px 55px;
      .phxico {
        padding: 0;
        position: absolute;
        right: 20px;
        margin-top: -39px;
        font-size: 20px;
        width: 35px;
        text-align: right;
        color: $sidebar-color;
      }
    }
    .accordion-body {
      text-align: left;
      padding: 5px 0 5px 60px;
    }
  }
}

//modals
.ReactModal__Content {
  .modal-body {
    // border-radius: 5px;
    h4 {
      background: $primary;
      font-weight: bolder;
      font-size: 12px;
      color: $white;
      text-align: left;
      font-weight: 100;
      text-transform: uppercase;
    }
  }
  &[aria-label="Confirm Account"],
  &[aria-label="Advance Search"] {
    .modal-container {
      //top: calc(50% - 150px);
    }
    .modal-body {
      form {
        padding: 5px;
      }
      .suspended,
      .closed {
        h4 {
          background: $red;
        }
        .form-group.field-look {
          border: 1px solid $red;
          .form-group-label {
            color: $white;
            background-color: $red;
          }
          &[data-field="status"] {
            position: relative;
            &:after {
              content: "\F026";
              @include fonticon();
              position: absolute;
              top: 5px;
              right: 5px;
              font-size: 15px;
              color: $red;
            }
            .form-group-label {
              color: $white;
              background-color: $red;
            }
          }
        }
        .pin-check {
          background: $red;
          color: $white;
        }
      }
      .suspended {
        .form-group[data-field="status"]:after {
          color: $orange;
        }
      }
    }
  }
  //account transfer
  &[aria-label="Account Transfer"] {
    .accttransfer.-amttransfer {
      width: 250px;
      margin-right: 5px;
    }
  }
  //account select
  &[aria-label="Select Account"] {
    table {
      width: 99%;
    }
  }
  &[aria-label="Transaction Details"] {
    .form-group {
      // height: 40px;
    }
    .form-group-label {
      // line-height: 15px;
      // font-weight: 500;
      // padding: 6px 0;
    }
    .form-wrapper {
      margin: 5px;
      padding: 5px;
      overflow: hidden;
      &.half.fleft {
        margin-right: 3px;
        width: calc(50% - 10px);
      }
      &.half.fright {
        margin-left: 3px;
        width: calc(50% - 10px);
      }
    }
    form.handleTransactionDetailsUpdate {
      padding: 0px;
    }
    .form-wrapper.description {
      width: 96.4%;
    }
  }
  &[aria-label="DragonPay"] {
    .modal-container {
      height: 440px;
    }
  }
  &[aria-label="Manage DragonPay"] {
    .modal-container {
      height: 580px;
    }
  }
  &[aria-label="Manual Transaction"] {
    .form-wrapper {
      position: relative;
    }
    .desktop-half .field-err {
      top: 5px;
      right: 5px;
    }
    .form-group.field-look {
      border: 0px;
      height: auto;
      .form-group-control {
        height: auto;
        padding: 0px 5px;
        input {
          border: 1px solid $line-gray;
          border-radius: 3px;
          padding: 0px 5px;
          height: 27px;
        }
      }
      .field-err {
        position: relative;
        text-align: right;
        right: 5px;
        top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  &[aria-label="BulkCrediting"] {
    .modal-container {
      height: 160px !important;
      .modal-body {
        .inner-modal-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .row {
            padding: 0px 10px 0px 10px;
            flex-wrap: nowrap;
            .col-sm-6 {
              .file-upload {
                border: 1px solid #aaa;
                width: 50%;
                padding: 5px;
                text-align: center;
                border-radius: 5px;
                background: linear-gradient(180deg, #eee, #ccc);
                box-shadow: 0 0 1px 1px #eee;
                cursor: pointer;
                color: #000;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              > select {
                cursor: pointer;
              }
              &.disabled {
                cursor: not-allowed;
              }
            }
          }
        }
        // height: 100%;
        // display: flex;
        // flex-direction: column;
        // .inner-container{
        // 	height: 100%;
        // 	display: flex;
        // 	flex-direction: column;
        // 	justify-content: space-between;
        // 	.bulk-error-container{
        // 		overflow-x: auto;
        // 	}
        // 	.bulk-btn{
        // 		border-radius: 5px;
        // 	}
        // }
      }
    }
  }
  &[aria-label="BulkCreditError"] {
    .modal-container {
      height: 200px !important;
      .bulkerror-contents {
        height: 100%;
        display: flex;
        flex-direction: column;
        .inner-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .bulk-error-container {
            overflow-x: auto;
          }
          .bulk-btn {
            border-radius: 5px;
          }
        }
      }
    }
  }
  &[aria-label="BulkCreditSuccess"] {
    .modal-container {
      height: auto !important;
      width: 800px !important;
      .bulk-credit-success {
        height: 100%;
        display: flex;
        flex-direction: column;
        .form-group.bulk-credit-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
        }
        .bulk-btn {
          border-radius: 5px;
        }
      }
    }
  }
  &[aria-label="Outcome Wager Limits Event Paths"] {
    .modal-container {
      height: 510px;
    }
    .modal-body {
      overflow: hidden;
    }
    .form-group {
      overflow: auto;
      height: 420px;
      border: 1px solid $line-gray;
      margin: 10px;
      padding: 0px;
      > div {
        border-bottom: 1px solid $line-gray;
        cursor: pointer;
        @include transit();
        .event-path-tree-item {
          i {
            font-size: 20px;
            @include transit();
            &.ico {
              padding: 0 4px;
            }
          }
        }
        &:last-child {
          border-bottom: 0px solid;
        }
        &:hover {
          background-color: $primary-lightest;
          .event-path-tree-item {
            i,
            a {
              color: $primary;
            }

            &.selected {
              > a,
              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .sports-tree-controls {
      margin: 5px;
      flex-direction: column;
      .controls-group {
        display: flex;
        .dropdown {
          position: relative;
          display: inline-block;
          width: 100%;
          border-radius: 5px;
          background-color: #fff;
          color: #323232;
          padding: 0px 15px;
          height: 26px;
          line-height: 24px;
          border: 1px solid #e2e2e2;
          max-width: 100%;
          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: 5px;
            top: 60%;
            margin-top: -6px;
            border-width: 6.4px 3.5px 0px 3.5px;
            border-style: solid;
            border-color: black transparent;
          }
          &.active {
            box-shadow: 0px 0px 1px 1px #418aca;
          }
          &.disabled {
            pointer-events: none;
            background: #f1f3f3;
            color: #333;
            border: none;
          }
          .dropbtn {
            background-color: transparent;
            color: black;
            border: none;
            display: inline-block;
            width: 100%;
            &.disabled {
              pointer-events: none;
              background: #f1f3f3;
              color: #333;
            }
            &:hover,
            &:focus {
              background-color: transparent;
            }
          }
          #sports-search {
            height: 100%;
            width: 100%;
            border: none;
            &:focus {
              outline: none;
            }
          }
          .dropdown-content {
            display: none;
            flex-direction: column;
            position: absolute;
            background-color: #fff;
            overflow: auto;
            z-index: 1;
            width: 100%;
            left: 0;
            height: 300px;
            color: black;
            top: 25px;
            &.active {
              border: 1px solid #418aca;
            }
            .dropdown-list {
              padding: 5px;
              > label {
                display: flex;
                > span {
                  margin-right: 5px;
                  margin-top: 1px;
                  > img {
                    height: 12px;
                  }
                }
              }
              .path-icon {
                display: inline-block;
                padding: none;
                margin-right: 0;
              }
              &:hover {
                background-color: #418aca !important;
                color: #fff;
              }
              &.searched-item {
                background-color: #418aca !important;
                color: #fff;
              }
            }
          }
        }
        > div {
          float: left;
          &:first-child {
            width: 65%;
          }
          &:nth-child(2) {
            width: 35%;
            .path-search-container {
              input {
                width: 100%;
              }
            }
          }
          // select[name='sports'] {
          // 	margin: 0px;
          // 	width: 95%;
          // }
        }
      }
      .controls-buttons {
        width: 100%;
        display: flex;
        height: 31px;
        margin-top: 5px;
        > button {
          float: left;
        }
      }
    }
    .hide {
      display: none;
    }
    .selected {
      background-color: $primary;
      color: $white;
    }
    .event-path-tree-item {
      font-size: 15px;
      padding: 3px 5px;
      &.selected {
        a,
        i {
          color: $white;
        }
      }
      a,
      i {
        color: $gray-darker;
        // font-weight: bold;
      }
      i {
        width: 13px;
      }
    }
    .event-paths {
      // padding: 5px 24px;
      // font-size: 14px;
      li {
        padding: 0 0 5px;
        > div:not(.event-paths-child) {
          padding: 3px;
        }
      }
      span {
        padding: 0 0 0 5px;
      }
      .no-path {
        padding: 0 0 0 12px;
      }
      i {
        padding-left: 20px;
      }
    }
    .event-paths-child {
      // padding: 5px 0 0 25px;
      i {
        padding-left: 50px;
      }
      > div {
        padding: 3px;
      }
    }
    .button-wrapper {
      padding: 0px 0px 14px 0px;
      button {
        margin: 0 auto;
        display: block;
      }
    }
  }
  &[aria-label="Outcome Wager Limits"] {
    .form-wrapper {
      padding: 5px;
      h3 {
        margin-bottom: 5px;
        padding-left: 10px;
        color: $primary;
      }
    }
    .half {
      width: calc(50% - 10px);
      margin: 0px 5px;
      .table-sub-head {
        text-transform: uppercase;
        font-size: 12px;
        padding-bottom: 10px;
        display: block;
      }
    }
    .base-table,
    .secondary-table {
      .rt-table {
        overflow: hidden;
      }
    }
    .base-table {
      border-right: 0;
      .rt-table {
        border: 1px solid $line-gray;
      }
    }
    .secondary-table {
      .header-row,
      .rt-td {
        max-width: 19%;
      }
    }
  }

  &[aria-label="Profile Flags"] {
    .selected {
      background-color: $primary;
      color: $white;
    }
    .form-group {
      > div {
        height: 20px;
        padding: 5px;
      }
    }
    .flag-description {
      line-height: 23px;
      vertical-align: top;
      padding: 0 0 0 10px;
    }
    .elite {
      background-image: url(../../assets/icons/elite.png);
      width: 25px;
      display: inline-block;
      background-position: top left;
      background-size: contain;
      height: 20px;
      background-repeat: no-repeat;
    }
    .warm {
      background-image: url(../../assets/icons/warm.png);
      width: 25px;
      display: inline-block;
      background-position: top left;
      background-size: contain;
      height: 20px;
      background-repeat: no-repeat;
    }
    .money {
      background-image: url(../../assets/icons/money.png);
      width: 25px;
      display: inline-block;
      background-position: top left;
      background-size: contain;
      height: 20px;
      background-repeat: no-repeat;
    }
    .follow {
      background-image: url(../../assets/icons/follow.png);
      width: 25px;
      display: inline-block;
      background-position: top left;
      background-size: contain;
      height: 20px;
      background-repeat: no-repeat;
    }
  }

  &[aria-label="New Password"] {
    .form-group-control {
      position: relative;
    }
    .field-err {
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }
  &[aria-label="Create Account Error"] {
    .modal-container {
      height: 150px;
      .modal-body {
        padding: 10px;
      }
    }
    ul > li {
      padding-bottom: 3px;
      i {
        color: $red;
      }
    }
  }
}

//create account
.form-createacct {
  padding: 5px;
  div:not(.form-combine) > .form-group > .form-group-control {
    > input {
      width: 80%;
    }
    > select {
      width: calc(80% + 12px);
    }
  }
  .container-fluid > .row > .row {
    padding: 0px;
  }
  .button-group.top-btns {
    margin-bottom: 5px;
  }
  .button-group.bottom-btns {
    margin-bottom: 20px;
    float: left;
    width: 100%;
  }
  h3 {
    float: left;
    margin: 0px;
    line-height: 30px;
    margin-right: 20px;
    font-weight: $fw-normal;
    text-transform: uppercase;
    font-size: $font-medium;
    color: $primary-darker;
  }
}

.form-createacct,
.tab-content,
#security-tab {
  .field-padding-left {
    padding-left: 15px;
    input {
      padding-left: 15px;
    }
  }
}

//security
#security-tab {
  position: relative;
  .info-message {
    margin: 50px;
  }
  .date-picker {
    position: inherit !important;
    > div {
      top: 0;
      right: 0;
      // margin-top: 18%;
    }
  }
  .form-group-control {
    select,
    input {
      width: 100%;
    }
    .field-err {
      position: relative;
      float: right;
      margin-bottom: 8px;
    }
  }
  .form-wrapper {
    overflow: visible;
  }
  .row {
    align-items: flex-start;
  }
  .datepicker-container {
    .datepicker-inner {
      position: relative;
      z-index: 99;
      float: left;
      margin-left: 255px;
    }
  }

  .bank-detail-section {
    background-color: #f7f9fc;
    .left-section {
      width: 93%;
    }
    .right-section {
      width: 5.5%;
    }
  }

  .bank-detail-add {
    margin-top: -4px;
    height: 17px;
    min-width: 19px;
    &:hover {
      background: #6fba65 !important;
    }
  }

  .bank-detail-remove {
    margin-top: 10%;
    height: 17px;
    min-width: 19px;
    &:hover {
      background: #e60b0c !important;
    }
  }
}

.accordion-promo {
  border: 1px solid $gray-lightest;
  border-radius: 3px;
  font-weight: 400;
  margin-bottom: 5px;

  &.PROMO_MONEY {
    background: $purple;
    color: $white;
  }
  &.REAL_MONEY {
    background: #ffd052;
    color: $black;
  }
  &.PROMO_MONEY_SNR {
    background: $orange;
    color: $white;
  }

  .accordion-heading {
    padding: 5px;
    cursor: pointer;
  }

  .accordion-body {
    background: rgba(255, 255, 255, 0.25);
    color: #000;
    padding: 5px 10px;
  }
}

.promo-content {
  align-items: inherit;
}

//End Security

//Transactions
#transactions-tab {
  //header
  .filter-results {
    padding: 5px;
    float: right;
    @media (max-width: 1024px) {
      float: none;
      padding: 0px 3px 3px 3px;
    }
  }
  .filter-field {
    width: auto;
  }

  //transactions table
  .table-transactions-main {
    .rt-table {
      .rt-tbody {
        .rt-tr {
          .cumulative {
            color: $green-dark;
          }
          &.failed {
            // background: $toast-error-bg;
            .txt-wrap:not(.cumulative) {
              color: $red-darker;
            }
            // &:hover {
            // 	background: $toast-error-bg!important;
            // }
          }
          .rt-td {
            overflow: visible;
            .void,
            .cancel {
              color: $red;
              + div {
                color: $red;
              }
            }
            .failed {
              background: $red;
            }
            .icon-cell {
              i {
                font-size: 18px;
                color: $primary;
                text-align: center;
                line-height: 40px;
                width: 40px;
              }
            }
          }
        }
      }
    }
  }
}

//End Transactions

#web-tab {
  height: 100%;
  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}

#dashboard-tab {
  form {
    height: 100%;
    .field-withCurr {
      // display: flex;
      // width: 100%;
      .input-curr {
        width: 100%;
        display: flex;
      }
      .lbl-curr {
        float: left;
        font-size: 11px;
        margin-left: 3px;
        width: 30px;
        line-height: 25px;
      }
      .field-err {
      }
    }
    .datepicker-container {
      .datepicker-inner {
        position: relative;
        z-index: 99;
        float: left;
        margin-left: 0px;
      }
    }
  }
  .main-wrapper {
    padding: 5px;
    overflow-y: auto;
  }
  .comments-header {
    width: calc(85% / 3);
    float: left;
    padding: 0 5px;
    &:last-child {
      width: 15%;
      text-align: center;
    }
  }
  .block-input {
    width: 100%;
    height: 100px;
  }
  .add-comment {
    padding: 5px 0;
    float: left;
    font-weight: 500;
    cursor: pointer;
    color: $primary;
    &.disabled {
      cursor: no-drop;
      color: $gray-dark;
      i {
        color: $gray-dark;
      }
    }
  }
  .empty-row {
    text-align: center;
    padding: 5px;
  }
  .comments-row {
    border-bottom: 1px solid #eee;
    &.highlight {
      background: $primary;
      color: $white;
      i {
        color: $white;
      }
    }
    .value {
      width: calc(85% / 3);
      float: left;
      padding: 5px;
    }
    .actions {
      width: 15%;
      text-align: center;
      span {
        color: $primary;
        &.disabled {
          cursor: no-drop;
          color: $gray-dark;
          i {
            color: $gray-dark;
          }
        }
      }
      i,
      span {
        background: none;
      }
      i {
        padding: 0 2px;
      }
    }
  }
  .container-fluid {
    > .row {
      margin-bottom: 5px;
      align-items: unset;
      div[type="row"] {
        padding: 0px 4px;
        .form-inner {
          height: 100%;
          &.multi-form-col {
            height: auto;
            margin-bottom: 5px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
          .form-wrapper {
            height: 100%;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

#settings-tab {
  .rt-noData {
    display: none;
  }
  // .form-inner.wager-limit {
  // 	span {
  // 		width: 33%;
  // 		display: inline-block;
  // 		text-align: center;
  // 	}
  // }
  .ReactTable .rt-tbody .rt-tr .rt-td {
    .action-icon {
      color: $primary;
      @include transit();
      &.disabled {
        color: $gray;
      }
      &:not(.disabled):hover {
        cursor: pointer;
        color: $primary-darker;
      }
    }
    span {
      text-align: left;
    }
  }
  span {
    width: 33%;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    i {
      color: $black;
    }
    &.btn {
      width: 31%;
      margin: 0 1%;
      padding: 5px 0;
      border: 1px solid $gray-lighter;
      &.disabled {
        background: $gray-lighter;
        color: $gray;
        i {
          color: $gray;
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
      i {
        color: $gray;
      }
    }
  }
  .flag-description {
    display: inline-block;
    vertical-align: top;
    line-height: 25px;
    padding: 0 0 0 9px;
  }
  .elite {
    background-image: url(../../assets/icons/elite.png);
    width: 25px;
    display: inline-block;
    background-position: top left;
    background-size: contain;
    height: 20px;
    background-repeat: no-repeat;
  }
  .warm {
    background-image: url(../../assets/icons/warm.png);
    width: 25px;
    display: inline-block;
    background-position: top left;
    background-size: contain;
    height: 20px;
    background-repeat: no-repeat;
  }
  .money {
    background-image: url(../../assets/icons/money.png);
    width: 25px;
    display: inline-block;
    background-position: top left;
    background-size: contain;
    height: 20px;
    background-repeat: no-repeat;
  }
  .follow {
    background-image: url(../../assets/icons/follow.png);
    width: 25px;
    display: inline-block;
    background-position: top left;
    background-size: contain;
    height: 20px;
    background-repeat: no-repeat;
  }
  .wager-limit {
    .form-group {
      .btn {
        margin-right: 5px;
      }
      &.wagerlimit-actions {
        float: left;
      }
      &.wagerlimit-setdefault {
        float: left;
        border-left: 1px solid $line-gray;
        padding-left: 10px;
        label {
          margin-right: 5px;
          width: 120px;
        }
        .form-group-control {
          width: 350px;
          select {
            width: 300px;
          }
          .btn {
            margin-left: 10px;
          }
        }
      }
      &.wagerlimit-table {
        float: left;
        width: 100%;
      }
    }
  }
  .profile-flags-wrapper {
    .form-group {
      .btn {
        margin-right: 5px;
      }
    }
    .ReactTable {
      .rt-tr-group {
        .rt-td {
          .close > i {
            color: $red;
          }
          .up > i,
          .down > i {
            color: $primary;
          }
          .close,
          .up,
          .down {
            margin: 0px 1px;
            line-height: 25px;
            width: auto;
            &.disabled > i {
              color: $gray;
            }
          }
          &.td-actions {
          }
        }
        .profileflags-status {
          &.follow,
          &.warm,
          &.money,
          &.elite {
            background: none;
            @include fonticon();
            font-size: 18px;
            text-align: center;
          }
          &.follow {
            color: $green;
            @include fonticon();
            &:before {
              content: "\e900";
            }
          }
          &.warm {
            color: $orange;
            @include fonticon();
            &:before {
              content: "\f23e";
            }
          }
          &.money {
            color: $red;
            &:before {
              content: "\f117";
            }
          }
          &.elite {
            color: $yellow;
            &:before {
              content: "\e901";
            }
          }
        }
        .profileflags-color {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 10px;
        }
      }
    }
  }
  .account-settings {
    align-self: flex-start;
  }
}

.ReactModal__Content[aria-label="Profile Flags"] {
  .form-wrapper {
    min-height: 130px;
  }
  .profileflag-item {
    height: 30px !important;
    cursor: pointer;
    @include transit();
    &:hover {
      background-color: $primary-lightest;
      color: $primary;
    }
    .profileflags-status {
      &.follow,
      &.warm,
      &.money,
      &.elite {
        background: none;
        @include fonticon();
        font-size: 18px;
        margin-right: 5px;
        text-align: center;
      }
      &.follow {
        color: $green;
        @include fonticon();
        &:before {
          content: "\e900";
        }
      }
      &.warm {
        color: $orange;
        @include fonticon();
        &:before {
          content: "\f23e";
        }
      }
      &.money {
        color: $red;
        &:before {
          content: "\f117";
        }
      }
      &.elite {
        color: $yellow;
        &:before {
          content: "\e901";
        }
      }
    }
  }
  .profileflags-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
  }
}

.performance-tab {
  .header-utilities-container {
    .filters-container {
      .filter-field {
        width: auto;
      }
    }
  }
  p {
    margin: 0;
  }
  .form-wrapper {
    overflow: hidden;
  }
  .phx-refresh {
    cursor: pointer;
    &:hover {
      color: #418aca;
    }
  }
  .datepicker-container {
    .datepicker-inner {
      position: relative;
      z-index: 99;
      float: left;
    }
  }
}

.page-main .main-wrapper .form-wrapper .form-dob select[name="year"] {
  margin-left: 29.5% !important;
}

#app-cust {
  .account-registration-form {
    display: inline-block;
    .left-form {
      float: left;
    }
    .right-form {
      float: right;
    }
  }
}

.colour-of-money {
  margin: 5px 0px 5px 10px;
}

.promotion-tab {
  .expire-button {
    float: right;
    margin-top: -3px;
    color: red;
  }
}

.subtop-dropdown {
  position: relative;
  display: inline-block;
}

.subtop-dropdown-content {
  font-size: 11px;
  padding: 10px;
  margin-top: 6px;
  margin-left: -12px;
  border: 1px solid #80b4e2;
  border-radius: 5px;

  position: absolute;
  background-color: $white;
  width: 270px;
  z-index: 1;
  .balance-box {
    margin-top: 5px;
    .label {
      color: #418aca;
      text-align: right;
    }
    .amount {
      text-align: right;
    }
  }
}

.mt-promotion-reward {
  padding: 5px 0px 10px 0px;
  .row {
    margin-left: 2px;
    margin-top: 5px;
  }
  .col-sm-8 {
    .minOddInput {
      padding-left: 0 !important;
      padding-right: 0.5rem;
    }
  }
}

.cm-manual-transaction-modal {
  .modal-container {
    height: 515px;
  }
}

.expire-promo-modal-text {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.modal-container {
  .advance-search-modal,
  .confirm-account-modal,
  .confirm-operator-modal,
  .end-call-modal,
  .account-transfer-modal,
  .manual-transaction-modal,
  .dragonpay-account-modal {
    height: 100%;
    h4 {
      position: fixed;
      width: 100%;
      z-index: 1;
    }
    form {
      padding-top: 32px !important;
    }
    .button-group {
      position: absolute;
      bottom: -13px;
      width: 100%;
      left: 0;
      background: #ffffff;
    }
  }
}

/*END: CUSTOMER MANAGER*/
