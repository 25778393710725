$side-bg: #f0f0f0;
#app-payment-handler {
  .payment-handler-header {
    .menu-divider {
      margin-left: 10px;
      margin-right: 10px !important;
      width: 1px !important;
      border: 0 !important;
      min-width: 1px;
    }
    .currency-select {
      height: 32px;
      width: 130px;
      position: absolute;
      top: 6px;
      display: block;
      .selectBox {
        background: #ffffff;
        height: 32px;
        border: 1px solid #dad5d5;
        //padding: 4.5px;
        text-align: left;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
        // span{
        //   font-weight: bold;
        // }
        .symbol {
          color: #f98b04;
          width: 35%;
          display: inline-block;
        }
        .drop-down-symbol {
          float: right;
        }
      }

      .list-container {
        margin-top: -3px;
        display: block;
        border: 1px #dadada solid;
        position: relative;
        //top: 36px;
        //left: -119px;
        z-index: 9;
        background: #ffffff;
        width: 130px;
        box-shadow: 6px 8px 6px -5px black;
        overflow-y: auto;
        max-height: 300px;

        .currency {
          text-align: left;
          padding: 5px;
          line-height: initial;
          span {
            color: #000000;
          }
          .symbol {
            font-weight: bold;
            color: #f98b04;
            width: 40%;
            display: inline-block;
          }
        }
      }

      .list-container .currency:hover {
        background-color: #1e90ff;
      }
    }
  }
  .top-filter {
    display: inline-block;
    text-align: center;
    padding: 6px;
    height: auto !important;
    width: 100%;

    .button-container {
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;
      .status-buttons {
        text-align: center;
        border-radius: 3px;
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        min-width: 100px;
        background: #fff;
        &.pending-button,
        &.rejected-button,
        &.approved-button {
          &.selected-status,
          &:hover {
            padding: 5px 3px;
            border: none;
            font-weight: bold;
          }
        }
        &.pending-button {
          border: 1px solid #e6b629;
          color: #7c5e07;
          &.selected-status,
          &:hover {
            color: #7c5e07;
            background-color: #f2e591;
          }
          &.selected-status:hover {
            background-color: #e7cf32;
          }
        }
        &.rejected-button {
          border: 1px solid #ec6251;
          color: #841405;
          &.selected-status,
          &:hover {
            color: #841405;
            background-color: #f6bfb8;
          }
          &.selected-status:hover {
            background-color: #f7786f;
          }
        }
        &.approved-button {
          border: 1px solid #59b82d;
          color: #1e5903;
          &.selected-status,
          &:hover {
            color: #1e5903;
            background-color: #b6e5a0;
          }
          &.selected-status:hover {
            background-color: #74ce4b;
          }
        }
        &.incoming {
          order: 1;
        }
        &.pending {
          order: 2;
        }
        &.under-final-review {
          order: 3;
        }
        &.pending_3rd_party {
          order: 4;
        }
        &.rejected {
          order: 5;
        }
        &.pre-rejected {
          order: 6;
        }
        &.rejected-by-third-party {
          order: 7;
        }
        &.approved {
          order: 8;
        }
        &.pre-approved {
          order: 9;
        }
        &.approved-for-third-party {
          order: 10;
        }
        &.approved-by-third-party {
          order: 11;
        }
      }
    }

    .multiselect {
      width: 200px;
      //margin-right: 10px;
    }

    .request-date {
      width: 200px;
    }

    .selectBox {
      position: relative;
      cursor: pointer;
    }

    .selectBox select {
      width: 100%;
      font-weight: bold;
    }

    .overSelect {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    #checkboxes {
      margin-top: -3px;
      display: block;
      border: 1px #dadada solid;
      position: absolute;
      z-index: 9;
      background: #ffffff;
      width: 200px;
      box-shadow: 6px 8px 6px -5px black;
      overflow-y: auto;
      max-height: 300px;
    }

    #checkboxes label {
      display: block;
      margin-right: 0px;
      height: 20px;
      border-bottom: 1px solid #d1caca;
      padding: 2px;
    }

    .currency-symbol {
      font-weight: bold;
      color: #f4a524;
    }

    .currency-list {
      .currency {
        label {
          // height: 20px !important;
          // top: 10px !important;
          // position: relative !important;
        }
      }
    }

    #checkboxes .all-approved-item {
      margin-left: 20px;
    }

    #checkboxes input {
      top: 2px;
      position: relative;
    }

    #checkboxes label:hover {
      background-color: #1e90ff;
    }
  }
  .page-main {
    left: 0 !important;
    position: relative !important;
  }
  .notes-cell {
    text-align: center;
  }
  .DayPicker {
    margin-top: 5px;
  }
  .page-container {
    position: absolute;
    top: 45px;
    width: 100%;
    bottom: 0;
    left: 0;

    .payment-handler-main {
      left: 250px;
    }

    .sidebar {
      width: 250px;
      height: 100%;
      display: flex;
      border-right: solid 1px white;
      flex-direction: column;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;

      .filter {
        padding-left: 5px;
        padding-top: 10px;

        .filter-form {
          margin-bottom: 10px;
          input[type="text"] {
            margin-top: 2px;
            width: 100%;
          }
        }

        .filter-status {
          li {
            margin: 10px 0;
          }
          input {
            margin-right: 5px;
          }
        }
      }
    }

    .custom-calendar {
      margin-bottom: 5px;
      .calendar-status {
        margin-bottom: 5px;
      }
    }
  }

  .tab-content {
    padding: 0;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    height: 32vh;
    width: 100%;
    .ReactVirtualized__Table {
      padding: 0;
    }

    .Grid {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      .grid-sorter {
        // display: inline-flex;
        color: #000;
        user-select: none;
        &.active-sorter {
          font-weight: bold;
        }
        i {
          vertical-align: middle;
        }
      }
      .grid-loading,
      .no-rows {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        color: #333;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .ReactVirtualized__Grid {
      outline: none;
      &.grid-header {
        overflow-x: hidden !important;
        .cell {
          background-color: #e2e2e2;
          border-right: 1px solid #ccc;
        }
      }
      &.grid-body {
        width: 100%;
        border: 1px solid #e0e0e0;
        .evenRow,
        .oddRow {
          border-bottom: 1px solid #e0e0e0;
        }
        .oddRow {
          background-color: #fafafa;
        }
      }
      .cell {
        font-size: 13px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.4em 0.4em;
        border-right: 1px solid #e0e0e0;
        // border-bottom: 1px solid #e0e0e0;
        i.status {
          padding: 2px 0;
          display: inline-block;
        }
        select {
          margin: 0;
          border: none;
          background: transparent;
          width: 85%;
          &.approval-status {
            position: absolute;
            left: 16px;
            top: 2px;
            outline: none;
          }
          &:not(.approval-status) {
            height: 19px;
          }
        }
        &.process {
          display: flex;
          align-items: center;
        }
        .ellipsis {
          position: initial !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          label {
            margin-left: 5%;
          }
        }
        .string {
          padding: 0.4em 0 0 0;
        }
        .number {
          padding: 0.4em 0.7em;
        }
      }
    }
  }
  .tabgrp-container {
    position: absolute;
    right: 15px;
    // margin-top: 5px;
    input {
      margin-top: 3px;
    }
  }
  .path-search-container {
    display: inline-flex;
    position: absolute;
    right: 10px;
    margin-top: 4px;
    .icon {
      cursor: pointer;
    }
    .search-by {
      margin: 0px 5px 0px 0px !important;
    }
  }

  .note-container {
    &:hover > span {
      display: none;
    }
    &:hover > i {
      cursor: pointer;
      display: inline-block;
      line-height: 30px;
      margin-top: -10px;
    }
    .hidden {
      display: none;
    }
  }
}
.modal-transaction-detail {
  .row {
    margin-top: 10px;
    margin-left: 0.5%;
  }
  .transaction-detail-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 3px 10px 10px 10px;
    background-color: white;
    text-align: center;
    button {
      margin: 0px 10px 0px 10px;
    }
  }
  width: 35%;
  margin-top: 10px;
  height: 150px;
}

.preference-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  .preference-toggle {
    padding: 10px;
    a {
      margin-left: 15px;
    }
  }
  .preference-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 80px;
    .preference {
      margin-bottom: 5px;
      flex: 0 170px;
    }
  }
}

.notes-body {
  textarea {
    resize: none;
    width: 100%;
  }
  .note-detail {
    display: flex;
    > p {
      flex: 1;
    }
    .left {
      margin-right: auto;
    }
  }
  .notes-list {
    height: 240px;
    overflow: auto;
    ul {
      list-style: none;
      .note-item {
        padding: 10px;
      }
      .note-details {
        display: flex;
        border-bottom: 1px solid $gray;
      }
      .note-author {
        margin: auto 0 auto auto;
      }
    }
  }
}

.ReactModal__Content {
  &[aria-label="Preferences"] {
    .modal-container {
      height: 340px;
      width: 450px;
    }
  }
  &[aria-label="Notes"] {
    .modal-container {
      height: 500px;
      width: 450px;
    }
  }
}

.approveAllTransactions {
  .modal-body {
    overflow: hidden !important;
  }
}

.deposit-container,
.withdrawal-container {
  .recently-updated {
    select {
      color: red;
    }
  }
  .rt-noData {
    margin-top: 13px;
  }
  .react-table {
    min-height: calc(100vh - 165px);
    input {
      color: inherit;
    }
    .ellipsis {
      position: relative;
      select {
        color: inherit;
        + .arrow-down {
          display: block;
          position: absolute;
          right: 4%;
          top: 30%;
        }
      }
    }
  }
  .status-bar {
    display: block;
    position: relative;
  }
}

.payment-prefernce-modal {
  height: 425px !important;
}
