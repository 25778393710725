// Colors
$promoRunning: #6fba65;
$retired: #808080;
$lockIcon: #ffb900;

$defaultIconColor: #418aca;
$activeIconColor: #fff;

$subNavBackground: #f3f3f3;
$navBorder: #ccc;
$navActiveItemBackground: #3d89cc;

$white: #fff;
$chevronDefault: #333;

.sidebar {
  &__options {
    height: 64px;
    padding: 5px;

    .search-field-container {
      position: relative;
      flex: 1;

      .inner-right {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 16px !important;
      }

      .search-field-input {
        width: 240px;
        height: 25px;
        padding-right: 30px;
        outline: none;
      }
    }

    .search-field-container-action {
      display: flex;
      margin-top: 2px;

      .search-field-select {
        height: 25px;
        width: 205px;
        outline: none;
      }
    }
  }

  &__items {
    max-height: calc(100vh - 112px);
    overflow: auto;

    .nav-item-sub {
      background-color: $subNavBackground;
    }

    .nav-item {
      background-color: $white;
      // border-bottom: 1px solid #CCC;

      .item-group {
        display: flex;
        align-items: center;
        height: 30px;
        border-bottom: 1px solid $navBorder;
        padding-left: 10px;

        &.active {
          border-bottom: none;
          background-color: $defaultIconColor;

          a {
            .title {
              color: $white;
            }
          }
        }

        .cursor {
          cursor: pointer;
        }
      }

      &.sub-item {
        background-color: $subNavBackground;

        .nav-item-sub {
          .nav-item {
            &.sub-item {
              .item-group {
                padding-left: 40px;
              }
            }
          }

          .item-group {
            padding-left: 20px;
          }
        }

        &:first-child {
          border-top: none;
        }

        .item-group {
          display: flex;
          align-items: center;
          height: 30px;
          border-bottom: none;
          padding-left: 20px;
          border-top: 1px solid $navBorder !important;

          &:first-child {
            border-top: none;
          }
        }
      }

      // &.active {
      //   border-bottom: none;
      //   background-color: #418ACA;

      //   .item-group {

      //     a {
      //       .title {
      //         color: #FFF;
      //       }
      //     }
      //   }
      // }

      &:first-child {
        border-top: 1px solid $navBorder;
      }

      // &:last-child {
      //   border-bottom: none;
      // }

      a {
        display: flex;
        text-decoration: none;
        color: $chevronDefault;
        width: 300px;
        outline: none;

        &:focus,
        &:active {
          outline: none;
        }

        i {
          &.no-pad {
            padding: 0 !important;
          }
          &.has-option {
            padding-left: 0 !important;
          }
          &.none-option {
            padding-left: 16px;
          }
        }

        .count {
          width: 28px;
          height: 15px;
          font-size: 9px;
          font-weight: 500;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
        }

        .title {
          flex: 1;
          display: inline-block;

          &.retired {
            color: #aaa;
          }
        }

        .phxico {
          padding: 0 10px;
          font-size: 12px;
          // TODO: fix with on next dev release
          min-width: 26px;
          max-width: 36px;
        }
      }
    }
  }
}

.action-container {
  width: 30px;
  height: 24px;
  border-radius: 5px;
  margin-top: 4px;
  margin-left: 5px;
  border: 1px solid $navActiveItemBackground;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;

  &.active {
    background: $navActiveItemBackground;
    color: $white;
  }
}

.indicator {
  position: relative;
  margin-left: 20px;
  flex: 1;
  letter-spacing: 0.8px;

  > input {
    border-radius: 0;
    background: transparent;
    color: $white;
    border: none;
    letter-spacing: 0.8px;
    width: 100%;
    padding-right: 20px;
    outline: none;
  }

  &.rounded {
    &:before {
      border-radius: 50%;
    }
  }

  &.active {
    &:before {
      background: $promoRunning;
    }
  }

  &.retired {
    &:before {
      background: $retired;
    }
  }

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: 15px;
    left: -20px;
  }
}

.member-list-loading-wrapper {
  height: 287px;
}

.member-list-wrapper {
  .search-input-wrapper {
    margin-top: 5px;
    width: 670px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;

    .search {
      position: absolute;
      right: 85px;
      top: 7px;
      font-size: 14px;
      color: #80b4e2;
    }

    input {
      height: 27px;
      flex: 1;
      padding-right: 25px;
      outline: none;
    }

    button {
      width: 75px;
      margin-left: 5px;
      outline: none;
    }
  }

  .controls-wrapper {
    margin-top: 5px;
    background: #f3f3f3;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;

    .controls {
      display: flex;
      width: 670px;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;

      .counter {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      button {
        width: 130px;
        outline: none;
      }
    }
  }
}

// TABLE
.table {
  &__root {
    margin-top: 10px;
    width: 670px;
    margin-left: auto;
    margin-right: auto;
    height: 327px;
    overflow: auto;

    .header {
      display: flex;
      flex: 1;
      height: 25px;
      line-height: 25px;
      background: #e2e2e2;

      &.empty {
        display: none;
      }

      &__row {
        display: flex;
        flex: 1;
        border-top: 1px solid #aaaaaa;

        .cell_ {
          border-left: 1px solid #aaaaaa;
          border-right: 1px solid #aaaaaa;
          border-bottom: 0;
          padding-left: 5px;
          text-overflow: ellipsis;
          overflow: hidden;

          &:not(:first-child) {
            border-left: 0;
          }
        }
      }
    }

    .body {
      &__row {
        display: flex;
        border-top: 1px solid #aaaaaa;
        height: 25px;
        line-height: 25px;

        &:last-child {
          border-bottom: 1px solid #aaaaaa;
        }

        .cell_ {
          border-left: 1px solid #aaaaaa;
          border-right: 1px solid #aaaaaa;
          border-bottom: 0;
          padding-left: 5px;
          text-overflow: ellipsis;
          overflow: hidden;

          &.icon {
            display: flex;
            align-items: center;
            padding-left: 0;
            justify-content: center;
            cursor: pointer;

            a {
              text-decoration: none;
              color: #80b4e2;

              &:active,
              &:focus {
                color: #80b4e2;
              }
            }
          }

          &:not(:first-child) {
            border-left: 0;
          }
        }
      }
    }
  }
}
