.reports-list {
  min-width: 250px;
  width: 250px;
  padding: 0px;
  overflow: auto;
  border-right: 1px solid $line-gray;
}

.reports-folder {
  //margin-bottom: 15px;

  &.indent {
    > div {
      padding-left: 28px;
    }

    > p {
      padding-left: 48px;
    }

    span {
      &.reports-Item {
        padding-left: 48px;
      }
    }
  }

  &__name-row {
    font-size: 16px;
    padding: 5px 8px;
    cursor: pointer;
    &.chosen {
      background: $secondary;
      .reports-folder__arrow,
      .reports-folder__name {
        color: $black;
      }
    }
    &:hover {
      background: $primary;
      .reports-folder__arrow,
      .reports-folder__name {
        color: $white;
      }
    }
  }

  &__icon {
    color: #ffca28;
    margin-right: 5px;
    float: left;
  }

  &__arrow {
    display: inline-block;
    width: 16px;
    color: #000;
    margin-right: 5px;
    cursor: pointer;
    float: left;
    outline: none;
  }

  &__name {
    text-transform: uppercase;
    color: $black;
    text-decoration: none;
    font-size: 12px;
    display: block;
    width: calc(100% - 52px);
    float: left;
    padding-top: 3px;
  }
}

.reports-Item {
  padding: 5px 5px 5px 28px;
  margin: 0;
  cursor: pointer;
  display: block;
  &.chosen {
    background: $secondary;
    outline: none;
    .icon-file {
      color: $secondary-darkest;
    }
    a {
      color: $black;
    }
  }
  &:last-child {
    margin-bottom: 10px;
  }
  a {
    color: $gray-dark;
    text-decoration: none;
    display: block;
    width: calc(100% - 30px);
    float: left;
    word-wrap: break-word;
  }
  .icon-file {
    padding-right: 10px;
    color: $primary-light;
    float: left;
  }
  &:hover {
    background: $primary-light;
    .icon-file,
    a {
      color: $white;
    }
  }
}
