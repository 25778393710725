/* Animations to fade the toast in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes flashpop-red {
  from {
    background-color: $red;
    color: $white;
  }
  to {
    background-color: $gray-light;
    color: $gray-dark;
  }
}

@keyframes flashpop {
  from {
    background-color: $primary-darker;
  }
  to {
    background-color: white;
  }
}

@-webkit-keyframes flashpop {
  from {
    background-color: $primary-darker;
  }
  to {
    background-color: white;
  }
}

@keyframes popbox {
  0% {
    transform: scale(0.4);
  }
  60% {
    transform: scale(1.2);
  }
  85% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.container-fluid {
  padding: 0;
}
.width-wrapper {
  overflow: unset !important;
  // max-width: $max-width;
  margin: 0 auto;
}

.section-wrapper {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 40px);
  border: 1px solid $line-gray;
  border-radius: 3px;
  background-color: $white;
  @extend .clearfix;
  h3 {
    color: $primary;
    text-transform: uppercase;
    font-weight: $fw-light;
    @include nopads();
    margin-bottom: 20px;
  }
}

.header-container {
  .brand {
    width: 195px;
    .logo {
      height: $header-height;
    }
    h1 {
      margin: 0;
      line-height: $header-height;
    }
  }
  .user-section {
    line-height: $header-height;
    background-color: $black25;
    width: auto;
    .btn {
      text-decoration: none;
      display: inline-block;
      &.btn-settings {
        border-right: 1px solid;
      }
    }
    .user-section-item {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .control-buttons {
    line-height: $header-height - 10px;
    margin-left: 20px;
    a {
      display: block;
      margin: 3px 0;
      box-sizing: border-box;
      padding: 0 5px;
      border-radius: 5px;
      color: white;
    }
  }
}

.tabgrp-container {
  background-color: $primary-darkest;
  height: 30px;
  .tabgrp {
    padding: 5px 5px 0px;
    float: left;
    .tab {
      background-color: $primary;
      color: $white;
      padding: 3px 5px;
      margin-right: 5px;
      width: $tab-w;
      text-align: center;
      border-radius: 5px 5px 0px 0px;
      height: 25px;
      line-height: 25px;
      &.active {
        background-color: $white;
        color: $primary;
      }
    }
  }
}

.page-container {
  position: absolute;
  top: $header-height;
  width: 100%;
  bottom: 0;
  left: 0;
}

.page-w100 {
  left: 0px;
}
.width-100 {
  width: 100%;
}
.sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  border-right: 1px solid;
  overflow-y: auto;
  border-right-color: $primary-lighter;
  transition: 300ms all;
  transform: translatex(0);
  .app-name {
    display: none;
  }
  &.collapse,
  &.hide {
    transform: translatex(-100%);
  }
  select.sports,
  .date-select,
  .status-select {
    margin-bottom: 5px;
  }
  .app-name {
    padding: 10px;
    font-size: 17px;
    text-transform: uppercase;
    color: $white;
  }
  #app-event-creator & {
    width: $sidebar-width-wide;
    overflow: hidden;
    .sidebar-head {
      height: 135px;
    }
    .sidebar-body {
      position: absolute;
      top: 135px;
      height: calc(100% - 135px);
      left: 0;
      right: 0;
      overflow: hidden;
    }
  }
  #app-customer-chat & {
    width: $sidebar-width-customer-chat;
    &.sidebar-container {
      display: flex;
      .queue,
      .sessions {
        width: 50%;
      }
      .sidebar-head {
        font-weight: bold;
      }
      .sidebar-body {
        height: 300px;
        overflow-y: auto;
        padding: 10px;
        .queue-item,
        .session-item {
          display: flex;
          justify-content: space-between;
          border: 1px solid;
          padding: 5px;
          margin-bottom: 5px;
          button {
            align-self: flex-end;
          }
        }
        .queue-item-disabled,
        .queue-item[disabled] {
          background-color: $red;
        }
        .flash-item {
          animation-name: flash;
          animation-duration: 1s;
        }
        .session-item {
          cursor: pointer;
        }
        .ended {
          background-color: grey;
        }
        .selected {
          border: 1px solid $red;
        }
      }
    }
  }
  #app-instant-action & {
    .react-tabs {
      overflow: hidden;
    }
    .react-tabs__tab-list {
      display: flex;
      justify-content: center;
      width: 50%;
      margin: auto;
      margin-top: 10px;
    }
    .react-tabs__tab-panel {
      margin: 6px;
      border: none;
    }
    .bet-filters {
      .toggle-header {
        background-color: #9ccffc;
        padding: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-text {
          color: #1a6fba;
        }
      }
      .toggle-button {
        font-size: 20px;
        cursor: pointer;
      }
      .disabled-body {
        pointer-events: none;
        opacity: 0.4;
      }
      .bet-type-filter {
        border: 0.3px solid grey;
        margin-top: 10px;
        .body {
          > div {
            padding: 7px;
          }
          .single-bets-field {
            border-bottom: 0.3px solid grey;
          }
        }
      }
      .markets-filter {
        border: 0.3px solid grey;
        margin-top: 10px;
        .body {
          > div {
            padding: 7px;
          }
          .mainbook-field {
            border-bottom: 0.3px solid grey;
          }
        }
      }
      .total-stake-filter {
        margin-top: 10px;
        .select-fields {
          margin-top: 5px;
          select {
            width: 50%;
          }
        }
      }
      .risk-amount-filter {
        margin-top: 10px;
        .select-fields {
          margin-top: 5px;
          select {
            width: 50%;
          }
        }
      }
      .sports-filter {
        border: 0.3px solid grey;
        margin-top: 10px;
        .body {
          > div {
            padding: 7px;
          }
          .show-bets-field {
            border-bottom: 0.3px solid grey;
          }
          .exclude-bets-field {
            border-bottom: 0.3px solid grey;
          }
        }
        .sports {
          display: flex;
          flex-direction: column;
          max-height: 430px;
          overflow-y: auto;
          padding: 7px;
        }
        .sports > .sport-item {
          margin-bottom: 10px;
          display: flex;
          padding: 5px 0px;
          > span {
            margin-top: -1px;
            padding: 0px 2px;
            > img {
              height: 12px;
            }
          }
          .path-icon {
            align-items: baseline;
            color: black;
            align-items: baseline;
            margin-right: 0px;
          }
          label {
            margin-right: 0px;
          }
        }
      }
    }
    input[type="checkbox"],
    input[type="radio"] {
      vertical-align: middle;
      position: relative;
      bottom: 1px;
    }
    .bet-display {
      .max-rows {
        .field {
          padding-top: 2%;
        }
      }
      .bets-formatting {
        .header {
          border-bottom: 1px solid #418aca;
          padding-bottom: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            font-size: 14px;
            color: #418aca;
            padding-left: 5px;
          }
          .add-button {
            padding: 0px 10px;
            box-shadow: none;
            .phx-plus {
              font-size: 18px;
            }
          }
        }
        .lower-limit {
          margin-top: 10px;
          .field {
            padding-top: 2%;
          }
        }
        .upper-limit {
          margin-top: 10px;
          .field {
            padding-top: 2%;
          }
        }
        .colors {
          display: flex;
          .font-color {
            margin-top: 10px;
            .field {
              padding-top: 2%;
              input {
                width: 25px;
                height: 25px;
              }
            }
          }
          .background-color {
            margin-top: 10px;
            margin-left: 20px;
            .field {
              padding-top: 2%;
              input {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
      .formatting-list {
        height: 500px;
        background-color: #f3f3f3;
        margin: 10px 0px;
        display: flex;
        flex-direction: column;
        .list {
          padding: 10px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          height: 100%;
          .formatting-item {
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            background-color: white;
            border-radius: 2px;
            .header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #e2e2e2;
              height: 30px;
              padding: 0px 10px;
              color: grey;
              .remove-button {
                font-size: 20px;
                cursor: pointer;
                &:hover {
                  color: red;
                }
              }
            }
            .body {
              display: flex;
              align-items: center;
              height: 30px;
              padding: 0px 10px;
            }
          }
        }
        .attention-field {
          background-color: #e2e2e2;
          height: 50px;
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: auto;
          .phx-alert {
            color: orange;
            padding-left: 10px;
          }
          .attention-text {
            width: 200px;
            font-style: italic;
            color: grey;
            padding-left: 10px;
          }
        }
      }
      input[type="number"] {
        font-size: 120%;
        width: 95%;
        &.error {
          border-color: red;
          outline: none;
        }
      }
    }
    .brand-filter {
      .select-field {
        margin-top: 5px;
        select {
          width: 50%;
        }
      }
    }
  }
  .sidebar-head {
    padding: 10px;
    background-color: $gray-lightest;
    box-sizing: border-box;
    &.nopads {
      padding: 0px;
    }
    .sidenav {
      h4,
      i {
        color: $black;
      }
      p {
        color: $black50;
      }
      .sidenav-lvl-0 h4 {
        background: $secondary-dark;
        @include transit();
        &:hover {
          background-color: $primary;
        }
        .phxico {
          @include transit();
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  .refresh-btn-wrapper {
    button {
      color: $primary;
      background: none;
      border: 0px solid;
      font-size: 15px;
      @include transit();
      cursor: pointer;
      opacity: 0.4;
      padding: 0px 5px;
      box-shadow: none;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.page-main {
  position: absolute;
  background-color: $gray-lightest;
  border-left: 1px solid $line-gray;
  top: 0;
  bottom: $footer-height;
  left: $sidebar-width;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  transition: 300ms all;
  &.expand {
    left: 0;
  }
  #app-event-creator & {
    left: $sidebar-width-wide;
  }
  #app-customer-chat & {
    left: $sidebar-width-customer-chat;
    padding: 10px;
    .main-head {
      padding-bottom: 10px;
      font-weight: bold;
    }
    .main-body {
      .customer-info {
        background-color: $bg;
        height: 60px;
        margin-bottom: 5px;
        padding: 5px;
        box-sizing: border-box;
      }
      .messages {
        background-color: $bg;
        height: 670px;
        margin-bottom: 5px;
        overflow-y: auto;
        padding: 5px;
        .customer-message {
          color: orange;
        }
        .operator-message {
          color: blue;
        }
        .system-message {
          color: green;
        }
      }
      .text-field {
        width: 100%;
        height: 100px;
        margin-bottom: 5px;
        box-sizing: border-box;
        resize: none;
      }
      .buttons {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  #app-instant-action & {
    .react-tabs__tab-list {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      margin-top: 10px;
    }
    .react-tabs__tab-panel {
      margin: 10px;
      border: none;
    }
    .react-tabs__tab {
      width: 30%;
    }
    .main-tabs {
      .ReactTable {
        .rt-table {
          overflow: initial;
          overflow-x: auto;
        }
        .rt-thead {
          overflow-y: scroll;
          overflow-x: hidden;
        }
        .rt-tbody {
          overflow-y: scroll;
          overflow-x: hidden;
          display: block;
          // max-height: 600px;
          // min-height: 180px;
          height: calc(100vh - 215px);
          .rt-tr-group {
            background: white;
            height: 60px;
            cursor: pointer;
            &:hover {
              background: $primary-lighter;
              .bet-description {
                background-color: darken($primary-lighter, 5%);
              }
            }
          }
        }
        .rt-tr {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
      .table {
        select,
        input {
          height: 32px !important;
        }
      }
      .accounts-table {
        .account-header {
          white-space: normal;
          word-wrap: break-word;
        }
        .username-header {
          white-space: normal;
          word-wrap: break-word;
        }
        .name-header {
          white-space: normal;
          word-wrap: break-word;
        }
        .email-header {
          white-space: normal;
          word-wrap: break-word;
        }
        .site-header {
          white-space: normal;
          word-wrap: break-word;
        }
      }
      .bets-table {
        // .icon-header  {
        //     width: 5% !important;
        // }
        // .date-header  {
        //     width: 5% !important;
        // }
        // .shop-header  {
        //     width: 5% !important;
        // }
        // .username-header {
        //     width: 7.5% !important;
        // }
        // .account-description-header {
        //     width: 10% !important;
        // }
        // .description-header  {
        //     width: 15% !important;
        // }
        // .sport-header  {
        //     width: 7.5% !important;
        // }

        // .unit-stake-header  {
        //     width: 7.5% !important;
        // }

        // .total-stake-header  {
        //     width: 7.5% !important;
        // }

        // .win-amount-header  {
        //     width: 7.5% !important;
        // }

        // .unit-stake-p-header {
        //     width: 7.5% !important;
        // }

        // .total-stake-p-header {
        //     width: 7.5% !important;
        // }

        // .win-amount-p-header  {
        //     width: 7.5% !important;
        // }

        // .icon-cell {
        //     width: 5% !important;
        // }
        // .date-cell  {
        //     width: 5% !important;
        // }
        // .shop-cell  {
        //     width: 5% !important;
        // }
        .username-cell {
          // width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }
        // .account-description-header  {
        //     width: 10% !important;
        // }
        // .description-cell  {
        //     width: 15% !important;
        // }
        .sport-cell {
          // width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }

        .unit-stake-cell {
          // width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }

        .total-stake-cell {
          // width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }

        .win-amount-cell {
          //     width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }

        .unit-stake-p-cell {
          //     width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }

        .total-stake-p-cell {
          //     width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }

        .win-amount-p-cell {
          //     width: 7.5% !important;
          white-space: normal;
          word-wrap: break-word;
        }
      }
    }
    .connected-button {
      display: flex;
      justify-content: flex-end;
      margin: 10px;
      .connected,
      .disconnected {
        padding: 10px;
        border-radius: 5px;
        color: white;
        cursor: default;
      }
      .connected {
        background-color: green;
      }
      .disconnected {
        background-color: red;
      }
    }
  }
  .main-wrapper {
    background-color: $bg;
  }
  &.page-w100,
  &.no-side {
    left: 0px;
    .form-inner {
      padding: 5px;
    }
  }
  &.no-footer {
    bottom: 0;
  }
}

.main-inner {
  background: $white;
  padding: 5px 10px;
}

.page-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: $footer-height;
  line-height: $footer-height;
  left: $sidebar-width;
  #app-event-creator & {
    left: $sidebar-width-wide;
  }
}

.market-state-actions {
  margin-bottom: 10px;
}

.market-state-table-container {
  overflow-x: auto;
  width: 100%;
  table td,
  table th {
    text-align: left;
  }

  .market-state-table {
    td {
      &.settled {
        background-color: $color-settled;
      }
      &.closed {
        background-color: $color-closed;
      }
      &.open {
        background-color: $color-open;
      }
      &.resulted {
        background-color: $color-resulted;
      }
      &.suspended {
        background-color: $color-suspended;
      }
    }
  }
}
.center-align {
  display: flex;
  align-items: center;
}

.info-message {
  padding: 10px;
  background: $secondary-lightest;
  color: $secondary-dark;
  margin-top: 10px;
  border: 1px solid $secondary-dark;
  border-radius: 3px;
  display: flex;
  @include transit();
  animation: fade-in 1s ease;
  animation-iteration-count: 1;
  i.phxico {
    margin-right: 10px;
    float: left;
  }
  span {
    float: left;
  }
  &.error {
    background: $error-bg;
    color: $error-fg;
    border-color: $error-fg;
  }
}

.toast-container {
  background-color: $primary-darker;
  color: $primary-lightest;
  text-align: center;
  border-radius: 2px;
  padding: 10px;
  position: fixed;
  z-index: 1000; // must be higher than loading indicator
  left: 50%;
  bottom: 30px;
  // max-width: 300px;
  // min-width: 300px;
  width: 350px;
  margin-left: -175px;
  animation: fadein 0.5s, fadeout 0.5s 1.6s;
  display: table;
  i {
    width: 10%;
    vertical-align: middle;
    display: table-cell;
    font-size: 25px;
    padding: 0 5px;
    &:last-child {
      font-size: 20px;
      border-left: 1px solid $black25;
      padding-left: 13px;
      color: $black25;
      &:hover {
        color: $black50;
      }
    }
  }
  span {
    width: 80%;
    vertical-align: middle;
    display: table-cell;
  }
  &.success {
    background: $toast-success-bg;
    color: $toast-success-color;
    box-shadow: 0 0 4px 0px $toast-success-color;
  }
  &.error {
    background: $toast-error-bg;
    color: $toast-error-color;
    box-shadow: 0 0 4px 0px $toast-error-color;
  }
}

.toastr-container {
  position: fixed;
  top: 46px;
  right: 0;
  padding: 10px;
  z-index: 9999;
  .toast {
    display: flex;
    position: relative;
    align-items: stretch;
    width: 400px;
    min-height: 60px;
    border-radius: 4px;
    margin-bottom: 5px;
  }
}

.toast-success {
  color: $toast-success-color;
  background-color: $toast-success-bg;
  border-color: darken($toast-success-bg, 5%);
}
.toast-success .toast-icon {
  fill: $toast-success-color;
}
.toast-error {
  color: $toast-error-color;
  background-color: $toast-error-bg;
  border-color: darken($toast-error-bg, 5%);
}
.toast-error .toast-icon {
  fill: $toast-error-color;
}
.toast-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.toast-info .toast-icon {
  fill: #31708f;
}
.toast-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.toast-warning .toast-icon {
  fill: #8a6d3b;
}
.toast-message-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 8px 32px 8px 0;
}
.toast-icon-container {
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toast-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
  text-shadow: 0 1px 0 #fff;
  color: inherit;
  font-size: 16px;
}
.toast-close:hover {
  opacity: 0.8;
}

.toast-icon {
  fill: inherit;
  width: 20px;
  height: 20px;
}

.toast-enter {
  transform: translate(110%, 0);
}
.toast-enter.toast-enter-active {
  transform: translate(0, 0);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.toast-enter > div {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.23, 1, 0.32, 1) 300ms;
}
.toast-enter.toast-enter-active > div {
  opacity: 1;
}
.toast-enter.toast-enter-active > .toast-close {
  opacity: 0.2;
}

.toast-leave {
  transform: translate(0, 0);
}

.toast-leave.toast-leave-active {
  transform: translate(110%, 0);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.row {
  margin: 0 -5px;
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}

.desktop-full {
  width: 100%;
  padding: 0 5px;
  float: left;
  box-sizing: border-box;
}

.desktop-half {
  width: 50%;
  padding: 0 5px;
  float: left;
  box-sizing: border-box;
}

.desktop-quarter {
  width: 25%;
  padding: 0 5px;
  float: left;
  box-sizing: border-box;
}

.desktop-three-quarter {
  width: 75%;
  padding: 0 5px;
  float: left;
  box-sizing: border-box;
}

.headers-preferences-modal {
  .body {
    padding: 10px;
    .preferences-headers {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding-top: 10px;
      padding-bottom: 10px;
      .header {
        width: 25%;
        text-align: center;
        padding: 10px 0px;
        background: $primary;
        color: $white;
        border-right: 1px solid $white50;
      }
    }
    .preferences-columns {
      display: flex;
      justify-content: space-between;
      .column {
        display: flex;
        flex-direction: column;
        width: 25%;
      }
      label {
        padding: 5px 0px;
      }
    }
  }
}

@media (max-width: 1024px) {
  header {
    .header-content {
      .header-actions {
        &.sidebar-toggle {
          display: block;
          // margin: 0 0 0 20px;
          padding: 0 10px;
        }
      }
    }
    //NOTE: not a typo: overrides brand class
    //TODO: create tablet.scss and include after modules or move query to specific module
    .brand.brand {
      width: 60px;
      .logo {
        background: url(../assets/logos/phx.png) no-repeat left;
        background-size: 46px;
        width: 60px;
      }
    }
  }
  .navigation-container {
    display: none;
  }
  .tablet-landscape-full {
    width: 100%;
    float: left;
  }
  .sidebar-container {
    .app-name {
      display: block;
    }
  }
  .tablet-landscape-half {
    width: 50%;
    float: left;
  }
  .tabular .react-tabs__tab-list {
    overflow-y: auto;
    white-space: nowrap;
  }
}

.modal-controls {
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: center;
}
