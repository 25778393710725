.modal-add-jackpot-bet {
  width: 90%;
  height: 80%;

  .align-items-normal {
    align-items: normal;
  }
}

.jbm-event-creator-row {
  width: 100%;
  position: relative !important;
  width: auto !important;
  .jbm-sports-selecttbox {
    width: 100%;
    .dropdown {
      position: relative;
      display: inline-block;
      width: 100%;
      border-radius: 5px;
      background-color: #fff;
      color: #323232;
      padding: 0px 15px;
      height: 26px;
      line-height: 24px;
      border: 1px solid #e2e2e2;
      max-width: 100%;
      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 5px;
        top: 60%;
        margin-top: -6px;
        border-width: 6.4px 3.5px 0px 3.5px;
        border-style: solid;
        border-color: black transparent;
      }
      &.active {
        box-shadow: 0px 0px 1px 1px #418aca;
      }
      &.disabled {
        pointer-events: none;
        background: #f1f3f3;
        color: #333;
        border: none;
      }
      .dropbtn {
        background-color: transparent;
        color: black;
        border: none;
        display: inline-block;
        width: 100%;
        &.disabled {
          pointer-events: none;
          background: #f1f3f3;
          color: #333;
        }
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
      #sports-search {
        height: 100%;
        width: 100%;
        border: none;
        &:focus {
          outline: none;
        }
      }
      .dropdown-content {
        display: none;
        flex-direction: column;
        position: absolute;
        background-color: #fff;
        overflow: auto;
        z-index: 1;
        width: 100%;
        left: 0;
        height: 300px;
        color: black;
        top: 25px;
        &.active {
          border: 1px solid #418aca;
        }
        .dropdown-list {
          padding: 5px;
          > label {
            display: flex;
            > span {
              margin-right: 5px;
              margin-top: 1px;
              > img {
                height: 12px;
              }
            }
          }
          .path-icon {
            display: inline-block;
            padding: none;
            margin-right: 0;
          }
          &:hover {
            background-color: #418aca !important;
            color: #fff;
          }
          &.searched-item {
            background-color: #418aca !important;
            color: #fff;
          }
        }
      }
    }
  }
  .jbm-sportsTree-hierirechy {
    overflow: auto;
    max-height: 322px;
    width: 100%;
    top: 0 !important;
    position: relative !important;
  }
}

.add-jackpotBet-modal {
  padding-bottom: 40px;
}

.add-Jackpot-currency-row .plus-minus-icon {
  text-align: right;
}
