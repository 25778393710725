.text-large {
  font-size: $font-large;
}

.text-medium {
  font-size: $font-medium;
}

.text-xmedium {
  font-size: $font-xmedium;
}

.text-small {
  font-size: $font-small;
}

.text-xsmall {
  font-size: $font-xsmall;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}
