.header-utilities-container {
  border-bottom: 1px solid;
  position: relative;
  height: $header-utilities-height;
  .actions {
    border-right: 1px solid;
    position: absolute;
    top: 0;
    left: 0;
    width: $header-utilities-action-width;
    &.list-reset {
      display: none;
    }
    a,
    span {
      display: block;
      height: $header-utilities-height * 0.5;
      line-height: $header-utilities-height * 0.5;
      box-sizing: border-box;
      padding: 5px 8px;
      text-align: center;
    }
  }
  .filters-container {
    padding: 5px;
    background-color: $white;
    .filter-field {
      display: inline-block;
      padding: 0px 3px 3px 3px;
      vertical-align: middle;
      width: 10.75%;
    }
  }
  .filter-field.period-filter {
    width: fit-content;
    margin-bottom: 1px;
    button {
      width: 24px;
      height: 24px;
      padding: 2px;
      margin-left: 2px;
    }
    .btn {
      background: #fff;
      box-shadow: none;
      border: 1px solid;
      &.active {
        border: none;
      }
      &:focus {
        outline: none;
      }
      &.btn[disabled] {
        cursor: not-allowed;
        background: #e2e2e2 !important;
        color: #aaa !important;
        border: none !important;
        .phxico {
          color: #aaa !important;
        }
        &:hover {
          background-color: #e2e2e2 !important;
          border: none;
        }
      }
    }
    .btn.btn-open {
      border-color: #59b82d;
      color: #1e5903;
      .phx-open {
        color: #1e5903;
      }
      &:hover {
        background-color: #74ce4b !important;
      }
      &.active {
        background-color: #b6e5a0;
      }
    }
    .btn.btn-suspended {
      border-color: #e6b629;
      color: #7c5e07;
      .phx-suspended {
        color: #7c5e07;
      }
      &:hover {
        background-color: #e7cf32 !important;
      }
      &.active {
        background-color: #f2e591;
      }
    }
    .btn.btn-closed {
      border-color: #ec6251;
      color: #841405;
      .phx-closed {
        color: #841405;
      }
      &:hover {
        background-color: #f7786f !important;
      }
      &.active {
        background-color: #f6bfb8;
      }
    }
    .btn.btn-resulted {
      border-color: #8b6dea;
      color: #3b11bd;
      .phx-resulted {
        color: #3b11bd;
      }
      &:hover {
        background-color: #ac99e4 !important;
      }
      &.active {
        background-color: #cbbef3;
      }
    }
    .btn.btn-settled {
      border-color: #b3b3b3;
      color: #313030;
      .phx-settled {
        color: #313030;
      }
      &:hover {
        background-color: #b3b3b3 !important;
      }
      &.active {
        background-color: #cac5c5;
      }
    }
  }
  // .filters-container.period-filter{
  //     display: flex;
  //     justify-content: center;
  //     .btn{
  //       width: 100px;
  //       background: #fff;
  //       box-shadow: none;
  //       border: 1px solid;
  //       &.active{
  //           border: none;
  //           font-weight: 600;
  //       }
  //       &:focus{
  //           outline: none;
  //       }
  //       &:hover{
  //           font-weight: 600;
  //       }
  //       &.btn[disabled]{
  //         cursor: not-allowed;
  //         background: #e2e2e2 !important;
  //         color: #aaa !important;
  //         border: none !important;
  //         &:hover{
  //             background-color: #e2e2e2 !important;
  //             border: none;
  //         }
  //       }
  //     }
  //     .btn.btn-open{
  //         border-color: #59b82d;
  //         color: #1e5903;
  //         &:hover{
  //             background-color: #74ce4b !important;
  //         }
  //         &.active{
  //             background-color: #b6e5a0;
  //         }
  //     }
  //     .btn.btn-suspended{
  //         border-color: #e6b629;
  //         color: #7c5e07;
  //         &:hover{
  //             background-color: #e7cf32 !important;
  //         }
  //         &.active{
  //             background-color: #f2e591;
  //         }
  //     }
  //     .btn.btn-closed{
  //         border-color: #ec6251;
  //         color: #841405;
  //         &:hover{
  //             background-color: #f7786f !important;
  //         }
  //         &.active{
  //             background-color: #f6bfb8;
  //         }
  //     }
  //     .btn.btn-resulted{
  //         border-color: #8b6dea;
  //         color: #3b11bd;
  //         &:hover{
  //             background-color: #ac99e4 !important;
  //         }
  //         &.active{
  //             background-color: #cbbef3;
  //         }
  //     }
  //     .btn.btn-settled{
  //         border-color: #b3b3b3;
  //         color: #313030;
  //         &:hover{
  //             background-color: #b3b3b3 !important;
  //         }
  //         &.active{
  //             background-color: #cac5c5;
  //         }
  //     }

  //   }
  h3 {
    @include h3();
    &.inline-fields {
      display: inline;
      margin-right: 10px;
    }
  }
}

.tabgrp-container {
  .tabgrp {
    .tab.mainfilter {
    }
  }
  .filter-searchevent {
    min-width: 200px;
    float: right;
    margin-top: 4px;
    margin-right: 5px;
    .block-input {
      padding: 0px 10px;
    }
  }
  .filter-resetfilter {
    float: right;
    button {
      padding: 0 15px;
    }
  }
  .filter-row-height {
    float: right;
    margin: 0 10px 0 5px;
    line-height: 30px;
    color: #fff;
  }
  button {
    background: $white;
    border: 1px solid $gray;
    border-radius: 3px;
    margin-top: 4px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    padding: 0 5px;
    @include transit();
    cursor: pointer;
    font-weight: 200;
    @include box-outline($primary-lighter, $primary-darkest);
    box-shadow: none;
  }
}
