.betattendant-sidebar-container {
  border-right-color: #80b4e2;
  position: absolute;
  top: 28px;
  left: 0;
  bottom: 0;
  width: 220px;
  border-right: 1px solid;
  border-right-color: currentcolor;
  overflow-y: auto;
  //border-right-color: #80b4e2;
  transition: 300ms all;
  transform: translatex(0);

  .bet-filters {
    .sports-filter {
      border-color: $line-gray;
      border: 0.3px solid grey;
      border-top-color: grey;
      border-right-color: grey;
      border-bottom-color: grey;
      border-left-color: grey;
      //margin-top: 10px;
      box-sizing: border-box;
      .toggle-button {
        font-size: 12px;
        color: $primary-darkest;
      }
      .body {
        > div {
          border-color: $line-gray !important;
          &:last-child {
            border-bottom: 0px !important;
          }
        }
      }
      .sports {
        @include transit();
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 400px;
        &.disabled {
          height: 0px;
          height: 0px;
          padding: 0px;
        }
      }
    }

    .toggle-header {
      background-color: #9ccffc;
      padding: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .header-text {
        color: #1a6fba;
      }
    }
  }

}
