.modal-winner-jackpot-bet {
  width: 90%;
  height: 80%;

  .align-items-normal {
    align-items: normal;
  }
}

.winnerBox-searchButton {
  width: 100%;
}

.winner-rows {
  margin-top: 1%;
}

.selected-winner-type {
  background-color: #e2e2e2;
}

.winner-jackpot-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3px 10px 10px 15px;
  background-color: white;
  text-align: left;
}

.select-wrap select {
  height: 0% !important;
}

#app-jackpot-bet-winner-manager {
  margin-left: 5px;
  padding-bottom: 40px;
}

#app-jackpot-bet-winner-manager {
  .winner-rows textarea {
    width: 100%;
  }
}
