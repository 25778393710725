.notify-main-container {
  width: 40px;
  .notify-container {
    margin: 10px 30px 0 0;
    cursor: pointer;
    right: -395px;
    height: 35px;
    width: 35px;
  }
  .notify-img {
    position: absolute;
    border: 3px solid #31679600;
  }
  .notification-active {
    border-radius: 16px;
    background: #3167967a;
  }
  .notify-count-container {
    display: flex;
    align-items: center;
    top: -5px;
    position: relative;
    left: 13px;
    height: 20px;
    width: 20px;
    border-radius: 15px;
  }
  .notify-count {
    border-radius: inherit;
    background: red;
    font-weight: 800;
    height: inherit;
    width: inherit;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-view {
    width: 485px;
    height: 632px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
    background-color: #ffffff;
    top: -2px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    right: 450px;
    .notify-header {
      height: 10%;
      background: #3b6992;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .notify-header-left {
        margin-left: 15px;
        font-size: 19px;
        font-weight: bold;
      }

      .notify-header-right {
        .mark-as-read-button {
          margin-right: 20px;
          background: #5c88af;
          color: white;
        }
      }
    }
    .notify-body {
      height: 90%;
      background: #ffffff;
      border-radius: 5px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .notify-holder {
        .icon-show {
          display: flex;
          width: 20%;
          justify-content: space-evenly;
          .side-icon {
          }
        }
        .icon-none {
          display: none;
        }
        width: 485px;
        height: 71px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;
        &:hover {
          background: #dde8f4;
        }
        .notify-holder-img-container {
          // width: 13%;
          // height: 84%;
          width: 12%;
          height: 80%;
          border-radius: 37px;
          border: 1px solid;
          border-color: #1682bd;
          display: flex;
          align-items: center;
          justify-content: center;
          .holder-img {
            height: 70%;
            width: 70%;
          }
        }
        .notify-holder-text-container {
          display: flex;
          align-items: center;
          margin-left: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 5px;
          width: 80%;
          .text-left-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          .notify-holder-text-title {
            font-size: 15px;
            color: #408aca;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 500;
          }
          .notify-holder-text {
            font-size: 12px;
            font-weight: 400;
            height: 14px;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            margin-bottom: 5px;
            color: #000000;
          }
          .notify-holder-time {
            width: 50%;
            margin-bottom: 3px;
            color: #000000;
            font-weight: 800;
          }
        }
      }
    }
  }
  .tab-none {
    display: none;
  }
  .new {
    background: #ebf5ff;
  }
  .old {
    background: #ffffff;
  }
}
.notify-holder {
  &[data-app="19"] {
    .notify-holder-img-container {
      background-color: $clr-risk;
    }
  }
  &[data-app="4"] {
    .notify-holder-img-container {
      background-color: $clr-cust;
    }
  }
  &[data-app="1"] {
    .notify-holder-img-container {
      background-color: $clr-evnt;
    }
  }
  &[data-app="48"] {
    .notify-holder-img-container {
      background-color: $clr-rslt;
    }
  }
  &[data-app="37"] {
    .notify-holder-img-container {
      background-color: $clr-chat;
    }
  }
  &[data-app="28"] {
    .notify-holder-img-container {
      background-color: $clr-inst;
    }
  }
  &[data-app="18"] {
    .notify-holder-img-container {
      background-color: $clr-rprt;
    }
  }
  &[data-app="7"] {
    .notify-holder-img-container {
      background-color: $clr-oper;
    }
  }
  &[data-app="22"] {
    .notify-holder-img-container {
      background-color: $clr-paym;
    }
  }
  &[data-app="6"] {
    .notify-holder-img-container {
      background-color: $clr-util;
    }
  }
  &[data-app="45"] {
    .notify-holder-img-container {
      background-color: $clr-crm;
    }
  }
  &[data-app="40"] {
    .notify-holder-img-container {
      background-color: $clr-bons;
    }
  }
  &[data-app="60"] {
    .notify-holder-img-container {
      background-color: $clr-jbm;
    }
  }
  &[data-app="24"] {
    .notify-holder-img-container {
      background-color: $clr-tm;
    }
  }
  &[data-app="13"] {
    .notify-holder-img-container {
      background-color: $clr-wl;
    }
  }
  &[data-app="34"] {
    .notify-holder-img-container {
      background-color: $clr-aff;
    }
  }
}
.notify-model-main {
  .modal-body {
    padding: 12px;
    border: 1px solid #80b3e2;
    border-radius: 4px;
    .modal-main-content {
      margin: 0px;
    }
    .btn-container {
      margin: 10px;
      display: flex;
      flex-flow: row-reverse;
      float: right;
      .confirm-btn {
        background: #80b3e2;
        color: white;
        box-shadow: 0px 3px #418aca;
        &:hover {
          background: #418aca;
        }
      }
      .cancel-btn {
        margin-right: 15px;
        background: #80b3e2;
        color: white;
        box-shadow: 0px 3px #418aca;
        &:hover {
          background: #418aca;
        }
      }
    }
  }
}
