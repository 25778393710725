@import "normalize";

@import "constants";
@import "colors";
@import "animation";
@import "mixins";
@import "fontsports";
@import "phxicons/phxicons";

@import "general";
@import "layout";
@import "forms";
@import "buttons";
@import "typography";
@import "icons";
@import "tabs";
@import "tables";
@import "menus";
@import "multiselectlist";

@import "components/fields";

@import "modules/header";
@import "modules/footer";
@import "modules/modal";
@import "modules/sportsTree";
@import "modules/pathtree";
@import "modules/filterbar";
@import "modules/datagrid";
@import "modules/datepicker";
// Added from  node_modules/flexboxgrid2/flexboxgrid2.css - for backwards compatibility after we upgraded to React 17 and CRA (previously these styles were in our head under a style tag somewhere. forcing legacy behaviour by importing this file)
@import "modules/flexboxgrid2.css";
@import "modules/rvgrid";
@import "modules/login";
@import "modules/kitTableActionBar";
@import "modules/importOpponentDlg";
@import "modules/eventCreator";
@import "modules/betRestrictions";
@import "themes";

//components styles
@import "components/lobby";
@import "components/RiskManager";
@import "components/EventCreator";
@import "components/Utilities";
@import "components/CustomerManager";
@import "components/CustomerChat";
@import "components/InstantAction";
@import "components/ReportsManager/main";
@import "components/OperatorManager";
@import "components/PaymentHandler";
@import "components/CombiRiskManager";
@import "components/JackpotBetManager/main";
@import "components/translationManager/main";
@import "components/BonusManager";
@import "components/Widgets";
@import "components/WagerLimits";
@import "components/AfiliateManager";
@import "components/AffiliateViewer";
@import "components/notification";
@import "components/betattendant";
@import "components/ShopManager";
