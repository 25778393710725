.kit-table-action-bar-container {
  padding: 5px;
  position: relative;

  .kit-table-action-button-container {
    box-sizing: border-box;
    width: 100%;
    height: 25px;
    line-height: 28px;
    background-color: $gray-lightest;
    text-align: right;

    a {
      margin: 0 5px;
      display: inline-block;
      color: $primary;

      &.disabled {
        color: $gray;
      }

      &.checked {
        color: $primary;
      }
    }
  }
}
