$error: #ff7a7a;

.report-details {
  flex-grow: 1;
  background: #eeeeee;
  overflow: auto;
  padding: 10px 10px 50px 10px;
  position: relative;

  &__footer {
    position: fixed;
    bottom: 0px;
    right: 0px;
    height: 50px;
    width: calc(100% - 250px);
    background: $white;
    border-top: 1px solid $line-gray;
    padding: 10px;
    .reports-footer-inner {
      padding-top: 5px;
      float: left;
    }
    .reports-format-title {
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 12px;
    }
    .reports-format-btn {
      float: right;
    }
  }
}

.reports-format {
  &__input {
    vertical-align: middle;
  }
}

.download-report-link {
  display: none;
}

.report-breadcrumb {
  padding: 10px 0px;
  .breadcrumb-item {
    float: left;
    color: $gray;
    .icon-next {
      color: $primary;
      margin: 0px 10px;
    }
  }
}

.report-desc {
  &__row {
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    border: 1px solid $line-gray;
  }

  &__title {
    display: inline-block;
    width: 200px;
    vertical-align: top;
    word-wrap: break-word;
    .required-indicator {
      color: $red;
      font-size: 16px;
      padding-left: 5px;
    }
  }

  &__content {
    display: inline-block;
    width: calc(100% - 220px);
    position: relative;

    .report-error-global-container {
      font-size: 11px;
      color: $error;
    }

    .datepicker-container {
      position: relative;

      .error {
        position: absolute;
        right: 5px;
        top: 4px;
        color: $error;
        font-size: 11px;
      }

      .datepicker-inner {
        position: relative;
        z-index: 99;
      }

      p {
        flex: 1;
        margin: 0;
      }

      input[type="text"] {
        width: 100%;
        z-index: -1;
      }
    }
  }

  &__header {
    display: block;
    width: 100%;
    color: $primary;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid $primary-lightest;
  }
}

.report-filter {
  margin-bottom: 20px;

  &.error-holder {
    margin-bottom: 5px;
  }
}
