/*Box and Dimensions*/
$max-width: 1450px;
$border-radius-base: 5px;

$header-height: 45px;
$footer-height: 30px;
$header-crumbs-w: 200px;
$header-utilities-height: 64px;
$header-utilities-action-width: 40px;
$grid-row-height: 20px;
$grid-row-height-default: 25px;
$grid-row-height-compact: 20px;
$grid-row-height-large: 30px;
$sidebar-width: 220px;
$grid-header-height: 36px;
$grid-row-height: 25px;
$sidebar-width-wide: 400px;
$sidebar-width-customer-chat: 500px;
$tab-w: 100px;
$event-desc-w: 300px;
$input-h: 24px;
$empty-cell-w: 25px;

$radius: 3px;

/*Font*/
$font-base: Helvetica, sans-serif;
$font-xlarge: 16px;
$font-large: 15px;
$font-medium: 13px;
$font-xmedium: 12px;
$font-small: 11px;
$font-xsmall: 8px;
$font-grid: 12px;

$fw-light: 100;
$fw-normal: 200;
$fw-semibold: 500;
$fw-bold: 600;
$fw-xbold: 800;
$input-h: 20px;
