.trans-manager-menu-container {
  width: 100%;
  margin-left: 0 !important;
  background-color: #172d41;
  align-items: baseline;
}

.trans-manager-menu-container .save-button,
.undo-button,
.search-button,
.add-button {
  padding: 0px 10px 0px 10px;
  margin: 3%;
}

.search-trans-manager {
  width: 71%;
  height: 26px !important;
}

.trans-manager-menu-container {
  .delete-button {
    padding: 0px 10px 0px 10px;
    //color:red;
    margin: 2%;
  }
  .delete-button {
    .disabled {
      color: gray;
    }
    .enabled {
      color: red;
    }
  }
  .sub-menu-container .menu-icons {
    width: 47px;
    background: #fff;
    border-radius: 5px;
    height: 28px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
  }

  .sub-menu-wrapper {
    margin-top: -13px;
    position: absolute;
  }

  .sub-menu-container {
    //z-index: 10;
    position: absolute;
    left: 0;
    color: black;
    width: 200px;
  }
  .menu-list {
    background-color: #ffffff;
    box-shadow: 1px 0px 8px 0px #888888;
    width: 110%;
    z-index: 99999;
    position: absolute;
  }
  .menu-list ul {
    height: 350px;
    overflow-y: auto;
  }
  .menu-list ul li {
    padding: 5px;
    cursor: pointer;
  }
  .menu-list ul li:hover {
    background-color: #edf2f7;
  }
  .phx-earth {
    color: #418aca;
  }
  .menu-list label[id="menu-list"] {
    margin-left: 2%;
    //position: absolute;
    cursor: pointer;
  }
  .menu-list input[type="checkbox"] {
    cursor: pointer;
  }
}
.modal-add-vocab {
  width: 35%;
  margin-top: 10px;
  height: 150px;
  .text-input-row {
    text-align: center;
    display: block;
    margin: 0px 1px;
  }
}

.add-baseVocab-input {
  width: 100%;
  margin-top: 5%;
  height: 30px !important;
  font-size: 15px !important;
}

.add-base-vocab-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3px 10px 10px 10px;
  background-color: white;
  //text-align: right;
}

.debug-window-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3px 10px 10px 10px;
  background-color: white;
  text-align: right;
}

.add-base-vocab-footer button[class="save"] {
  //margin-left: 2%;
  float: right;
}

.modal-debug-window {
  width: 59%;
  height: 80%;
  margin-top: 20px;
  .body-wrapper {
    padding: 10px;
  }
}

.modal-debug-window {
  .debug-step-1 {
    margin-top: 20px;
  }
  .debug-step-2 {
    margin-top: 20px;
  }
  .col-xs-12 {
    display: inline-flex;
    margin-top: 5px;
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }
}

.debug-window-footer {
  button[class="cancel"] {
    margin-left: 2%;
  }
  button[class="finish"] {
    margin-left: 2%;
  }
  button[class="next"] {
    margin-left: 2%;
  }
}

.debug-local-selectBox {
  width: 22%;
  margin-left: 2%;
}

.debug-step-2 {
  input[class="debug-result"] {
    color: black;
  }
}

.columns-xs {
  width: 100%;
}
.debug-message {
  .label {
    margin-top: -190px;
    padding-left: 6px;
  }
  .message {
    height: 200px;
    width: 100%;
  }
}
.delete-vocab-message {
  padding: 0 0 0 44px;
}
