#app-jackpot-bet-manager {
  input {
    border-radius: 0px;
  }

  select {
    border-radius: 0px;
  }

  .currency-text-box {
    width: 100px !important;
    margin-left: 5px;
    color: #000;
  }

  .align-items-normal {
    align-items: normal;
  }

  .page-container {
    margin-top: 20px;
  }

  .margin-top-10 {
    margin-top: 10px;
  }

  .margin-left-10 {
    margin-left: 10px;
  }

  .list-time {
    font-size: 10px;
  }

  #marketInformation {
    .phx-plus {
      font-size: 12px;
      background-color: #e2e2e2;
      color: #fff;
      border-radius: 15px;
      padding: 2px;
    }
    .phx-minus {
      font-size: 12px;
      background-color: #e2e2e2;
      color: #fff;
      border-radius: 15px;
      padding: 2px;
      margin-left: 2px;
    }
    .market-text-box-first {
      width: 100%;
      color: #000;
    }
    .market-text-box-second {
      width: 100%;
      color: #000;
    }
  }
}

.currency-right-container {
  width: 100%;
  height: 100%;
}

.jackpot-title {
  width: 100%;
  margin-top: 4px;
  height: 30px !important;
  text-align: center;
  font-size: 20px !important;
}

.add-Jackpot-currency-row .phxico {
  cursor: pointer;
}

.add-Jackpot-consolation-row .phxico {
  cursor: pointer;
}

.add-Jackpot-consolation-row .prize-field {
  display: inline-flex;
}

.add-Jackpot-consolation-row .prize-field input {
  width: 52%;
}

.add-Jackpot-currency-row {
  .stake-field {
    display: inline-flex;
  }
  .stake-field input {
    margin-left: -10%;
    width: 100%;
  }
  .prize-field {
    display: inline-flex;
  }
  .prize-field input {
    margin-left: -19%;
    width: 100%;
  }
}

.add-jackpot-market {
  padding: 2px;
}

.add-jackpot-market .phxico {
  cursor: pointer;
}

.add-jackpot-market .market-text-box-first {
  color: black !important;
  border-color: #636161 !important;
}
.add-jackpot-market .market-text-box-second {
  color: black !important;
  border-color: #636161 !important;
  width: 100%;
}

.modal-body {
  overflow-x: hidden;
  overflow-y: auto;
  // padding:10px 10px 40px 10px;
  position: initial;
}

.add-jackpot-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3px 10px 10px 10px;
  background-color: white;
  text-align: right;
}

.sportsTree-hierirechy {
  .event-creator-row {
    overflow: auto;
    max-height: 322px;
  }
  margin-left: 4%;
}

.align-items-normal {
  margin-top: 10px !important;
}

.rt-noData {
  z-index: 0 !important;
}

@media all and (min-width: 1920px) {
  .add-Jackpot-currency-row {
    .prize-field input {
      margin-left: 0px !important;
    }
  }
}
