#app-wager-limits {
  tbody tr {
    height: 40px;
  }
  .drop-down-inline {
    display: -webkit-inline-box;
  }
  .select-width {
    width: 190px;
    margin: 10px 0px 0px 5px;
  }
  .select-margin {
    margin-left: 150px;
  }
  .margin-top {
    margin-top: 0px;
  }
  .app-wager-limits-comp {
    background: #f2f2f2;
  }
  .no-footer {
    bottom: 0;
    height: 100% !important;
    border: none !important;
    left: 300px !important;
  }
  .wager-limits-sidebar {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #b5b5bd;
  }
  .stack-distributation-content {
    .section-1,
    .section-1 {
      margin-top: 10px;
    }
  }
  .invalid-limit-class input {
    border: 1px solid red;
  }
  tr td input {
    width: 100% !important;
  }
  tr td select {
    width: 100% !important;
  }
}

.wager-limits-section {
  border: 1px solid #b5b5bd;
  box-shadow: 0px 0px 3px 2px #ccc;
}
.component-breaker {
  margin: 0 0 20px 0px;
}
.first-sub-title {
  width: 44.2%;
}
.second-sub-tittle {
  width: 52%;
  border-left: 1px solid #cacaca;
  padding-left: 0.3%;
}
.align-subheader {
  margin-top: 5px;
  margin-bottom: -5px;
}
.wager-limits-section {
  .panel-header-content {
    .panel-header-title {
      font-size: 13px;
    }
    .panel-header-Sub-title {
      text-transform: capitalize !important;
      color: #80809b;
    }
  }
  .warning-icon {
    color: orange;
    font-weight: bold;
  }
  .critical-icon {
    color: red;
    font-weight: bold;
  }
}
.modal-wager-limit-color-picker {
  width: 16.5%;
  height: 80%;
  margin-top: 20px;
  min-width: 220px;
  max-height: 400px;
}
.modal-wager-limit-color-picker h4 {
  text-align: center !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
}
.wager-limit-color-picker-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3px 10px 10px 10px;
  background-color: white;
  text-align: right;
}
.wager-limit-color-picker-footer {
  button[class="close"] {
    margin-left: 2%;
  }
  button[class="ok"] {
    margin-left: 2%;
    float: left;
  }
}
.deletion-error-message {
  width: 34.5%;
  height: 80%;
  margin-top: 20px;
  min-width: 220px;
  max-height: 200px;
}
.deletion-error-message .message-content {
  margin: 20px;
}
.deletion-error-message-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3px 10px 10px 10px;
  background-color: white;
  text-align: right;
}
.deletion-error-message-footer {
  button[class="ok"] {
    margin-left: 2%;
  }
}
.WL-grey-italic {
  color: grey;
  font-style: italic;
}
.WL-bold-text {
  font-weight: bold;
}
#app-wager-limits {
  .sub-menu-wrapper1 {
    float: left;
    margin-right: 5px;
    .sub-menu-container {
      width: 50px;
      height: 40px;
      margin-top: 5px;
      position: relative;
      .menu-list {
        z-index: 10;
        position: relative;
        left: 0;
        color: black;
        width: 228px;
        ul {
          border: 1px solid #e1e1e1;
          border-radius: 5px;
          margin-top: 2px;
          li {
            width: 100%;
            height: auto;
            text-align: left;
            margin-right: 0px;
            margin-top: 0px;
            outline: none;
            cursor: pointer;
            padding: 10px 5px 5px 5px;
            border-bottom: 1px solid #e1e1e1;
            line-height: 13px;
            transition: all 0.3s ease;
            background: #fff;
            display: block;
            &.disabled {
              color: #aaa;
              cursor: default;
            }
            &.disabled:hover {
              background: white;
            }
            &:hover {
              background: darken(#fff, 10);
            }
            &:last-child {
              border-bottom: none;
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;
            }
          }
        }
      }
      .menu-icons {
        background: #fff;
        border-radius: 5px;
        height: 32px;
        cursor: pointer;
        transition: all 0.3s ease;
        outline: none;
        &:hover {
          background: rgba(255, 255, 255, 0.75);
        }
        i {
          height: 17px;
          width: 17px;
          &:first-child {
            &:before {
              margin-left: -2px;
            }
          }
          &.reverse-background {
            background: #3c70a0;
            color: #fff !important;
            padding: 2px;
            border-radius: 3px;
            margin-left: 5px;
          }
          &:nth-child(2) {
            margin-left: 2px;
            font-size: 15px;
            color: #000 !important;
          }
        }
      }
    }
  }
}
.color-picker-container {
  .sketch-picker {
    width: auto !important;
  }
}
