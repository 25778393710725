.multi-select-list {
  border: 1px solid rgba(0, 0, 0, 0.1);

  .multi-select-list-title-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: $primary-lightest;
    color: $primary;

    &.-disabled {
      background-color: $gray-lightest;
    }
  }

  .multi-select-list-title-text {
    font-size: 15px;
    font-weight: 300;
  }

  i.multi-select-list-title-icon {
    font-size: 20px;
  }
}
