@font-face {
  font-family: "phxicons";
  src: url("./fonts/phxicons.eot");
  src: url("./fonts/phxicons.eot") format("embedded-opentype"),
    url("./fonts/phxicons.ttf") format("truetype"),
    url("./fonts/phxicons.woff") format("woff"),
    url("./fonts/phxicons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
