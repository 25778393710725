#app-affiliate-manager {
  .pt-10 {
    padding-top: 10px;
  }
  background: #f2f2f2;
  header {
    overflow: initial;
  }
  .module-header {
    height: auto;
    background-color: #274c6e;
    display: block;
    position: relative;
    padding: 5px 10px;
    color: #fff;
    .row {
      .col-xs-12 {
        margin-top: 3px;
        i {
          margin-top: -2px;
        }
      }
    }
    .header-title {
      display: inline-block;
      padding: 5px;
      i {
        font-size: 18px;
        color: #ffffff;
      }
    }
    .header-title.no-data {
      color: #ff4848;
    }
    .status-filter {
      display: block;
      padding-right: 10px;
      float: right;
      select {
        margin: 0;
        float: left;
        width: 100px;
        margin-right: 10px;
      }
      .search-input-wrapper {
        display: flex;
        float: right;
        .search-field-container {
          background: white;
          border-radius: 4px;
          input {
            height: 25px;
            outline: none;
            border: none;
            width: 87%;
          }
          .close-button {
            background: rgba(0, 0, 0, 0.5);
            color: rgba(255, 255, 255, 0.5);
            height: 19px;
            line-height: 15px;
            width: 20px;
            margin-top: 3px;
            margin-right: 4px;
            position: absolute;
            right: 23px;
            z-index: 4;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
            border-radius: 50%;
            transition: all 0.3s ease;
            padding: 4px 2px 2px 2px;
            i {
              color: #ffffff;
              width: 10%;
            }
          }
        }
        .search {
          margin-top: 7px;
        }
      }
    }
  }
  .page-main {
    left: 250px;
    background-color: #edf2f7;
    border: none;
    .page-title {
      display: flex;
      background-color: $primary-darker;
      height: 40px;
      padding-left: 10px;
      vertical-align: middle;
      color: #fff;
      font-size: 12px;
      line-height: 2;
      font-weight: 800;
      font-family: sans-serif;
      line-height: 40px;
      font-weight: 400;
      .option {
        margin-right: 20px;
        button {
          outline: none;
          > i {
            font-size: 14px;
          }
        }
      }
      input {
        outline: none;
        border: transparent;
        background: transparent;
        color: white;
        flex: 2 1 0%;
        margin-top: 10px;
      }
    }
    .panel-header-title {
      text-transform: none;
      font-weight: 100;
    }
    pre {
      font-family: sans-serif;
      line-height: 17px;
      white-space: pre-wrap;
      word-wrap: break-word;
      margin: 0;
    }
    .revenue-withdrawals {
      display: block;
    }
  }
  .panel-header {
    display: flex;
    height: auto !important;
    .panel-header-title {
      text-transform: none;
      font-weight: 100;
      flex: 1;
    }
    .add-option {
      width: 20px;
      height: 20px;
      border: 1px solid #7fb4e2;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3px;
      margin-right: 5px;
      &.active {
        cursor: pointer;
        border: 1px solid #6fba65;
        color: #6fba65;
        &:active {
          background: #6fba65;
          color: #fff;
        }
      }
      &.disabled {
        border: 1px solid #6fba65;
        color: #6fba65;
        background: #e2e2e2;
      }
    }
  }
  .sub-menu-wrapper {
    float: left;
    margin-right: 1.5%;
    .sub-menu-container {
      // display: flex;
      width: 50px;
      height: 40px;
      margin-top: 5px; // justify-content: center;
      // align-items: center;
      position: relative;
      .menu-list {
        box-shadow: -1px 2px 10px #888888;
        z-index: 10;
        position: absolute;
        left: 0;
        color: black;
        width: 200px;
        ul {
          border: 1px solid #e1e1e1;
          border-radius: 5px;
          margin-top: 2px;
          li {
            width: 100%;
            text-align: left;
            margin-top: 0px !important;
            outline: none;
            cursor: pointer;
            padding: 9px;
            border-bottom: 1px solid #e1e1e1;
            line-height: 13px;
            transition: all 0.3s ease;
            background: #fff;
            display: block;
            &.disabled {
              color: #aaa;
              cursor: default;
            }
            &:hover {
              background: darken(#fff, 10);
            }
            &:last-child {
              border-bottom: none;
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;
            }
          }
        }
      }
      .menu-icons {
        background: #fff;
        border-radius: 5px;
        height: 32px;
        cursor: pointer;
        transition: all 0.3s ease;
        outline: none;
        &:hover {
          background: rgba(255, 255, 255, 0.75);
        }
        i {
          height: 17px;
          width: 17px;
          &:first-child {
            &:before {
              margin-left: -2px;
            }
          }
          &.reverse-background {
            background: #3c70a0;
            color: #fff !important;
            padding: 2px;
            border-radius: 3px;
            margin-left: 5px;
          }
          &:nth-child(2) {
            margin-left: 2px;
            font-size: 15px;
            color: #000 !important;
          }
        }
      }
    }
  }
  section.custom {
    min-width: 350px;
  }
  .affiliate-manager-sidebar {
    @extend .event-creator-sidebar;
    .sidebar-body {
      padding: 0;
      .sidebar__items {
        max-height: none;
        .nav-item {
          border: none !important;
          border-top: none !important;
          .margin-Left {
            margin-left: 6%;
          }
          .nav-title-dot {
            color: #ff690a;
            font-size: 40px;
            padding-bottom: 20px;
          }
          .nav-child-dot {
            color: #ff690a;
            font-size: 40px;
            margin-right: -10px;
            padding-bottom: 10px;
          }
          .nav-item-sub {
            ul {
              li {
                .item-group {
                  padding-left: 37px;
                }
              }
            }
          }
        }
      }
    }
    width: 250px;
    .path-icon {
      color: #ffca28;
    }
    .path-icon--settings {
      color: $primary;
    }
    .path-anchor {
      &.disabled {
        pointer-events: none;
        color: #ccc;
      }
    }
    .path-container {
      &.level-1:first-child {
        border-top: none;
      }
    }
    .active-bold {
      font-weight: 700;
    }
    .side-bar-menu-left-icons {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
    }
  } //dashboard page==================>
  .dashboard {
    .dashboard-details-container {
      margin-top: 5px;
      padding-right: 0px;
      display: flex;
      .padding-medium {
        height: auto;
        .revenues-item-box {
          height: 100px;
          width: 25%;
          float: left;
          margin-bottom: 5px;
          margin-top: 5px;
          .revenues-box {
            cursor: pointer;
            text-align: center;
            border: 1px solid #0ed0f7;
            border-radius: 15px;
            width: 100%;
            height: 100% !important;
            margin: 0 auto;
            padding-top: 27px;
            .name {
              font-weight: 900;
            }
            .value {
              font-weight: bold;
              font-size: x-large;
              margin-top: 8px;
            }
            .gross-revenue {
              color: #0ed0f7;
            }
            .total-cost {
              color: #f79b45;
            }
            .net-revenue {
              color: #5dcc3f;
            }
            .affiliate-share {
              color: #7c4fdd;
            }
          }
          .revenues-box:hover {
            box-shadow: 0 0 2px 2px #d4d4d4;
          }
        }
        .withdrawals-item-box {
          height: 100px;
          margin-bottom: 5px;
          margin-top: 5px;
          .withdrawals-box {
            text-align: center;
            border: 1px solid #0ed0f7;
            border-radius: 15px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            cursor: pointer;
            padding-top: 18px;
            .name {
              font-weight: 900;
            }
            .count {
              font-weight: bold;
              font-size: x-large;
              margin-top: 4px;
            }
            .pending {
              color: #f40e72;
            }
            .approved {
              color: #0ed0f7;
            }
            .rejected {
              color: #f79b45;
            }
            .total {
              margin-top: 8px;
              color: #7c4fdd;
              font-size: 12px;
            }
          }
          .withdrawals-box:hover {
            box-shadow: 0 0 2px 2px #d4d4d4;
          }
        }

        .affiliates-item-box {
          height: 100px;
          margin-bottom: 5px;
          margin-top: 5px;
          width: 25%;
          float: left;
          .affiliates-box {
            text-align: center;
            border: 1px solid #0ed0f7;
            border-radius: 15px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            cursor: pointer;
            padding-top: 26px;
            .name {
              font-weight: 900;
            }
            .value {
              font-weight: bold;
              font-size: x-large;
              margin-top: 10px;
            }
            .pending {
              color: #f40e72;
            }
            .active {
              color: #5dcc3f;
            }
            .rejected {
              color: #f79b45;
            }
            .closed {
              color: #040505;
            }
          }
          .affiliates-box:hover {
            box-shadow: 0 0 2px 2px #d4d4d4;
          }
        }
      }
    }
    .description {
      overflow: visible;
      &.left {
        margin-right: 10px;
        width: 100%;
      }
      &.right {
        flex: 1;
      }
    }
  }
  // Commission Plans page ==================>
  .commission-plans {
    .commission-plans-container {
      .name-input {
        width: 67%;
        float: right;
        input {
          width: 100%;
        }
        .validation-error {
          float: right;
          font-style: italic;
          color: red;
          font-size: 10px;
        }
        .no-validation-error {
          display: none;
        }
      }
      .set-default {
        display: inline-flex;
      }
      .margin-top {
        width: 100%;
        margin-top: 22px;
        .first {
          margin-bottom: 10px;
          display: inline-block;
          margin-top: -10px;
          float: left;
          width: 50%;
          padding-right: 10px;
          label {
            margin-right: 10px;
          }
          .description-input {
            width: 67%;
            float: right;
            textarea {
              width: 100%;
              height: 92px;
              resize: none;
            }
            .validation-error {
              float: right;
              font-style: italic;
              color: red;
              font-size: 10px;
            }
            .no-validation-error {
              display: none;
            }
          }
        }
        .second {
          display: inline-block;
          width: 50%;
          float: right;
          margin-top: -9px;
          padding-left: 10px;
          .status {
            display: inline-block;
            width: 100%;
            .status-input {
              width: 74%;
              float: right;
              select {
                width: 100%;
                margin: 0px;
              }
              .validation-error {
                float: right;
                font-style: italic;
                color: red;
                font-size: 10px;
              }
              .no-validation-error {
                display: none;
              }
            }
          }
          .type {
            display: inline-block;
            width: 100%;
            margin-top: 8px;
            .type-input {
              width: 74%;
              float: right;
              select {
                width: 100%;
                margin: 0px;
              }
              .validation-error {
                float: right;
                font-style: italic;
                color: red;
                font-size: 10px;
              }
              .no-validation-error {
                display: none;
              }
            }
          }
          .brand {
            display: inline-block;
            width: 100%;
            margin-top: 8px;
            .brand-input {
              width: 74%;
              float: right;
              select {
                width: 100%;
                margin: 0px;
              }
              .validation-error {
                float: right;
                font-style: italic;
                color: red;
                font-size: 10px;
              }
              .no-validation-error {
                display: none;
              }
            }
          }
        }
      } //Commission plan >> cost form
      .cost-form {
        .description-wrapper {
          padding: 5px 5px 5px 0px;
          color: #a9adb2;
        }
        .ReactTable {
          .rt-thead.-header {
            .rt-tr {
              background: #3e7ecb;
              color: #ffffff;
            }
          }
        }
        .cost-action-button {
          color: #00c7ff;
          font-size: 15px;
          margin-top: 5px;
          text-align: center;
          position: relative;
          display: block;
          i {
            cursor: pointer;
          }
          .phx-delete.disabled {
            color: #e1e1e1;
            cursor: not-allowed;
          }
        }
        .select-currency {
          width: 100%;
          margin-top: 0px;
          height: 100%;
          padding-top: 0px;
          cursor: pointer;
        }
        .add-more-cost {
          width: 100%;
          background-color: #06a816;
          color: #ffffff;
        }
        .rt-noData {
          display: none;
        }
      } //Commission plan >> Product form
      .product-form {
        .description-wrapper {
          padding: 5px 5px 5px 0px;
          color: #a9adb2;
        }
        .ReactTable {
          .rt-thead.-header {
            .rt-tr {
              background: #3e7ecb;
              color: #ffffff;
            }
          }
        }
        .product-container.margin-bottom {
          margin-bottom: 13px;
        }
        .product-container {
          .first-row {
            width: 100%; //background-color:gray;
            padding: 7px 0px 5px 0px;
            display: inline-flex;
            label[class="product-name"] {
              float: left;
              width: 60%;
              padding-top: 9px;
              font-weight: 600;
              color: blue;
            }
            span {
              display: inline-flex;
              float: right;
              width: 40%;
              label {
                float: left;
                width: 30%;
                padding-top: 9px;
                margin-right: 0px;
              }
              select {
                float: right;
                width: 70%;
              }
            }
          }
          .second-row {
            width: 100%;
            height: 10px;
            display: inline-block;
            .product-action-button {
              color: #00c7ff;
              font-size: 15px;
              margin-top: 5px;
              text-align: center;
              position: relative;
              display: block;
              .phx-enabled {
                cursor: pointer;
              }
              .phx-disabled {
                color: #e1e1e1;
                cursor: not-allowed;
              }
            }
            .add-more-range {
              width: 100%;
            }
            .add-more-range.enable {
              background-color: #06a816;
              color: #ffffff;
            }
            .add-more-range.disable {
              background-color: #e1e1e1;
              color: #000000;
            }
          }
        }
        .rt-noData {
          display: none;
        }
      }
      .cs-flex {
        padding: 15px !important;
      }
      .cs-flex {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 35px;
        &.no-space {
          padding: 0;
        }
      }
      .cs-box {
        &__container {
          // margin: 10px;
          margin-bottom: 10px;
          margin-right: 10px;
          border: 1px solid #bfbfbf;
          width: 220px; // height: 90px;
          background: #fff;
          &.small-box {
            .content {
              height: 45px;
              overflow: hidden;
            }
          }
          &.medium-box {
            height: 130px;
            .content {
              height: 80px;
              overflow: hidden;
            }
          }
          &.large-box {
            height: 220px;
            max-height: 220px;
            box-shadow: 0 0 2px 2px #d4d4d4;
            border-radius: 3px;
            cursor: pointer;
            .content {
              //height: 130px;
              height: 170px;
              overflow: hidden;
              .body {
                height: 100%;
                float: left;
                width: 90%;
              }
              .icon-container {
                height: 100%;
                float: right;
                width: 10%;
                span {
                  position: absolute; //bottom: 43px;
                  padding-top: 126px;
                  font-size: 20px;
                  color: #418aca;
                }
              }
            }
          }
          .header {
            display: flex;
            padding: 0 15px;
            border-bottom: 1px solid #e1e1e1;
            height: 48px;
            align-items: center;
            .icon {
              width: 35px;
              height: 20px;
              margin-left: -5px;
              i {
                font-size: 18px;
                &.retired {
                  color: #818181;
                }
              }
              > .green,
              > .success {
                color: #81f881;
              }
              > .orange,
              > .warning {
                color: #f3a15e;
              }
              > .red,
              > .error {
                color: #f56565;
              }
              > .off {
                color: #d6d6d6;
              }
              > .promo-active {
                color: #6fba65;
              }
              > .default {
                color: #418aca;
              }
            }
            .title {
              height: 38px;
              display: flex;
              align-items: center;
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              h1 {
                width: 100%;
                font-size: 12px;
                flex: 1;
                margin: 0;
                letter-spacing: 0.4px;
                font-weight: 600;
                word-wrap: break-word;
                white-space: pre-wrap;
              }
            }
          }
          .content {
            padding: 20px 10px 10px;
            font-family: sans-serif;
            height: 140px;
            overflow: hidden;
            &.small {
              padding: 5px 10px;
            }
            pre {
              font-size: 12px;
              margin: 0;
              font-family: inherit;
              font-weight: 300;
              line-height: 1.3em;
              word-wrap: break-word;
              white-space: pre-wrap;
              .content-row {
                .title {
                  font-weight: 600;
                }
                .value {
                  margin-left: 6%;
                  font-weight: 100;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Commission Plans page ==================>
.edit-affiliate-container {
  .p-0 {
    padding: 0px;
  }
  .m {
    margin: 5px 0px;
  }
  label {
    margin: 0px;
    font-weight: 600;
  }
  th {
    background: #418aca;
    color: #fff;
  }
  td {
    text-align: center;
  }
  input {
    width: 100% !important;
  }
  input[type="text"][disabled] {
    color: #170707;
  }
  select {
    height: 20px;
    width: 100% !important;
    padding: 0px;
  }
  .add-sms-content {
    background: #06b34e;
    color: #fff;
    font-size: 12px;
    padding: 4px 0px;
    text-align: center;
    cursor: pointer;
  }
  .primary-button {
    height: 24px;
    border-radius: 15px;
    border: 0px;
    padding: 0px 50px;
    background: #418aca;
    box-shadow: none;
    color: #fff;
  }
}

.affiliate-container {
  .cell-data {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ReactTable {
    .rt-table {
      //max-height: 87vh !important;
      overflow-y: scroll !important;
      display: -webkit-box !important;
    }
  }
}

.validation-error {
  float: right;
  font-style: italic;
  color: red;
  font-size: 10px;
}
.no-validation-error {
  display: none;
}

.landing-pages-modal-window-main-div {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  .confirmModalHeadingExtra {
    margin-top: 20px;
  }
}
.revenue-withdrawals-modal-window-main-div {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  .confirmModalHeadingExtra {
    margin-top: 20px;
  }
}

#landing-pages {
  .button-add-landing-page {
    width: 100%;
  }
  .button-add-landing-page.enable {
    background-color: #06a816;
    color: #ffffff;
  }
  .button-add-landing-page.disable {
    background-color: #e1e1e1;
    color: #000000;
  }
  .delete-button {
    color: #00c7ff;
    font-size: 15px;
    margin-top: 5%;
    text-align: center;
    position: relative;
    display: block;
    .phx-delete {
      cursor: pointer;
    }
  }
  .default-set-button {
    text-align: center;
    display: block;
    padding-top: 4px;
  }
  .landing-page-name {
    text-align: center;
  }
  .landing-page-url {
    text-align: center;
  }
  .landing-page-brand {
    height: 20px;
    margin: 0px;
    padding: 0px;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
  }
}

#affiliate-manager-report {
  .header {
    display: block;
    position: relative;
    padding: 5px 10px;
    background: #172d41;
    color: #fff;
    .withdrawls-status {
      display: inline-block;
      padding: 5px;
    }
  }
  .dates-row {
    height: 33px !important;
    input {
      height: 25px;
      padding: 10px;
    }
  }
  .report-footer {
    position: fixed;
    bottom: 0px;
    right: 0px;
    height: 50px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e2e2e2;
    padding: 10px;
  }
  .reports-format-title {
    margin-right: 20px;
    text-transform: uppercase;
    font-size: 12px;
  }
  .reports-format-btn {
    float: right;
  }
  .reports-footer-inner {
    padding-top: 5px;
    float: left;
  }
  .report-desc__row {
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    border: 1px solid #e2e2e2;
    input {
      width: 100%;
      height: 23px;
    }
  }
  .report-desc__header {
    display: block;
    width: 100%;
    color: #418aca;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebf5ff;
  }
}
