/*Styles applicable to specific tool only that doesnt belong to any modules*/

/*START: RISK MANAGER*/
.edit-market-container {
  position: relative;
  .error-container {
    .error {
      display: inline-block;
      padding: 10px 25px;
      font-weight: bold;
      background-color: $color-suspended;
      border-radius: $border-radius-base;
    }
  }
  th {
  }
  .select-market {
    select {
      margin-left: 10px;
      font-size: $font-medium;
      width: 250px;
    }
    .market-line {
      display: inline-block;
      ul {
        cursor: pointer;
        li {
          display: inline-block;
          padding: 3px 10px 4px;
          &.active {
            border-radius: 5px;
            // border: 1px solid rgba(200, 200, 200, 0.8);
            // border-bottom: 3px solid #cccccc;
            background: #418aca;
            color: #fff;
          }
        }
      }
    }
  }

  .price-and-wager-limits {
    > div.clearfix {
      margin-bottom: 5px;
    }
    button {
      margin: 10px;
      @include btn();
    }
  }

  > section,
  > div {
    margin-bottom: 10px;
  }

  .loading-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
  }
  &.line-12 {
    .price-input {
      font-style: italic;
    }
  }
}

.column-data {
  .liabilityIndicator.red {
    font-weight: bold;
    color: $red;
  }
  .liabilityIndicator.orange {
    font-weight: bold;
    color: $orange;
  }
}

#app-risk,
#app {

  .liability-1 {
    color: red;
  }

  .liability-2 {
    color: orange;
  }
  .market-actions {
    .button {
      a {
        color: $primary;
      }
      .disabled {
        color: $disabled;
      }
    }
  }
  .ReactModal__Content {
    &[aria-label="Outcome Wager Limits"] {
      .button-group {
        margin-bottom: 10px;
        margin-top: 10px;
        height: $input-h;
        .btn-grp-child {
          width: auto;
          float: left;
          padding: 6px 10px;
          a {
            text-decoration: none;
            color: $black;
            &.bold {
              color: $primary;
            }
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }

  .fleft.half {
    width: calc(50% - 5px);
    margin-right: 5px;
  }

  .fright.half {
    width: calc(50% - 5px);
    margin-left: 5px;
  }

  .ReactVirtualized__Grid {
    .row,
    .column {
      &.updated {
        @include cell-alert();
        position: relative;
        z-index: 2;
        .column-data,
        .column.market {
          position: relative;
          z-index: 5;
        }
        .column.market {
          top: 0px;
        }
      }
      &.column-actions {
        text-align: center;
      }
      .phxico,
      .phx-custom {
        font-size: $font-medium;
        &.phx-eye {
          color: $primary;
        }
        &.phx-eye-off {
          color: $red;
        }
        &.phx-trophy {
          color: $orange;
        }
        &.phx-sad {
          color: $dirt-yellow;
        }
        &.phx-flag {
          color: $purple-light;
        }
        &.phx-rss {
          color: $orange;
        }
        &.icon-numeric {
          background-color: $yellow;
          padding: 1px 3px;
          border-radius: 3px;
          color: $white;
          font-weight: 100;
          font-style: normal;
          font-size: $font-small;
        }
      }
    }
  }
}

//modals of RSK
.search-criteria {
  .analysis-container & {
    margin-bottom: 10px;
    background: $gray-lightest;
    border-radius: $border-radius-base;
  }
  .field-container {
    display: inline-block;
    margin-right: 10px;
  }
  p {
    margin: 0;
  }
}
.analysis-container {
  .main-inner {
    padding-top: 10px;
  }
}

.opponents-page {
  height: calc(100% - 31px);
  width: 100%;
  .form-wrapper {
    margin-bottom: 0;
  }
  .opponents-container,
  .players-container {
    height: calc(50% - 24px);
    position: relative;
    .loading-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $white75;
      z-index: 9;
    }
  }
  .divider {
    border: 1px solid transparent;
    margin: 0;
  }
  .opponents-table {
    height: calc(100% - 38px);
  }
  .-pagination {
    background: $white;
  }
  .ReactTable {
    background: $white75;
    height: 100%;
  }
}
.header.panel-header {
  height: 34px;
  box-sizing: border-box;
  background: $primary-lightest;
  padding: 4px;
  .btn-box {
    box-shadow: none;
    width: 26px;
    height: 26px;
    line-height: 26px;
    &.disabled {
      color: rgba(0, 0, 0, 0.25);
      border: 1px solid rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }
  .panel-header-actions {
    float: right;
    text-align: right;
  }
  .panel-header-title {
    float: left;
    line-height: 26px;
    color: $primary-dark;
    font-size: 1.25em;
    font-weight: $fw-xbold;
    padding: 0 5px;
    select {
      height: 26px;
    }
    .ReactModalPortal & {
      font-size: 16px;
      font-weight: normal;
    }
  }
}
.add-edit-opponent,
.add-edit-player,
.add-edit-kit {
  .form-new-player-team,
  .form-new-kit {
    padding: 0;
    .form-wrapper {
      padding: 5px 0;
    }
  }
  .opponents-kit {
    .panel-header {
      font-size: 1em;
    }
  }
  .form-group-control {
    input,
    select {
      height: 25px;
      width: 100%;
    }
    select {
      margin: 0;
    }
    &.color-picker {
      > div > div:first-child {
        width: 100%;
        > div {
          width: 100% !important;
        }
      }
    }
  }
  .form-group-label {
    line-height: 26px;
  }
  .form-group {
    padding: 2px 10px;
  }
}

.edit-market-container {
  position: relative;
  table > thead > tr {
    background: $gray-lightest;
  }
  label > select {
    margin-left: 10px;
    font-size: $font-medium;
  }

  .price-and-wager-limits {
    > div.clearfix {
      margin-bottom: 10px;
    }
    button {
      margin: 10px;
      @include btn();
    }
  }
  .wager-table-container {
    table {
      a {
        text-decoration: none;
      }
      input[type="text"] {
        height: 20px;
        line-height: 20px;
      }
      .Margin[disabled] {
        background-color: #fff;
        color: black;
        border: none;
      }
    }
  }
  .edit-market-price {
    position: relative;
    overflow: visible;
    .rc-tooltip-inner {
      padding: 5px 10px;
      min-height: 0;
      background-color: $yellow;
    }
    .rc-tooltip-placement-right {
      .rc-tooltip-arrow {
        border-right-color: $yellow;
      }
    }
    .rc-tooltip.rc-tooltip-placement-right {
      top: 0;
      left: 100%;
      opacity: 1;
    }
  }

  > section,
  > div {
    margin-bottom: 5px;
    &.select-market {
      margin-bottom: 15px;
    }
  }

  .loading-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
  }
  .market-line-config-table-wrapper {
    .block-input {
      margin-bottom: 0px;
    }
  }
  .market-details {
    .form-field.tleft {
      padding-left: 10px;
    }
  }
}

//analysis

.page-analysis {
  .header-utilities-container {
    height: 34px;
    border-bottom: 1px solid $line-gray;
    .filters-container {
      margin-bottom: 0px;
    }
  }
  .search-criteria {
    margin-top: 10px;
    padding: 5px;
    .field-group {
      margin-bottom: 5px;
    }
    .group-name {
      @include h3();
      margin: 0 0 5px 0;
    }
  }
  .util-button-container {
    margin-top: 10px;
  }
}

// user preferences
$user-preferences-title-height: 28px;
$user-preferences-footer-height: 45px;
.page-user-preferences {
  height: 100%;
  .title {
    padding: 0;
    line-height: $user-preferences-title-height;
  }
  .content {
    position: relative;
    height: calc(100% - #{$user-preferences-title-height} - #{$user-preferences-footer-height});
    .saving-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .tabs-container,
  .react-tabs {
    height: 100%;
  }
  .react-tabs__tab-list {
    position: relative;
  }
  .react-tabs__tab-panel {
    height: calc(100% - 20px);
    overflow: auto;
  }
  .bottom {
    height: $user-preferences-footer-height;
    margin: 0;
    padding: 10px 0;
    box-sizing: border-box;
  }
  .sports-container,
  .columns-container {
    li {
      @extend .half;
      @extend .fleft;
      padding: 3px 0;
    }
    label {
      display: block;
      margin-right: 0;
    }
  }
}

.custom-range-picker {
  height: 100%;
  .title {
    padding: 0 10px;
    line-height: $user-preferences-title-height;
  }
  .content {
    position: relative;
    height: calc(100% - #{$user-preferences-title-height} - #{$user-preferences-footer-height});
  }
  .bottom {
    height: $user-preferences-footer-height;
    margin: 0;
    padding: 10px 0;
  }
  .daterange-picker-from,
  .daterange-picker-to {
    width: 50%;
    text-align: center;
  }
  .DayPicker {
    outline: none;
  }
}

.markets-connected,
.lines-connected {
  .list-box-container {
    padding: 10px;
    background-color: $gray-lighter;
    border-radius: $border-radius-base;
  }
}

.risk-manager-sidebar {
  .path-container.level-0 {
    > .path-anchor {
      pointer-events: none;
      &.active {
        font-weight: normal;
        color: #000;
      }
    }
  }
  width: 280px;
  & + .page-main {
    left: 280px;
    & + .page-footer {
      left: 280px;
    }
  }
}
.type-path {
  word-break: break-all;
}

.risk-manager-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  .sidebar-header {
    .dropdown {
      .dropbtn {
        &.disabled {
          pointer-events: none;
          background: #eee;
          color: #333;
        }
      }
      &.disabled {
        pointer-events: none;
        background: #eee;
        color: #333;
      }
    }
  }
}

#app-risk {
  .page-w100 {
    height: 100%;
  }
}
.control-buttons {
  .enabled > .pm {
    color: #418aca !important;
    font-weight: bold;
    margin: 0px;
    font-size: 17px;
  }
  .disabled > .pm {
    color: #a2c4e4 !important;
    font-weight: bold;
    margin: 0px;
    font-size: 17px;
  }
}
/*END: RISK MANAGER*/
