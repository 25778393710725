#app-bonus-manager {
  background: #f2f2f2;
  header {
    overflow: initial;
  }
  .page-main {
    left: 250px;
    background-color: #edf2f7;
    .page-title {
      display: flex;
      background-color: $primary-darker;
      height: 40px;
      padding-left: 10px;
      vertical-align: middle;
      color: #fff;
      font-size: 12px;
      line-height: 2;
      font-weight: 800;
      font-family: sans-serif;
      line-height: 40px;
      font-weight: 400;
      .brand-name-id {
        position: fixed;
        right: 10px;
        padding-top: 4px;
        span {
          display: block;
          padding: 4px;
          line-height: 10px;
        }
      }
      .title {
        width: 100%;
      }
      .option {
        margin-right: 20px;
        position: fixed;
        right: 0px;
        button {
          outline: none;

          > i {
            font-size: 14px;
          }
        }
      }
      input {
        outline: none;
        border: transparent;
        background: transparent;
        color: white;
        flex: 0.06 1 0%;
        margin-top: 14px;
        width: 100%;
      }
    }
    .panel-header-title {
      text-transform: none;
      font-weight: 100;
    }
    pre {
      font-family: sans-serif;
      line-height: 17px;
      white-space: pre-wrap;
      word-wrap: break-word;
      margin: 0;
    }
    .promotional-groups {
      select.brand-id {
        width: 200px;
      }
      .page-title {
        .brand-name-id {
          right: 340px;
        }
      }
    }
    .message-plans {
      .message-plans-edit-page {
        select.brand-id {
          width: 200px;
        }
      }
    }
    .terms-and-conditions {
      .terms-container {
        select.brand-id {
          width: 200px;
        }
      }
    }
  }
  .panel-header {
    display: flex;
    .panel-header-title {
      text-transform: none;
      font-weight: 100;
      flex: 1;
    }
    .add-option {
      width: 20px;
      height: 20px;
      border: 1px solid #7fb4e2;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3px;
      margin-right: 5px;

      &.active {
        cursor: pointer;
        border: 1px solid #6fba65;
        color: #6fba65;
        &:active {
          background: #6fba65;
          color: #fff;
        }
      }

      &.disabled {
        border: 1px solid #6fba65;
        color: #6fba65;
        background: #e2e2e2;
      }
      &:active {
        background-color: #6fba65;
        color: #ffffff;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .campaign {
    .description {
      overflow: visible;
      textarea {
        resize: none;
        width: 100%;
        height: 100px;
      }
      input[type="text"] {
        width: 75px;
        margin-right: 5px;
        text-align: center;
        outline: none;
      }
      &.left {
        margin-right: 5px;
        width: 200px;
      }
      &.right {
        flex: 1;
      }
    }
    .react-tabs {
      width: 100%;
      margin-bottom: 20px;
    }
    .react-tabs .react-tabs__tab-panel--selected {
      height: 100%;
      background-color: #fff;
    }
    h4,
    h2,
    h3 p {
      margin: 0;
    }
    p {
      margin-left: 10px;
    }
    .dateTimeContainer {
      display: flex;
      margin-bottom: 5px;
      .datepicker-container {
        flex: 0 !important;
        p {
          display: inline-flex;
          margin-top: 0;
          margin-bottom: 0;
          label {
            width: 20px;
          }
        }
        + input {
          width: 48px !important;
        }
      }
    }
    .brand-selection {
      width: 100%;
      select {
        width: 70%;
      }
    }
    .campaign-details-container {
      display: flex;
    }
    .promotions-list,
    .message-plans-list {
      display: flex;
      overflow-x: auto;
      margin-top: 10px;
      .promotion,
      .message-plan {
        .dateTimeContainer {
          input[type="text"] {
            width: 75px;
            margin-right: 5px;
          }
          p {
            margin-left: 0;
          }
        }
        .addButton {
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 75px;
          width: 150px;
          height: 150px;
          font-size: 100px;
          border: 10px solid;
          border-color: rgba(102, 102, 102, 0.4);
          margin-bottom: 30px;
        }
        .promotion-form {
          width: 260px;
        }
        &.message {
          background: #7dbcff;
          border: 1px solid #0d8aff;
        }
        &.paused {
          background: #f6c27a;
          border: 1px solid #ec9f40;
        }
        background: #76ab8c;
        border: 1px solid #3a7f5a;
        margin-bottom: 10px;
        height: auto;
        margin-right: 10px;
        border-radius: 2px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        min-width: 280px;
        width: 280px;
        h3 {
          padding: 5px;
          margin: 0;
          margin-bottom: 10px;
        }
        .icon {
          font-weight: 800;
          margin-right: 5px;
        }
        .form-group {
          display: flex;
          padding: 2px 0;
          align-items: center;
        }
        .form-group-control {
          width: calc(100% - 120px);
          font-weight: 400;
        }
        .form-group-label {
          width: 120px;
          font-weight: 600;
        }
        .promo-icons {
          a {
            color: black !important;
          }
          button {
            width: 38px !important;
            height: 30px !important;
            line-height: 0px !important;
            min-width: 30px !important;
            transition: all 0.3s ease;
            border: 1px solid rgba(255, 255, 255, 0.25);
            background: #fff;
            &:hover {
              background-color: rgba(255, 255, 255, 0.75);
            }
            i {
              font-size: 18px;
              color: #599edb !important;
            }
          }
          font-size: 18px;
          margin-top: auto;
          display: flex;
          justify-content: space-between;
        }
        .popup {
          position: absolute;
          top: 35px;
          right: -35px;
          padding: 10px;
          height: 70px;
          z-index: 999;
          background-color: white;
          flex-direction: column;
          align-items: center;
          border-radius: 5px;
          box-shadow: gray 0px 0px 15px 2px;
          ul {
            display: flex;
            div {
              width: 25px;
              height: 25px;
              line-height: 25px;
              margin-bottom: 5px;
            }
            .selected {
              background: #599edb;
              color: #fff;
            }
          }
          a {
            color: black;
            margin-top: auto;
          }
        }
      }
    }
  }
  .box-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    .box {
      width: 200px;
      height: 200px;
      margin: 4px;
      box-shadow: 0px 0px 12px grey;
      padding: 10px 0;
      color: black;
      border-radius: 3px;
      outline: none;
      .header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(57, 57, 57, 0.2);
        max-height: 45px;
        padding-bottom: 5px;
        i {
          font-size: 26px;
          margin-right: 6px;
          margin-left: 6px;
          &.retired {
            color: gray;
          }
        }
        h3 {
          margin: 0;
        }
      }
      p {
        font-family: sans-serif;
        line-height: 15px;
        white-space: pre-wrap;
        word-wrap: break-word;
        margin-left: 6px;
        margin-right: 6px;
        max-height: 120px;
        overflow: hidden;
      }
    }
  }
  .terms-container {
    pre {
      font-family: sans-serif;
      line-height: 16px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    .description {
      height: 100%;
      margin-bottom: 0;
    }
  }
  .promotion-container,
  .message-plans-container {
    pre {
      font-family: sans-serif;
      line-height: 16px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    .brand-id {
      height: auto;
      select {
        width: 200px;
      }
    }
    .description {
      min-height: 100px;
    }
    .promo-groups {
      min-height: 150px;
    }
    .criteria {
      min-height: 200px;
    }
    .rewards {
      min-height: 400px;
      .content {
        &.small {
          height: 100px !important;
        }
      }
    }
    .cs-flex {
      padding: 15px !important;
    }
    .template-view-mood-section {
      height: 200px !important;
      overflow: auto !important;
    }
    // .description {
    //   min-height: 100px;
    // }
    // .promo-groups {
    //   min-height: 150px;
    // }
    // .criteria {
    //   min-height: 200px;
    // }
    // .rewards {
    //   min-height: 350px;
    // }
    // .cs-flex {
    //   padding: 15px !important;
    // }
  }
  textarea {
    border: none;
    padding: 10px;
    line-height: 16px;
  }
  .row-group,
  .row-filter {
    width: 100%;
    position: relative;
    &:hover {
      background-color: $primary-lightest;
    }
    select {
      &:first-child {
        width: 150px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: auto;
      }
      &.operator {
        width: 120px;
      }
      margin-right: 10px;
    }
    input[type="text"],
    input[type="number"] {
      width: 100px;
      margin-right: 10px;
    }
    button {
      height: 24px;
    }
    .shop-container,
    .bet-type-container,
    .jackpot-container {
      display: inline-grid;
      width: 60%;
      border: 1px solid #ccc;
      height: 50px;
      position: relative;
      overflow: hidden;
      overflow-y: scroll;
      vertical-align: top;
      div {
        padding: 1px 5px;
      }
    }
    .bet-type-container {
      width: 25%;
    }
  }
  .row-item {
    width: 100%;
    input[type="text"],
    input[type="number"] {
      height: 25px;
    }
    input.ep {
      width: 40%;
    }
  }
  button {
    &.btn-box {
      margin-top: 3px;
      width: 20px !important;
      height: 20px !important;
      line-height: 20px !important;
      min-width: 20px !important;
      i {
        font-size: 10px;
      }
    }
  }
  .promo-reward-item {
    height: 253px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 240px;
    .padding-small {
      height: 315px;
      position: relative;
      .row {
        height: auto;
      }
    }
    .header {
      border-bottom: none;
    }
    .panel-header {
      padding: 0;
      vertical-align: middle;
    }
    .panel-header-title {
      width: 200px;
      select {
        height: 25px !important;
        margin-top: 5px !important;
      }
    }
    .panel-header-actions {
      button {
        margin-top: 5px;
        &.btn-box {
          margin-top: 7px;
        }
      }
    }

    select {
      height: 20px;
      margin: 0;
      width: 100%;
    }
    input[type="number"] {
      width: auto;
    }
    .col-md-12 {
      padding: 0;
    }
    .form-group {
      label {
        width: 110px;
      }
      input[type="text"] {
        width: 150px;
      }
      .checkbox-label {
        padding-left: 5px;
      }
      .sub-label {
        position: absolute;
        right: -13px;
        top: 4px;
      }
      .form-group-control {
        width: calc(100% - 120px);
        &.expires-after {
          display: flex;
          input[type="text"],
          input[type="number"] {
            width: 35px !important;
            margin-right: 5px;
          }
          select {
            min-width: 55px;
            width: 70px;
            padding: 0 !important;
            font-size: 11px;
          }
        }
      }
    }
    .panel-footer {
      border-top: 1px solid #e2e2e2;
      padding: 7px 3px;
      position: absolute;
      bottom: -5px;
      margin: 0 -5px;
      width: 239px;
      font-size: 11px;
      .form-group {
        padding: 2px 5px;
      }
    }
  }
  .bonus-manager-sidebar {
    .width-24 {
      width: 24px;
    }
    @extend .event-creator-sidebar;
    .sidebar-body {
      padding: 0;
      ul.sidebar__items {
        .nav-item-sub {
          ul {
            margin: 0px;
          }
        }
      }
    }
    width: 250px;
    .path-icon {
      color: #ffca28;
    }
    .path-icon--settings {
      color: $primary;
    }
    .path-anchor {
      &.disabled {
        pointer-events: none;
        color: #ccc;
      }
    }
    .path-container {
      &.level-1:first-child {
        border-top: none;
      }
    }
    .side-bar-menu-left-icons {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
    }
  }
  .bonus-manager-app-page {
    height: 100%;
    .panel-content {
      padding: 5px;
    }
  }
  .bonus-manager-header {
    .panel-header {
      background: none;
      height: 45px;
      display: flex;
      .btn-box {
        display: flex;
        align-items: center;
        width: 40px;
        height: 32px;
        background-color: #fff;
        transition: all 0.3s ease;
        border: 1px solid rgba(255, 255, 255, 0.25);
        color: $primary-dark;
        &:hover {
          background-color: rgba(255, 255, 255, 0.75);
          .phxico {
            color: $primary-dark;
          }
        }
        &:disabled {
          pointer-events: none;
          background: none;
          .phxico {
            color: rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
    .panel-header-actions {
      display: flex;
      align-items: center;
    }
  }
  .list-container-sports {
    .level-0 {
      overflow: auto;
      width: 100%;
      padding: 0 2px;
      > .path-anchor {
        display: block;
      }
    }
    .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .new-bet-restriction-container {
    .sports-tree-controls {
      display: block;
      height: 30px;
      margin: 2px 0;
      .controls-group {
        align-items: center;
        > div {
          display: flex;
          &:not(:last-child) {
            padding-right: 5px;
          }
        }
        select {
          width: 100%;
        }
      }
      .path-search-container input {
        width: 100%;
      }
    }
  } // Common Component
  .cs-flex {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 35px;
    &.no-space {
      padding: 0;
    }
  }
  .cs-box {
    &__container {
      // margin: 10px;
      margin-bottom: 10px;
      margin-right: 10px;
      border: 1px solid #bfbfbf;
      width: 180px;
      // height: 90px;
      background: #fff;

      &.small-box {
        .content {
          min-height: 45px;
          height: auto;
          overflow: hidden;
        }
      }

      &.medium-box {
        height: 130px;
        .content {
          height: 80px;
          overflow: hidden;
        }
      }

      &.large-box {
        height: 180px;
        max-height: 180px;
        box-shadow: 0 0 2px 2px #d4d4d4;
        border-radius: 3px;
        cursor: pointer;

        .content {
          height: 130px;
          overflow: hidden;
        }
      }
      .header {
        display: flex;
        padding: 0 15px;
        border-bottom: 1px solid #e1e1e1;
        height: 48px;
        align-items: center;
        .icon {
          width: 35px;
          height: 20px;
          margin-left: -5px;
          i {
            font-size: 18px;
            &.retired {
              color: #818181;
            }
          }
          > .green,
          > .success {
            color: #81f881;
          }
          > .orange,
          > .warning {
            color: #f3a15e;
          }
          > .red,
          > .error {
            color: #f56565;
          }
          > .off {
            color: #d6d6d6;
          }
          > .promo-active {
            color: #6fba65;
          }
          > .default {
            color: #418aca;
          }
        }
        .title {
          height: 38px;
          display: flex;
          align-items: center;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          h1 {
            width: 100%;
            font-size: 12px;
            flex: 1;
            margin: 0;
            letter-spacing: 0.4px;
            font-weight: 600;
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
      }
      .content {
        padding: 20px 10px 10px;
        font-family: sans-serif;
        height: 140px;
        overflow: hidden;
        &.small {
          padding: 5px 10px;
        }
        pre {
          font-size: 12px;
          margin: 0;
          font-family: inherit;
          font-weight: 300;
          line-height: 1.3em;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
  }
  .note {
    padding: 0 10px;
    font-size: 12px;
    width: 200px;
  }
  .section-container {
    font-family: sans-serif;
    pre {
      margin: 0;
      font-family: sans-serif;
    }
    .description {
      margin-bottom: 0;
      &.last {
        border-bottom: 0;
      }
      textarea {
        &.description-input {
          border: none;
          width: 100%;
          height: 40px;
          resize: none;
          outline: none;
        }
      }
      > .small {
        height: 60px;
      }
      > .medium {
        min-height: 115px;
      }
      > .large {
        //height: 220px;
      }
      > .auto {
        height: calc(100vh - 624px);
      }
      > .full {
        height: calc(100vh - 216px);
      }
    }
  } // Global header
  .btn-box.two-icon-wide {
    width: 55px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      height: 17px;
      width: 17px;
      &:first-child {
        &:before {
          margin-left: -2px;
        }
      }
      &.reverse-background {
        background: #3c70a0;
        color: #fff !important;
        padding: 2px;
        border-radius: 3px;
      }
      &:nth-child(2) {
        margin-left: 2px;
        font-size: 15px;
        color: #000 !important;
      }
    }
  }
  .menu-divider {
    margin-left: 10px;
    margin-right: 10px !important;
    width: 1px !important;
    border: 0 !important;
    min-width: 1px;
  }
  section.custom {
    min-width: 350px;
  }
  .sub-menu-wrapper {
    float: left;
    .sub-menu-container {
      // display: flex;
      width: 50px;
      height: 40px;
      margin-top: 5px; // justify-content: center;
      // align-items: center;
      position: relative;
      .menu-list {
        z-index: 10;
        position: absolute;
        left: 0;
        color: black;
        width: 200px;
        ul {
          border: 1px solid #e1e1e1;
          border-radius: 5px;
          margin-top: 2px;
          li {
            outline: none;
            cursor: pointer;
            padding: 5px;
            border-bottom: 1px solid #e1e1e1;
            line-height: 13px;
            transition: all 0.3s ease;
            background: #fff;
            display: block;
            &.disabled {
              color: #aaa;
              cursor: default;
            }
            &:hover {
              background: darken(#fff, 10);
            }
            &:last-child {
              border-bottom: none;
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;
            }
          }
        }
      }
      .menu-icons {
        background: #fff;
        border-radius: 5px;
        height: 32px;
        cursor: pointer;
        transition: all 0.3s ease;
        outline: none;
        &:hover {
          background: rgba(255, 255, 255, 0.75);
        }
        i {
          height: 17px;
          width: 17px;
          &:first-child {
            &:before {
              margin-left: -2px;
            }
          }
          &.reverse-background {
            background: #3c70a0;
            color: #fff !important;
            padding: 2px;
            border-radius: 3px;
            margin-left: 5px;
          }
          &:nth-child(2) {
            margin-left: 2px;
            font-size: 15px;
            color: #000 !important;
          }
        }
      }
    }
  }
  .btn-box {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
    &.btn-success {
      color: #6fba65;
      border: #6fba65 solid 1px;
      margin-right: 12px;
      &:hover {
        background: #6fba65;
        color: #fff;
        box-shadow: none;
      }
    }
    &.btn-error {
      color: #e60b0c;
      border: #e60b0c solid 1px;
      &.abs {
        position: absolute;
        right: 0;
        top: 0;
      }
      &:hover {
        background: #e60b0c;
        color: #fff;
        box-shadow: none;
      }
    }
  }
  .filter-options {
    .match-option {
      display: flex;
      .filter-type {
        flex: 1;
        select {
          width: 60px;
        }
      }
      .import-option {
        width: 145px;
        margin-right: 5px;
        button > i {
          font-size: 14px;
        }
      }
    }
    .filter-selections {
      .selections-item {
        display: flex;
        &:first-child {
          margin-top: 20px;
        }
        .promogroup-filter-option {
          flex: 1;
          display: flex;

          .radio-option {
            line-height: 32px;

            label {
              margin-right: 10px;
            }
          }

          .text-between-option {
          }

          .text-single-option {
            .option-spacer {
              display: inline-block;
              padding-left: 67px;
            }

            .option-spacer-last-bet-date {
              display: inline-block;
              padding-left: 75px;
            }
          }

          * {
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
          select {
            width: 220px;
            outline: none;

            &:not(:first-child) {
              width: inherit;
            }

            option {
              outline: none;
            }

            &.filter-option-type {
              min-width: 130px;
            }
          }
          input[type="text"] {
            width: 60px;
            height: 25px;
          }
        }
        .remove-option {
          display: flex;
          justify-content: center;
          align-items: center;
          .remove {
            width: 20px;
            height: 20px;
            border: 1px solid #e60b0c;
            border-radius: 3px;
            justify-content: center;
            align-items: center;
            display: flex;
            color: #e60b0c;
            cursor: pointer;
            &:active,
            &:focus {
              background: #e60b0c;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.sport-search {
  width: 100%;
}
.bm-delete-modal {
  &.has-cd {
    .modal-container {
      height: 270px;

      .modal-body {
        padding: 0;
      }
    }
  }
  &.no-cd {
    .modal-container {
      height: auto;
    }
  }
  .modal-container {
    width: 500px;

    .modal-body {
      padding: 0;
      justify-content: space-between;
    }

    .content {
      .connected {
        height: 100px;
        margin: 15px 0;
        overflow: scroll;
      }
    }
  }
  .modal-controls {
    position: unset;
    width: 100%;
    bottom: 0;
    padding: 0;

    .button-group {
      button {
        outline: none;
      }
    }
  }
}
.bm-event-path-tree {
  height: 540px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 5px;
  margin-top: 10px;
  border: 1px solid #ccc;
  .ep-toggle {
    cursor: pointer;
  }
  label {
    user-select: none;
    cursor: pointer;
    &.disabled {
      color: #999;
      .path-icon {
        color: #999;
      }
    }
    &.no-child {
      margin-left: 17px;
    }

    overflow: hidden;
  }
  .path-icon {
    color: #333;
    display: inline-block;
    margin-right: 0;
    width: auto;
  }
  .phx-event-path,
  .phx-rank-event,
  .phx-game-event {
    color: #fff;
    font-size: 13px;
  }
}
.modal-controls {
  &.error-modal {
    position: absolute;
    button {
      width: 100px;
    }
  }
}

.message-plans-edit-page {
  .promotional-groups-dropDown {
    //width:20%;
  }
  .message-trigger-dropDown {
    width: 24%;
  }
  .messsage-trigger {
    display: flex;
  }
  .message-plans-channel-row {
    margin-top: 10px;
    display: -webkit-inline-box;
  }
  .message-plans-channel-row span {
    padding-right: 18px;
  }
  .padding-medium {
    height: auto !important;
  }
  input[name="expiry"] {
    width: 50%;
    height: 28px;
  }
  .message-panels-expiry-select {
    display: block;
  }
  .add-promotional-group,
  .remove-promotional-group {
    background: black;
    color: white;
    padding: 2px;
    border-radius: 29px;
    cursor: pointer;
    margin-left: 1%;
  }
  .add-promotional-group-member-enabled {
    padding: 2px;
    border-radius: 29px;
    cursor: pointer;
    margin-left: 1%;
  }
  .add-promotional-group-member-disabled {
    padding: 2px;
    border-radius: 29px;
    color: gray;
    //cursor: pointer;
    margin-left: 1%;
  }
  .message-trigger {
    .drop-down-1,
    .drop-down-2,
    .input-field-1,
    .input-field-2 {
      width: 12%;
      margin-left: 10px;
    }
    .add-promotional-group-member-disabled {
      padding: 2px;
      border-radius: 29px;
      color: gray;
      //cursor: pointer;
      margin-left: 1%;
    }
    .rdw-fontfamily-custom {
      width: 320px;
    }
    .rdw-fontfamily-actioncustom {
      width: 180px;
    }
    .margin-Left {
      margin-left: 15px;
    }
    li:hover {
      background-color: #f1f1f1;
    }
    .message-trigger {
      .drop-down-1,
      .drop-down-2,
      .input-field-1,
      .input-field-2 {
        width: 12%;
        margin-left: 10px;
      }
      .checkBox {
        margin-left: 10px;
      }
    }
    .favourite-event {
      display: inline-flex;
      margin-left: 1%;
      .fav-events-div {
        min-height: 60px;
        min-width: 200px;
      }
      .action-button {
        margin-left: 1%;
      }
    }
    .sportsTree-hierirechy {
      top: 31px;
      display: block;
      // position: absolute;
      overflow: auto;
    }
    .event-creator-row {
      top: 0;
      position: absolute;
      margin-left: -2%;
      width: 27%;
    }
    .sports-selector {
      width: 100%;
      top: 0;
      display: block;
      position: absolute;
      .dropdown {
        // position: relative;
        display: inline-block;
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        color: #323232;
        padding: 0px 15px;
        height: 26px;
        line-height: 24px;
        border: 1px solid #e2e2e2;
        max-width: 100%;
        &:after {
          content: "";
          width: 0;
          height: 0;
          position: relative;
          right: -96%;
          top: -14px;
          margin-top: -6px;
          border-width: 6.4px 3.5px 0px 3.5px;
          border-style: solid;
          border-color: black transparent;
        }
        &.active {
          box-shadow: 0px 0px 1px 1px #418aca;
        }
        &.disabled {
          pointer-events: none;
          background: #f1f3f3;
          color: #333;
          border: none;
        }
        .dropbtn {
          background-color: #fff;
          color: black;
          border: none;
          display: inline-block;
          width: 100%;
          &.disabled {
            pointer-events: none;
            background: #f1f3f3;
            color: #333;
          }
          &:hover,
          &:focus {
            background-color: #fff;
          }
        }
        #sports-search {
          height: 100%;
          width: 100%;
          border: none;
          &:focus {
            outline: none;
          }
        }
        .dropdown-content {
          display: none;
          flex-direction: column;
          position: absolute;
          background-color: #fff;
          overflow: auto;
          z-index: 1;
          width: 95%;
          left: 7px;
          height: 300px;
          color: black;
          top: 25px;
          &.active {
            border: 1px solid #418aca;
          }
          .dropdown-list {
            padding: 5px;
            > label {
              display: flex;
              > span {
                margin-right: 5px;
                margin-top: 1px;
                > img {
                  height: 12px;
                }
              }
            }
            .path-icon {
              display: inline-block;
              padding: none;
              margin-right: 0;
            }
            &:hover {
              background-color: #418aca !important;
              color: #fff;
            }
            &.searched-item {
              background-color: #418aca !important;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .static-message-plan .auto {
    height: auto !important;
    .checkBox {
      margin-left: 10px;
    }
  }

  .rdw-dropdown-optionwrapper {
    border: 0px !important;
    border-left: 1px solid #e0ebeb !important;
    border-top: 1px solid #e0ebeb !important;
    border-right: 1px solid #e0ebeb !important;
    overflow-y: auto;
  }

  .rdw-dropdown-optionwrapper:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
  }
  .rdw-dropdownoption-default {
    min-height: 29px;
  }

  .rdw-placeholder-dropdown {
    .rdw-dropdown-carettoopen-custom {
      height: 0px;
      width: 0px;
      position: absolute;
      right: 14%;
      border-top: 6px solid black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    .rdw-dropdown-carettoclose-custom {
      height: 0px;
      width: 0px;
      position: absolute;
      right: 4%;
      border-bottom: 6px solid black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .rdw-actions-dropdown {
    .rdw-dropdown-carettoopen-custom {
      height: 0px;
      width: 0px;
      position: absolute;
      right: 14%;
      border-top: 6px solid black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    .rdw-dropdown-carettoclose-custom {
      height: 0px;
      width: 0px;
      position: absolute;
      right: 14%;
      border-bottom: 6px solid black;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
}

.campaign-statistics-modal {
  h4 {
    text-align: center !important;
  }
  .modal-container {
    width: 400px;
    height: 600px;
    .row {
      padding: 3px;
      div:first-child {
        width: 55%;
      }
      div:nth-child(2) {
        width: 45%;
      }
    }
  }
}
.campaign-promo-code-modal {
  .modal-container {
    width: 450px;
    height: 745px;
    button {
      &.btn-box {
        margin-top: 3px;
        width: 20px !important;
        height: 20px !important;
        line-height: 20px !important;
        min-width: 20px !important;
        box-shadow: none !important;
      }
      &.search {
        margin-left: 10px;
      }
    }
    .pc-form-field {
      width: 70%;
      float: left;
      input {
        &.max-uses {
          width: 75px;
        }
      }
      label {
        margin-right: 10px;
        div.label {
          width: 75px;
          display: inline-block;
          text-align: left;
        }
      }
      .datepicker-container {
        display: inline-block;
        p {
          display: block;
          -webkit-margin-before: 0px;
          -webkit-margin-after: 0px;
        }
      }
    }
    .pc-form-btn {
      width: 30%;
      float: left;
      position: relative;
      button {
        position: absolute;
        top: 20px;
        width: 130px;
      }
    }
    .promo-code-table {
      .custom-table {
        height: 260px;
        .rt-tbody {
          height: 214px;
          .rt-tr {
            &.selected {
              background: #418aca;
              color: #fff;
            }
          }
        }
      }
    }
    .promo-actions {
      margin-top: -10px;
      select {
        min-width: 50px;
        width: 70px;
      }
      button {
        width: 25px;
        padding: 0;
        box-shadow: none;
      }
    }
    .promo-code-usage-table {
      .custom-table {
        height: 190px;
        .rt-tbody {
          height: 190px;
        }
      }
    }
    .loading {
      margin-top: 10%;
    }
    .rt-tbody {
      border: 1px solid rgba(0, 0, 0, 0.05);
      .rt-td {
        padding: 3px;
        border-bottom: none;
        border-right: none;
      }
      .rt-noData {
        width: 150px;
        text-align: center;
        margin: 10% auto;
        position: relative;
        vertical-align: middle;
        // transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.06);
        transition: all 0.3s ease;
        pointer-events: none;
        padding: 20px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    table {
      margin-bottom: 20px;
      &.tbl-codes {
        height: 260px;
        tbody {
          height: 260px;
        }
      }
      &.tbl-code-usage {
        height: 200px;
        tbody {
          height: 200px;
        }
      }
    }
  }
}

.favourite-event {
  display: inline-flex;
  margin-left: 1%;
  .fav-events-div {
    border: 1px solid black !important;
    min-height: 60px;
    min-width: 200px;
  }
  .action-button {
    margin-left: 1%;
  }
}

.msg-plans-event-creator-row {
  top: 0;
  position: absolute;
  border: 1px solid #80b4e2;
  // margin-left: -2%;
  // width:25%;
  // height: 100%;

  .sportsTree-hierirechy {
    // top:31px;
    display: block;
    // position:absolute;
    height: 400px;
    overflow: auto;
    min-width: 90%;
    // margin-left: 6% !important;
  }

  .sports-bottom-add-button {
    bottom: 0px;
    position: relative;
    // width: 100%;
    margin-bottom: 4px;
  }
  .load-opponent {
    border-top: 1px solid #80b4e2;
    border-bottom: 1px solid #80b4e2;
    background-color: #ebf5ff;
    padding: 8px;
    text-align: center;
    &:hover {
      background-color: #418aca;
      cursor: pointer;
      color: #fff;
    }
    &.disabled {
      background: #e2e2e2;
      color: #aaa;
      cursor: not-allowed;
    }
  }
  .selected-opponent {
    height: 360px;
    padding-left: 15px;
    padding-top: 2px;
    overflow: auto;
    border-bottom: 1px solid #80b4e2;
    .oponent {
      display: block;
      font-size: 13px;
      text-transform: capitalize;
      padding: 2px;
      &.selected {
        font-weight: bold;
        color: #418aca;
        cursor: default;
      }
      &:hover {
        color: #418aca;
      }
    }
  }
}
.sports-selector {
  width: 100%;
  top: 0;
  display: block;
  position: unset;
  height: 75%;
  overflow: auto;
  .dropdown {
    // position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    color: #323232;
    padding: 0px 15px;
    height: 26px;
    line-height: 24px;
    border: 1px solid #e2e2e2;
    max-width: 100%;
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: relative;
      right: -96%;
      top: -14px;
      margin-top: -6px;
      border-width: 6.4px 3.5px 0px 3.5px;
      border-style: solid;
      border-color: black transparent;
    }
    &.active {
      box-shadow: 0px 0px 1px 1px #418aca;
    }
    &.disabled {
      pointer-events: none;
      background: #f1f3f3;
      color: #333;
      border: none;
    }
    .dropbtn {
      background-color: #fff;
      color: black;
      border: none;
      display: inline-block;
      width: 100%;
      &.disabled {
        pointer-events: none;
        background: #f1f3f3;
        color: #333;
      }
      &:hover,
      &:focus {
        background-color: #fff;
      }
    }
    #sports-search {
      height: 100%;
      width: 100%;
      border: none;
      &:focus {
        outline: none;
      }
    }
    .dropdown-content {
      display: none;
      flex-direction: column;
      position: absolute;
      background-color: #fff;
      overflow: auto;
      z-index: 1;
      width: 95%;
      left: 7px;
      height: 300px;
      color: black;
      top: 25px;
      &.active {
        border: 1px solid #418aca;
      }
      .dropdown-list {
        padding: 5px;
        > label {
          display: flex;
          > span {
            margin-right: 5px;
            margin-top: 1px;
            > img {
              height: 12px;
            }
          }
        }
        .path-icon {
          display: inline-block;
          padding: none;
          margin-right: 0;
        }
        &:hover {
          background-color: #418aca !important;
          color: #fff;
        }
        &.searched-item {
          background-color: #418aca !important;
          color: #fff;
        }
      }
    }
  }
}
.rdw-option-wrapper[title="Bold"],
[title="Italic"],
[title="Underline"],
[title="Link"],
[title="Unlink"] {
  height: 32px !important;
  width: 40px !important;
}
.rdw-colorpicker-wrapper .rdw-option-wrapper {
  height: 32px !important;
  width: 40px !important;
}
.rdw-fontfamily-dropdown {
  width: 160px !important;
}
.rdw-fontfamily-dropdown ul {
  width: 160px !important;
}
.rdw-link-modal {
  height: 230px !important;
}
.rdw-link-decorator-wrapper > a:hover {
  padding: 0px !important;
}
.rdw-link-decorator-icon {
  left: 98% !important;
  height: 7px;
  width: 7px;
  top: -5px !important;
}
.rdw-editor-toolbar {
  font-size: 12px !important;
}
.rdw-placeholder-dropdown,
.rdw-actions-dropdown {
  li {
    border-bottom: 1px solid #e0ebeb;
  }
  li:hover {
    background: #3e7ecb;
    color: #ffffff;
  }
}
.rdw-link-modal {
  .rdw-link-modal-label {
    font-size: 12px;
  }
}
.rdw-fontfamily-optionwrapper,
.rdw-dropdown-optionwrapper {
  li:hover {
    background: #3e7ecb;
    color: #ffffff;
  }
}
.rdw-colorpicker-modal {
  padding: 0px !important;
}
.rdw-colorpicker-modal-options {
  overflow-y: scroll !important;
  overflow-x: unset !important;
}
.between-dash {
  margin-right: 10px;
}
