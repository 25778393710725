.login-wrapper {
  width: 30%;
  height: 350px;
  margin: 0 auto;
  margin-top: 70px;
  background: $black50;
  border-radius: 3px;
  padding: 20px;
  border: 1px solid $xylo1;
  form {
    position: relative;
  }
  .logo-onbody {
    @include wh(100%, 130px);
    background: url("../../assets/logos/phx-title.png") no-repeat center;
    background-size: auto;
    margin-bottom: 20px;
    position: relative;
    span {
      position: absolute;
      color: $secondary;
      font-size: 12px;
      text-transform: uppercase;
      width: 100%;
      bottom: -25px;
      text-align: center;
    }
  }
  .login-head {
    width: 100%;
    display: block;
    margin: 20px auto;
    border-bottom: 1px solid $white50;
    h3 {
      color: $gray-dark;
      font-weight: 300;
    }
  }
  .login-container {
    width: 100%;
    margin: 0 auto;
    input {
      border-radius: 3px;
      width: 100%;
      &:first-child {
        margin-bottom: 5px;
      }
    }
  }
  .btn-action {
    margin-top: 20px;
  }
}
//.header-container {
.main-bg {
  padding-right: 10px;
  line-height: 0px !important;
  .dropdown-wrap {
    padding-top: 5px !important;
    padding-left: 5px;
    line-height: 30px;
    border-radius: 3px;
    .flag-select__btn {
      background: #fff;
      border: none;
      box-shadow: none !important;
      height: unset !important;
      &:hover {
        background: transparent !important;
        color: #333;
      }
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .flag-select__options {
      min-width: 14em !important;
      min-height: 2.71428571em;
      background: #fff;
      display: inline-block;
      padding: 0 !important;
      color: rgba(0, 0, 0, 0.87);
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      transition: box-shadow 0.1s ease, width 0.1s ease;
      top: 30px;
      right: 0 !important;
      max-height: 350px !important;
      li {
        margin: 0 !important;
        span {
          width: 100% !important;
          height: auto !important;
          display: flex;
          align-items: center;
          img {
            top: 4px;
          }
        }
      }
    }
  }
}
.user-section-width {
  width: 100% !important;
}
//}
